.ant-cascader {
	background-color: #eeeeee;
	border: 1px solid #d2d2d2;
	border-radius: 5px;
	height: 65px;
	width: auto;
    min-width: 150px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border:none;
    background-color:#eeeeee;
    height: 63px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	   height: 63px;
	   border:none;
	    background-color: #eeeeee;
	}
.ant-select-selection-item {
	font-family: Industry;
	font-style: normal;
	font-size: 28px;
	width: max-content;
	margin-top: 15px;
}
.ant-cascader-menus {
	font-family: Roboto;
	font-style: normal;
	font-size: 15px;
}

.ant-select-selection-search-input {
	background-color: #eeeeee;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
	top: 12px;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-cascader-menu-item:hover {
	background-color: var(--ion-color-gray);
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
	background-color: var(--ion-color-gray-tint);
}