.nameContainer {
	display: flex;
	align-items: center;
}
.domainNameLb {
	justify-content: flex-start;
	max-width: 100% !important;
}
.domainVerificationContainer {
	display: flex;
	align-items: center;
	max-width: 120px;
}
.domainVerificationBtn {
	max-width: max-content !important;
	width: max-content !important;
	justify-content: flex-start;
}
.copyVerificationTokenBtn {
	margin-left: 10px;
	cursor: pointer;
}
.noData {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	min-width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.noDataHint {
	font-weight: normal;
	margin-bottom: 1rem;
}

.listGrid {
	padding: 0;
	margin: 0;
	height: 100%;
	flex-grow: 1;
}
.headerRow {
	display: flex;
	align-items: center;
	height: 2.813rem;
	background: var(--ion-main-background-color);
	border-radius: 5px;
	font-family: 'Industry';
	padding-left: 1rem;
}
.centeredCol {
	text-align: center;
	display: flex;
	justify-content: center;
}
.dataRow {
	background-color: var(--ion-color-primary-contrast);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	margin-top: 0.5rem;
	align-items: center;
	padding-left: 0.5rem;
}
.createdRow {
	background-color: #edf8ec;
}
.noData {
	margin-top: 1rem;
	padding-left: 0.5rem;
}
.noData:hover {
	cursor: default;
}
.moreCol {
	max-width: 3.75rem;
}
.moreIcon {
	color: var(--ion-color-gray);
	border-radius: 50%;
	padding: 0.375rem;
}
.moreIcon:hover {
	background-color: var(--ion-main-background-color);
	cursor: pointer;
}
.info {
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
}
.hide {
	display: none;
}
.popup {
	--box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.015);
	border-radius: 10px;
}
.elementContainer {
	height: inherit;
	display: inline-flex;
	align-items: center;
}
.closeIcon {
	margin-left: 10px;
	width: 25px;
	height: 25px;
}
.columnHeader {
	display: flex;
	align-items: center;
	text-transform: uppercase;
}
.domainAddInput {
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 250px;
	height: 35px;
}
.domainValidation {
	color: #ff0000;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
