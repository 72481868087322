.detailsHeader {
	margin-top: 1.063rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
	padding: 0.8rem 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 45px;
}
.detailsCol {
	margin-right: 1rem;
}
.tableHeader {
	width: 100%;
	margin-top: 1.063rem;
}
.editForm,
.ssoDiv {
	width: 100%;
}
.formGrid {
	width: 100%;
	padding: 0;
}
.formGrid ion-list {
	width: 100%;
	margin-bottom: 0.3rem;
}
.formGrid ion-item {
	opacity: 1;
	--border-color: rgba(0, 0, 0, 0.04);
	--highlight-color-focused: none;
}
.formGrid .createdOnLb {
	margin-left: 0.5rem;
}
.formGrid .phoneItem {
	display: flex;
}
.formGrid .phoneItem ion-label {
	margin-left: 1rem;
	align-self: center;
}
.formGrid ion-input.editable {
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 300px;
}
.formGrid ion-textarea.editable {
	border-radius: 4px;
	border: 1px solid #ccc;
}
.formGrid ion-input.companyName {
	margin-right: 0.5rem;
}
.formLb {
	font-weight: bold;
	margin-right: 4rem;
	min-width: 210px !important;
}
.inputLb {
	margin-left: 0.5rem;
}
.inputLbContact {
	margin-left: 0.5rem !important;
	max-width: 100% !important;
}
.inputLbAgreement {
	color: var(--ion-color-primary) !important;
}
.inputLbAgreement:hover {
	cursor: pointer !important;
}
.subTitle {
	margin: 1rem;
}
.subTitleLb {
	font-family: 'Industry';
	text-transform: capitalize;
	color: var(--ion-color-gray);
}
.chkLb {
	margin-left: 0.5rem;
}
.ddlLb {
	flex: 1 !important;
}
.selectLb {
	margin-left: 0 !important;
	font-weight: bold;
	margin-right: 6.5rem;

	min-width: 170px !important;
}
.groupSelect {
	max-width: 300px;
	flex: 1;
}
.hidden {
	display: none !important;
}

.fullWidth {
	width: 100%;
}

.agreementFrame {
	border: none;
	width: 100%;

	height: 597.2px !important;
}

.modalHeader {
	text-align: center;
}
.addModal {
	--width: 613px;
	--height: 813px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	min-height: 233px;
	--border-radius: 10px;
}
.domainConfigurationModal {
	max-width: 813px;
}
.modalContent {
	background: var(--ion-color-primary-contrast);
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}
.modalHeader {
	display: block;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
}
.headerContainer {
	width: 100%;
	text-align: left;
	margin-left: 22px;
}
.headerTitle {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	margin-top: 3rem;
	margin-bottom: 1.875rem;
	display: block;
}
.domainConfigurationHeaderTitle {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	margin-top: 3rem;
	margin-bottom: 1rem;
	display: block;
}
.subTitle {
	color: var(--ion-color-gray-shade);
}
.modalDomainConfHeaderContainer {
	width: 100%;
	margin-left: 22px;
}
/* .editImageContainer {
	width: 321px;
	margin: 24px auto 6px;
	height: calc(53px + 6px);
	--inner-padding-end: 0;
} */
.msgContainer {
	flex: 1;
	margin-top: 1.5rem;
	width: 60%;
	color: var(--ion-color-primary-contrast);
}
.fullImage {
	min-width: 200px;
	min-height: 200px;
}
.userAvatar {
	margin: auto !important;
	height: 200px;
	width: 200px;
	/* background-color: var(--ion-text-color); */
}
.tabContainer {
	--background: var(--ion-color-primary-contrast);
	border-radius: 5px;
	border: 1px solid var(--ion-color-gray-shade);
	display: inline-flex;
	width: 100%;
	height: 57px;
	margin-bottom: 2.75rem;
}
.tabContainer ion-segment-button,
.tabContainer .urlExpand {
	--color: var(--ion-color-gray-shade);
	--indicator-box-shadow: none;
	--padding-bottom: 15px;
	--padding-top: 16px;
	--padding-left: 2px !important;
	--padding-right: 2px !important;
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--transition: 0.5s;
	border-radius: 0px;
	margin: 0;
}
.urlExpand {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.iconContainer {
	width: max-content;
}
.icon {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.iconExpanded {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
/* .tabContainer ion-segment-button:first-child {
	border-right: 1px solid var(--ion-color-gray-shade);
} */
.tabContainer ion-segment-button:last-child {
	border-left: 1px solid var(--ion-color-gray-shade);
}
.tabContainer ion-icon {
	width: 26px;
	height: 26px;
	margin-right: 0.5rem;
}
.iconExpanded {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.btnContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	min-height: 130px;
}
.btnContainer ion-button {
	--border-width: 1px;
	--background-hover-opacity: 1;
	--transition: 0.5s;
	width: 185px;
	height: 50px;
	font-size: 15px;
	margin-top: 0.5rem;
	margin-bottom: 1.2rem;
}
.btnContainer ion-button:first-child {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.saveBtn {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
}
.hidden {
	display: none;
}
.agreementRow {
	height: 50px !important;
	width: 100%;
	border-bottom: 1px solid #f1f1f3;
	align-items: center;
}
.agreementRowContent {
	height: 100%;
	margin-left: 0.5rem;
	font-family: Roboto;
	font-size: 15px;
	color: #1e1f22;
	line-height: 1.33;
	text-align: left;
}
.noTopPadding {
	padding-top: 0;
}

.btnRow ion-button {
	width: 185px;
	margin: 0 auto;
}

.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}

.countrySelectItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 48px;
	padding-left: 16px;
}

.countrySelectItem > ion-label {
	max-width: 210px;
}

.errorMsg {
	color: var(--form-field-error-color);
	font-size: 0.8em;
}
.formBtns {
	display: flex;
}
.formBtns ion-icon {
	color: var(--ion-color-gray);
}
.formBtns ion-icon:hover {
	cursor: pointer;
}
.copyOrgCallbackURLBtn {
	cursor: pointer;
}
.copiedOrgCallbackURL {
	text-align: right;
	max-width: 80px;
}
.labelFont {
	font-family: 'Industry-Demi';
}
.countryDiv{
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 300px;
	width: 100%;
}