@media (max-width: 576px) {
	.mainMenu .logo {
		min-height: 40px !important;
	}
	.toolBar {
		height: auto !important;
	}
	.menuItem {
		--min-height: auto !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.mainMenu .logo {
		min-height: 40px !important;
	}
	.toolBar {
		height: auto !important;
	}
	.menuItem {
		--min-height: auto !important;
	}
}
.mainMenu {
	max-width: 260px;
	border: none;
}
.mainMenu.contracted {
	min-width: 80px;
	max-width: 80px;
	overflow: hidden;
}
.absolute {
	position: absolute;
	z-index: 1;
}
.toolBar {
	--background: var(--ion-color-toolbar);
	height: var(--ion-height-toolbar);
}
.toolBarCollapse {
	display: flex;
}
.toolBar:hover {
	cursor: pointer;
}
.avatarContainer {
	margin-left: 17px;
	display: flex;
	width: calc(100%);
}
.userAvatar {
	padding-top: 15px;
	width: 38px;
	min-width: 38px;
	margin-right: 0.5em !important;
}
.userNameLb {
	text-transform: none;
	color: #ffffff;
	margin-top: 25px;
	flex-grow: 1;
}
.headerIcon {
	color: var(--ion-header-icon-color);
	/* --color-activated: black; */
}
.profilePic {
	height: 38px;
	min-height: 38px;
}
.logo {
	padding-left: 0.3em;
	font-weight: bold;
	font-size: 2em;
	color: var(--ion-color-primary-contrast) !important;
}
.mainMenuIcon {
	width: 32px;
	color: var(--ion-color-primary-contrast);
	margin-right: 0.6em;
	margin-top: 0.15em;
}
.mainMenuIconCollapse {
	width: 32px;
	color: var(--ion-color-primary-contrast);
	margin-right: 24px;
}
.mainMenuIcon:hover {
	cursor: pointer;
}
.menuList {
	background-color: var(--ion-color-menu);
	height: 100%;
	z-index: 0 !important;
	overflow-y: auto;
}
.menuList > ion-item > ion-icon {
	padding-right: 1em;
}
.menuToggle {
	display: flex;
}
.menuToggleCollapse {
	display: inline-block;
}
.menuItemContainer {
	display: flex;
	--min-height: 53px;
	width: 100%;
}
.menuItem {
	--ripple-color: transparent;
	transform: none;
	--background: var(--ion-color-menu);
	--background-hover: var(--ion-background-menu-item-hover);
	--background-hover-opacity: 1;
	--min-height: 53px;
	border-radius: 5px;
	margin-left: 10.5px;
	margin-right: 15.5px;
	width: 100%;
}

.menuItemCollapse {
	--ripple-color: transparent;
	transform: none;
	--background: var(--ion-color-menu);
	--background-hover: var(--ion-background-menu-item-hover);
	--background-hover-opacity: 1;
	--min-height: 53px;
	border-radius: 5px;
	width: 60px;
	margin-left: 9px;
	margin-right: 10px;
}
.menuItemIdentifier {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 5px;
	--min-height: 53px;
}
.noMenuIdentifier {
	width: 0px;
}
.menuItemContainer:hover .menuItemIdentifier {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 5px;
	min-height: 53px;
	background: var(--ion-background-menu-item-hover);
}
.menuTitle {
	color: var(--ion-color-primary-contrast) !important;
}
.menuTitleHidden {
	display: none !important;
}
.menuIcon {
	color: var(--ion-color-menu-icon);
	margin-right: 0.6em;
}
.menuIconCollapse {
	padding-left: 5px;
}
.menuBarHeader {
	background-color: var(--ion-color-menu);
}
.subMenuIcon {
	margin-left: 1.5em;
}
.mainMenu.contracted .menuIcon {
	margin-right: 0 !important;
}
.activeItem {
	--background: var(--ion-background-menu-item-active);
}
.activeItem .menuItemIdentifier {
	background: var(--ion-background-menu-item-active);
}

.activeItem .menuIcon {
	color: var(--ion-color-menu-icon);
}
.menuItem:hover .menuIcon {
	color: var(--ion-color-menu-item-hover);
}
.hideSubMenu {
	display: none;
}
.showSubMenu {
	display: block;
}
.hidden {
	display: none;
}
.versionContainer {
	position: absolute;
	width: 100%;
	bottom: 2rem;
	color: var(--ion-color-gray);
	text-align: center;
}
.versionContainer:hover {
	color: var(--ion-color-gray-shade);
	cursor: auto;
}

.inviteUserBtn {
	--color: var(--ion-color-primary);
	--border-color: var(--ion-color-primary);
	--border-width: 2px;
	--background: var(--ion-text-color);
	--background-hover: white;
	--background-hover-opacity: 1;
	--border-radius: 5px;
	text-transform: initial;
	height: 45px;
	width: 150px;
	margin-left: 30px;
}

.inviteUserRounded {
	display: flex;
	justify-content: center;
	height: 50px;
	width: 50px;
	margin-left: 15px;
	--padding-end: 0;
	--padding-start: 0.5rem;
	--color: white;
	--border-color: var(--ion-color-primary);
	--border-width: 2px;
	--background: var(--ion-text-color);
	--background-hover-opacity: 1;
	--border-radius: 50%;
}

.inviteUserRounded:hover,
.inviteUserBtn:hover {
	--background-hover: white;
	--color-hover: var(--ion-text-color);
	--border-color: white;
}

.addIcon {
	font-size: 1.5rem;
	margin-right: 10px;
}
