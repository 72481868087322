@media (max-width: 576px) {
	.passwordGrid .topLogoContainer {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.passwordFormContainer {
		align-items: flex-start !important;
	}
	.passwordFormContainer ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.passwordGrid .logo {
		align-self: flex-end;
	}
	.passwordForm {
		width: 100%;
		max-width: none !important;
		padding: 30px 20px !important;
	}
	.passwordForm .btnRow {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.passwordForm .btnRow ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.blueoceanLogo {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.passwordGrid .topLogoContainer {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.passwordFormContainer {
		align-items: flex-start !important;
	}
	.passwordFormContainer ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.passwordGrid .logo {
		align-self: flex-end;
	}
	.passwordForm {
		width: 100%;
		max-width: none !important;
		padding: 30px 20px !important;
	}
	.passwordForm .btnRow {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.passwordForm .btnRow ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.blueoceanLogo {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
.passwordGrid,
.passwordGridRow {
	height: 100%;
}
.passwordGrid .leftContainer {
	height: 100%;
	background-color: var(--ion-color-primary);
	background: var(--login-page-image);
    background-size: cover;
}
.passwordGrid .rightContainer {
	display: flex;
	flex-direction: column;
}
.passwordFormContainer {
	flex-grow: 1;
	align-items: center;
}
.passwordGrid ion-button {
	display: flex;
	text-transform: none;
}
.passwordGrid .logo {
	margin-bottom: 0.5em;
	color: var(--ion-color-primary);
	font-weight: bold;
	font-size: 2.5em;
	text-align: center;

	display: block;
	width: 100%;
	min-height: 108px;
	background-color: white;
	background: var(--robot-logo);
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 0.8em;
}
.passwordGrid .lbForgotten {
	display: block;
	width: 100%;
	color: var(--ion-lb-color-light);
	padding-bottom: 0.5em;
}
.passwordGrid .lbInstruction {
	display: block;
	max-width: 400px;
	color: var(--ion-text-color);
	padding-bottom: 0.5em;
	margin-top: 1.5em;
	text-align: left !important;
}
.passwordForm {
	padding: 20px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.passwordForm ion-row {
	padding-top: 1em;
}
.passwordForm .forgotPswList {
	width: 100%;
}
.passwordForm .btnRow {
	padding-top: 3em;
}
.passwordForm .btnRow ion-col {
	padding-left: 1em;
	margin-right: 1em;
}
.passwordForm .btnRow ion-button {
	max-width: 300px;
	margin: 0 auto;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.errorContainer {
	color: var(--ion-color-danger);
}
.dividerContainer {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.dividerContainer div:nth-of-type(1) {
	flex-grow: 1;
}
.dividerContainer div:nth-of-type(2) {
	margin-top: 1.15em;
}
.dividerContainer div:nth-of-type(3) {
	flex-grow: 1;
}
.divider {
	width: 95%;
	height: 1px;
	background-color: var(--ion-background-color-divider);
	border-top: var(--ion-border-top-color-divider);
	border-bottom: var(--ion-border-bottom-color-divider);
}
.blueoceanLogo {
	fill: var(--ion-background-color-divider);
	width: 70px;
	height: 50px;
	/* font-size: 70px!important; */
}
.lbOr {
	margin-top: 1.5em;
}
.txtLogin {
	color: var(--ion-color-primary);
	text-transform: uppercase;
}
.txtLogin:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
.goToLoginCol {
	padding-top: 2.5em;
}
.checkMarkContainerError {
	bottom: 120px !important;
}
.checkMarkConfirmationContainer{
	position: absolute;
    bottom: 32px;
    right: 59px;
    z-index: 1000;
}
