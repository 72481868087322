.addModal {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.mainContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 50px;
}
.modalContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.groupNameContainer div:first-child {
	width: 100%;
}
.modalContent .itemsContent {
	flex-grow: 1;
}
.itemsContent ion-list {
	width: 100%;
}
.header {
	width: 100%;
}
.modalHeader {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.headerContainer {
	display: flex;
	margin: 0 auto;
}
.headerIcon {
	margin-right: 0.5rem;
}
.headerTitle {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.headerHint {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.usersRow {
	margin-top: 1.2rem;
	margin-left: 0.5rem;
}
.usersRow ion-col {
	flex: 1;
	overflow-y: auto;
}
.usersRow ion-col:first-child {
	padding-right: 2.4rem;
}
.usersList,
.noSelection {
	overflow-y: auto;
	max-height: 300px;
	padding: 5px !important;
}
.noSelection {
	margin-top: 0.9rem;
}
.noSelection ion-label {
	color: var(--ion-color-gray);
}
.noUsers {
	display: flex;
	margin-top: 1.3rem;
	color: var(--ion-color-gray);
}
.usersSelected {
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 1.4rem;
	color: var(--ion-color-gray);
}
.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.btnRow ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.cancelBtn {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.colTitle {
	text-transform: capitalize;
	font-family: 'Industry';
	font-size: 18px !important;
	margin-bottom: 0.9rem;
}
.searchBar {
	--placeholder-color: var(--ion-text-color);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
}
.searchBar ion-icon {
	color: var(--ion-text-color) !important;
}
.searchBar input {
	padding-left: 0 !important;
	border-bottom: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
}
.userItem {
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	--inner-padding-end: 0;
}
.userPicContainer {
	width: 62px;
	height: 38px;
	margin-left: 0.375rem;
}
.userPic {
	height: 100%;
	width: 42px;
	font-size: 1.8em;
	margin-left: 0.3em;
}
.userName {
	margin-left: 0.5rem;
	font-weight: 500;
	flex-grow: 1;
	max-width: 88px;
}
.selectBtn {
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	/* margin: 0 0.5rem 0 0; */
	width: 23px;
	height: 23px;
}
.selectBtn ion-icon.fabIcon {
	width: 1rem;
	height: 1rem;
}
.hidden {
	display: none;
}
