.chartContainerNoData {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px !important;
}

.chartContainerNoData .defaultIconContainer {
	display: flex;
	align-items: flex-end;
	color: var(--ion-color-gray-tint);
}

.chartContainerNoData .defaultIconContainer p {
	font-size: 20px;
	font-weight: 700;
	color: var(--ion-color-gray-tint);
}

.chartContainerNoData .defaultTextContainer {
	color: var(--ion-color-gray);
	text-align: center;
}
