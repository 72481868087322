.personIcon {
	margin-right: 0.5rem;
}
/* Profile settings */
.formGrid {
	width: 100%;
	padding: 0;
}
.formGrid ion-list {
	width: 100%;
	padding: 0;
}
.formGrid .firstCol {
	margin-right: 5rem;
}
.formGrid ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}
.formGrid ion-list-header {
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}
.msgContainer {
	flex: 1;
	margin-top: 1.5rem;
	width: 100%;
	color: var(--ion-color-primary-contrast);
}
.fullImage {
	min-width: 200px;
	min-height: 200px;
}
.userAvatar {
	margin: auto !important;
	height: 200px;
	width: 200px;
}
.urlExpand {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.expandInput {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.iconContainer {
	width: max-content;
}
.icon {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.iconExpanded {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.hidden {
	display: none;
}
.chkContainer {
	margin-top: 0 !important;
	--padding-start: 0;
	padding-left: 1rem;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
}

.chkContainerPrinciple {
	font-weight: bold;
}
.chkContainer ion-label {
	font-size: 15px !important;
	padding-top: 0.15rem;
	padding-left: 0.5rem;
}
.selectContainer {
	margin: 0 0.5em 0.5em 0.5em;
}
.profileListHeader {
	--background: transparent !important;
}
.profileHeader {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}
.linkContainer {
	display: flex;
	margin-top: 3rem;
}
.linkContainer ion-icon {
	color: var(--ion-color-primary);
}
.link {
	color: var(--ion-color-primary);
}
.link:hover,
.linkContainer ion-icon:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
/* Notifications settings */
.notificationsGrid {
	margin: 0;
}
.notifyWhenRow {
	background-color: var(--ion-main-background-color);
}
.notifyWhenRow ion-item {
	--background: var(--ion-main-background-color);
	padding-left: 1rem;
}
.notifyWhenRow ion-label {
	text-transform: uppercase;
	font-family: 'Industry-Demi';
	margin: 0;
}
.notifyWhenRow ion-checkbox {
	margin: 0;
}

.innerLabelCheckbox {
	margin-left: 10px;
}
.submitCol ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}

.btnRow {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.btnRow ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}

.detailsHeader {
	margin-top: 1.063rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
	padding: 0.8rem 1rem;
	font-weight: bold;
}

.agreementRowContent {
	height: 100%;
	margin-left: 0.5rem;
	font-family: Roboto;
	font-size: 15px;
	color: #1e1f22;
	line-height: 1.33;
	text-align: left;
}
.detailsCol {
	margin-right: 1rem;
}
.noTopPadding {
	padding-top: 0;
}

.agreementRow {
	height: 50px !important;
	width: 100%;
	border-bottom: 1px solid #f1f1f3;
	align-items: center;
}

.inputLbAgreement {
	color: var(--ion-color-primary) !important;
}
.inputLbAgreement:hover {
	text-decoration: underline;
	color: #377831 !important;
	cursor: pointer !important;
}
.errorContainer {
	color: var(--ion-color-danger);
}

.formItemContainer {
	margin: 0 0.5em 0.5em;
}

.formItem {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.formItem:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.formItem:active {
	--highlight-background: var(--form-field-active-color);
}

.formItem ion-label {
	margin-bottom: 10px;
}

.formItem ion-label.lbColor {
	color: var(--ion-text-color) !important;
}

.formItem .colorFocused,
.formItem ion-label.colorFocused {
	color: var(--ion-color-primary) !important;
}

.formItem .hasFocus,
.formItem ion-label.hasFocus {
	color: var(--ion-color-primary) !important;
}

.formItem ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.checkMarkContainer {
	bottom: 90px !important;
}

.checkMarkConfirmationContainer {
	position: absolute;
	bottom: 17px;
	right: 64px;
	z-index: 1000;
}

.checkMarkContainerError {
	bottom: 105px !important;
}

.settingsListHeader {
	margin: 0px !important;
}
.labelFont {
	font-family: 'Industry-Demi';
}
