@import url(https://themes.googleusercontent.com/fonts/css?kit=Ql-0kofkZ8ZK35GTMQezzyenZfp837hVvoP-hutgaAqdU2UdszIFv-JbwcPrcubs);
@media (max-width: 576px) {
	.Menu_mainMenu__1E27s .Menu_logo__2yFIt {
		min-height: 40px !important;
	}
	.Menu_toolBar__2_MEq {
		height: auto !important;
	}
	.Menu_menuItem__3or_u {
		--min-height: auto !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.Menu_mainMenu__1E27s .Menu_logo__2yFIt {
		min-height: 40px !important;
	}
	.Menu_toolBar__2_MEq {
		height: auto !important;
	}
	.Menu_menuItem__3or_u {
		--min-height: auto !important;
	}
}
.Menu_mainMenu__1E27s {
	max-width: 260px;
	border: none;
}
.Menu_mainMenu__1E27s.Menu_contracted__uQfQ7 {
	min-width: 80px;
	max-width: 80px;
	overflow: hidden;
}
.Menu_absolute__89zyi {
	position: absolute;
	z-index: 1;
}
.Menu_toolBar__2_MEq {
	--background: var(--ion-color-toolbar);
	height: var(--ion-height-toolbar);
}
.Menu_toolBarCollapse__1nKER {
	display: flex;
}
.Menu_toolBar__2_MEq:hover {
	cursor: pointer;
}
.Menu_avatarContainer__3a871 {
	margin-left: 17px;
	display: flex;
	width: calc(100%);
}
.Menu_userAvatar__2ozzq {
	padding-top: 15px;
	width: 38px;
	min-width: 38px;
	margin-right: 0.5em !important;
}
.Menu_userNameLb__2SFyT {
	text-transform: none;
	color: #ffffff;
	margin-top: 25px;
	flex-grow: 1;
}
.Menu_headerIcon__8jA1t {
	color: var(--ion-header-icon-color);
	/* --color-activated: black; */
}
.Menu_profilePic__15FJK {
	height: 38px;
	min-height: 38px;
}
.Menu_logo__2yFIt {
	padding-left: 0.3em;
	font-weight: bold;
	font-size: 2em;
	color: var(--ion-color-primary-contrast) !important;
}
.Menu_mainMenuIcon__PTVTw {
	width: 32px;
	color: var(--ion-color-primary-contrast);
	margin-right: 0.6em;
	margin-top: 0.15em;
}
.Menu_mainMenuIconCollapse__1mgnR {
	width: 32px;
	color: var(--ion-color-primary-contrast);
	margin-right: 24px;
}
.Menu_mainMenuIcon__PTVTw:hover {
	cursor: pointer;
}
.Menu_menuList__1q2A5 {
	background-color: var(--ion-color-menu);
	height: 100%;
	z-index: 0 !important;
	overflow-y: auto;
}
.Menu_menuList__1q2A5 > ion-item > ion-icon {
	padding-right: 1em;
}
.Menu_menuToggle__2n88O {
	display: flex;
}
.Menu_menuToggleCollapse__ZI0XV {
	display: inline-block;
}
.Menu_menuItemContainer__3YcyS {
	display: flex;
	--min-height: 53px;
	width: 100%;
}
.Menu_menuItem__3or_u {
	--ripple-color: transparent;
	-webkit-transform: none;
	        transform: none;
	--background: var(--ion-color-menu);
	--background-hover: var(--ion-background-menu-item-hover);
	--background-hover-opacity: 1;
	--min-height: 53px;
	border-radius: 5px;
	margin-left: 10.5px;
	margin-right: 15.5px;
	width: 100%;
}

.Menu_menuItemCollapse__y3wrY {
	--ripple-color: transparent;
	-webkit-transform: none;
	        transform: none;
	--background: var(--ion-color-menu);
	--background-hover: var(--ion-background-menu-item-hover);
	--background-hover-opacity: 1;
	--min-height: 53px;
	border-radius: 5px;
	width: 60px;
	margin-left: 9px;
	margin-right: 10px;
}
.Menu_menuItemIdentifier__NJL0i {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 5px;
	--min-height: 53px;
}
.Menu_noMenuIdentifier__e9kjg {
	width: 0px;
}
.Menu_menuItemContainer__3YcyS:hover .Menu_menuItemIdentifier__NJL0i {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 5px;
	min-height: 53px;
	background: var(--ion-background-menu-item-hover);
}
.Menu_menuTitle__2m8Ku {
	color: var(--ion-color-primary-contrast) !important;
}
.Menu_menuTitleHidden__2GdRv {
	display: none !important;
}
.Menu_menuIcon__QU3Hd {
	color: var(--ion-color-menu-icon);
	margin-right: 0.6em;
}
.Menu_menuIconCollapse__4PwxU {
	padding-left: 5px;
}
.Menu_menuBarHeader__1mpZf {
	background-color: var(--ion-color-menu);
}
.Menu_subMenuIcon__3N7kr {
	margin-left: 1.5em;
}
.Menu_mainMenu__1E27s.Menu_contracted__uQfQ7 .Menu_menuIcon__QU3Hd {
	margin-right: 0 !important;
}
.Menu_activeItem__1II2y {
	--background: var(--ion-background-menu-item-active);
}
.Menu_activeItem__1II2y .Menu_menuItemIdentifier__NJL0i {
	background: var(--ion-background-menu-item-active);
}

.Menu_activeItem__1II2y .Menu_menuIcon__QU3Hd {
	color: var(--ion-color-menu-icon);
}
.Menu_menuItem__3or_u:hover .Menu_menuIcon__QU3Hd {
	color: var(--ion-color-menu-item-hover);
}
.Menu_hideSubMenu__RXhNU {
	display: none;
}
.Menu_showSubMenu__1N34q {
	display: block;
}
.Menu_hidden__ORFD7 {
	display: none;
}
.Menu_versionContainer__2gncQ {
	position: absolute;
	width: 100%;
	bottom: 2rem;
	color: var(--ion-color-gray);
	text-align: center;
}
.Menu_versionContainer__2gncQ:hover {
	color: var(--ion-color-gray-shade);
	cursor: auto;
}

.Menu_inviteUserBtn__115fv {
	--color: var(--ion-color-primary);
	--border-color: var(--ion-color-primary);
	--border-width: 2px;
	--background: var(--ion-text-color);
	--background-hover: white;
	--background-hover-opacity: 1;
	--border-radius: 5px;
	text-transform: initial;
	height: 45px;
	width: 150px;
	margin-left: 30px;
}

.Menu_inviteUserRounded__WhivY {
	display: flex;
	justify-content: center;
	height: 50px;
	width: 50px;
	margin-left: 15px;
	--padding-end: 0;
	--padding-start: 0.5rem;
	--color: white;
	--border-color: var(--ion-color-primary);
	--border-width: 2px;
	--background: var(--ion-text-color);
	--background-hover-opacity: 1;
	--border-radius: 50%;
}

.Menu_inviteUserRounded__WhivY:hover,
.Menu_inviteUserBtn__115fv:hover {
	--background-hover: white;
	--color-hover: var(--ion-text-color);
	--border-color: white;
}

.Menu_addIcon__3yBs4 {
	font-size: 1.5rem;
	margin-right: 10px;
}

/* .usersContent {
	height: 100%;
	display: flex;
	flex-flow: column;
	padding: 0 !important;
}
ion-list {
	padding: 0 !important;
}
.inviteUserModal {
	height: 813px;
    width: 613px;
}
.addForm {
	height: 100%;
	margin: 20px 90px 90px!important;
}
.formGrid {
	display: flex;
	flex-direction: column;
	height: inherit;
	overflow-y: auto;
}.formGrid ion-row:first-child {
	flex-grow: 1;
}
.addList {
	width: 100%;
	padding-bottom: 0;
}
.groupSelect,
.deviceSelect,
.organizationSelect {
	margin: 10px 10px 10px!important;
	border-style: hidden hidden solid hidden 2px!important;
}
.inviteList {
	/* margin: 6rem 6.875rem; */
/* }
.inviteHeader {
	display: flex;
    flex-direction: column;
}
.inviteUserHint {
	width: 244px;
	height: 20px;
	opacity: 0.5;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: var(--ion-background-menu-item-hover);
}

.inviteUserTitle {
	text-align: left;
	width: 100%;
	height: 33px;
	font-family: Industry-Demi;
	font-size: 28px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.04;
	letter-spacing: normal;
	color: var(--ion-color-primary);
}

.headerIcon {
	color: var(--ion-color-primary);
}

.organizationSelect {
	font-family: Roboto;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.38;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-text-color);
	margin: 10px 10px 10px!important;
}*/

.InviteUser_selectContainer__3bwmj {
	margin: 0 0.5em 0.5em 0.5em;
}

.InviteUser_width__1POQP {
	width: 9px;
}

.InviteUser_hidden__cmSle {
	display: none;
}

.InviteUser_alignCenter__6W8vR {
	text-align: center;
}

.InviteUser_fullWidth__1nxux {
	width: 100%;
}

.InviteUser_addModal__16SG6 {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.InviteUser_addForm__20izu {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.InviteUser_modalContent__4fP7F {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.InviteUser_modalContent__4fP7F ion-row:first-child {
	flex-grow: 1;
}
.InviteUser_modalHeader__3Xfhh {
	display: flex;
    flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
    margin-top: 2.5rem;
}
.InviteUser_headerContainer__339l- {
	display: flex;
	margin: 0 auto;
}
.InviteUser_headerIcon__3vlCy {
	margin-right: 0.5rem;
}
.InviteUser_icon__1s6O0 {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.InviteUser_iconExpanded__2ahW9 {
    color: var(--ion-color-gray-shade);
    width: 26px;
	height: 26px;
    margin: 14px 7px 10px 16px;
}
.InviteUser_headerTitle__3H0Y3 {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.InviteUser_headerHint__2gnED {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.InviteUser_formList__3UO_j {
	width: 100%;
	margin: 0 3rem;
}
.InviteUser_nameRow__Sxp8o {
    margin-top: 0.8rem;
}
.InviteUser_select__3zIp- {
	margin-top: 0.75rem;
}
.InviteUser_select__3zIp- div {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
}
.InviteUser_selectLb__20V6S {
	font-size: 13px!important;
    margin-left: 0.5rem;
}
.InviteUser_expandInput__UAKdz {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.InviteUser_iconContainer__3buoL {
	width: -webkit-max-content;
	width: max-content;
}
.InviteUser_chkRow__213fr {
	padding: 0 3rem;
}
.InviteUser_btnRow__2i7Vs {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.InviteUser_btnRow__2i7Vs ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.InviteUser_btnRow__2i7Vs ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.InviteUser_submitCol__2RIa1 ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.InviteUser_cancelBtn__1MnKN {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.FormInputListItem_formItemContainer__ugynB {
	margin: 0 0.5em 0.5em;
}
.FormInputListItem_formItem__nuks3 {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}
.FormInputListItem_formItem__nuks3:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}
.FormInputListItem_formItem__nuks3:active {
	--highlight-background: var(--form-field-active-color);
}
.FormInputListItem_formItem__nuks3 ion-label {
	margin-bottom: 10px;
}
.FormInputListItem_formItem__nuks3 ion-label[position='floating'] {
	margin-bottom: 8px;
}
.FormInputListItem_formItem__nuks3 ion-label.FormInputListItem_lbColor__XydcF {
	color: var(--ion-text-color) !important;
}
.FormInputListItem_formItem__nuks3 .FormInputListItem_colorFocused__1Kn8x,
.FormInputListItem_formItem__nuks3 ion-label.FormInputListItem_colorFocused__1Kn8x {
	color: var(--ion-color-primary) !important;
}
.FormInputListItem_formItem__nuks3 .FormInputListItem_colorError__sidfH,
.FormInputListItem_formItem__nuks3 ion-label.FormInputListItem_colorError__sidfH {
	color: var(--form-field-error-color) !important;
}
.FormInputListItem_formItem__nuks3 ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}
.FormInputListItem_errorItem__inJiu {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}
.FormInputListItem_errorMsg__2Mxkm {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}
.FormInputListItem_inputIcon__1QgeD {
	display: inline-flex !important;
	width: 100%;
}
.FormInputListItem_inputIconSize__17Pkb {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: background-color 50000s ease-in-out 0s;
	transition: background-color 50000s ease-in-out 0s;
}

@media (max-width: 576px) {
	.Login_loginGrid__13tP0 .Login_topLogoContainer__30mHT {
		flex-grow: 1;
	}
	.Login_loginFormContainer__2KBjY {
		align-items: flex-start !important;
	}
	.Login_loginFormContainer__2KBjY ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.Login_loginGrid__13tP0 .Login_logo__15hBy {
		align-self: flex-end;
	}
	.Login_loginForm__3AlQf {
		width: 100%;
		max-width: none !important;
		padding: 0 20px 20px !important;
	}
	.Login_loginForm__3AlQf .Login_btnRow__3b4Ng {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.Login_loginForm__3AlQf .Login_btnRow__3b4Ng ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.Login_loginForm__3AlQf .Login_btnRow__3b4Ng ion-button {
		max-width: 100% !important;
		margin: none !important;
	}
	.Login_blueoceanLogo__2ZTKO {
		max-width: 55px !important;
		bottom: 20px !important;
	}
	.Login_loginGrid__13tP0 .Login_dividerRow__2W3M3 {
		width: 100%;
		padding: 0px 40px;
		max-width: 100%;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.Login_loginGrid__13tP0 .Login_topLogoContainer__30mHT {
		flex-grow: 1;
	}
	.Login_loginFormContainer__2KBjY {
		align-items: flex-start !important;
	}
	.Login_loginFormContainer__2KBjY ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.Login_loginGrid__13tP0 .Login_logo__15hBy {
		align-self: flex-end;
	}
	.Login_loginForm__3AlQf {
		width: 100%;
		max-width: none !important;
		padding: 0 20px 20px !important;
	}
	.Login_loginForm__3AlQf .Login_btnRow__3b4Ng {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.Login_loginForm__3AlQf .Login_btnRow__3b4Ng ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.Login_loginForm__3AlQf .Login_btnRow__3b4Ng ion-button {
		max-width: 100% !important;
		margin: none !important;
	}
	.Login_blueoceanLogo__2ZTKO {
		max-width: 55px !important;
		bottom: 20px !important;
	}
	.Login_loginGrid__13tP0 .Login_dividerRow__2W3M3 {
		width: 100%;
		padding: 0px 40px;
		max-width: 100%;
	}
}

.Login_loginGrid__13tP0,
.Login_loginGridRow__2nGtW {
	height: 100%;
}
.Login_loginGrid__13tP0 .Login_leftContainer__1L-mm {
	height: 100%;
}
.Login_loginGrid__13tP0 .Login_homeCover__2mukW {
	display: block;
	width: 100%;
	height: 100%;
	background: var(--login-page-image);
	background-size: cover;
	background-repeat: no-repeat;
}

.Login_loginGrid__13tP0 .Login_homeCover__2mukW.Login_uvd__2a91w {
	background: url('/assets/img/GoBe_Cover.png'); /*change me*/
	background-size: cover;
}

.Login_loginGrid__13tP0 .Login_homeCover__2mukW.Login_beam__2zjOI {
	background: url('/assets/img/beam-cover.png');
	background-size: cover;
}

.Login_loginGrid__13tP0 .Login_homeCover__2mukW.Login_gobe__1jqdX {
	background: url('/assets/img/GoBe_Cover.png');
	background-size: cover;
}

.Login_loginGrid__13tP0 .Login_rightContainer__1PMF_ {
	display: flex;
	flex-direction: column;
}
.Login_loginFormContainer__2KBjY {
	flex-grow: 1;
	padding-top: 40px;
}
.Login_loginGrid__13tP0 ion-button {
	display: flex;
	text-transform: none;
}
.Login_loginGrid__13tP0 .Login_logoItem__2kMDc {
	--border-color: white;
}
.Login_loginGrid__13tP0 .Login_logo__15hBy {
	color: var(--ion-color-primary);
	font-weight: bold;
	font-size: 2.5em;
	text-align: center;

	display: block;
	width: 100%;
	min-height: 104px;
	margin-bottom: 0.8em;
	background: var(--robot-logo);
	background-color: white;
	background-repeat: no-repeat;
	background-position: center;
}

.Login_loginGrid__13tP0 .Login_logo__15hBy.Login_uvd__2a91w {
	background: url('/assets/img/gobe-robots-logo.svg'); /*change me*/
	background-color: white;
	background-repeat: no-repeat;
	background-position: center;
}

.Login_loginGrid__13tP0 .Login_welcomeLb__ID2nt {
	width: 100%;
	color: var(--ion-color-gray);
}
.Login_loginForm__3AlQf {
	padding: 20px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.Login_loginForm__3AlQf ion-row {
	padding-top: 1.15em;
	margin: 0 auto;
}
.Login_loginForm__3AlQf .Login_loginList__1ZZMm {
	width: 100%;
}
.Login_loginForm__3AlQf .Login_btnRow__3b4Ng ion-col {
	padding-left: 1em;
	margin-right: 1em;
}
.Login_loginForm__3AlQf .Login_btnRow__3b4Ng ion-button {
	margin: 0 auto;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.Login_forgotPswLinkWrapper__3-uOG {
	text-align: right;
	padding-right: 6px;
}
.Login_forgotPswLink__3cWGX {
	color: var(--ion-color-primary);
}
.Login_forgotPswLink__3cWGX:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
.Login_errorContainer__1ENR_ {
	color: var(--ion-color-danger);
}
.Login_dividerRow__2W3M3 {
	padding: 0px 40px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.Login_dividerContainer__oFNDS {
	display: flex;
	align-items: center;
	justify-content: center;
}
.Login_dividerContainer__oFNDS div:nth-of-type(1) {
	flex-grow: 1;
}
.Login_dividerContainer__oFNDS div:nth-of-type(3) {
	flex-grow: 1;
}
.Login_divider__3_DyG {
	height: 2px;
	background-color: var(--ion-background-color-divider);
	width: 100%;
}
.Login_dividerFont__1HRrp {
	margin-left: 20px;
	margin-right: 20px;
	color: var(--ion-background-color-divider);
}
.Login_logoContainer__2GEFP {
	width: 109px;
	height: 67px;
}
.Login_blueoceanLogo__2ZTKO {
	width: calc(100% - 30px);
	height: 38px;
	margin: 9px 15px 20px;
}
.Login_loginBtn__3kxjw,
.Login_backBtn__3LMZu {
	font-size: 18px;
	max-width: 185px;
	--border-width: 1px;
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
}

.Login_loginSSOBtn__3vU1d {
	font-size: 18px;
	max-width: 135px;
	padding: 0px;
	width: 205px;
	align-self: center;
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.Login_ssoElem__1TnAr {
	margin-right: 0.5em !important;
	margin-left: 0.5em !important;
	width: 100%;
}
.Login_ssoRow__AOjf3 {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 0px;
	padding-top: 0px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.Login_ssoButtonRow__1stTZ {
	max-width: 65%;
	display: block;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
}
.Login_ssoPadding__iQrW3 {
	padding-top: 42px !important;
	padding-bottom: 0px !important;
}
.Login_ssoRow__AOjf3 ion-button {
	margin: auto;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	text-transform: uppercase;
	--background-activated: var(--ion-color-secondary);
}

.Login_loginRow__T0Unl {
	padding-top: 0px !important;
}
.Login_noAccountAction__1_9m9 {
	width: 100%;
	text-align: center;
	margin: 32px 0;
}
.Login_ssoTxt__Mvyi2 {
	position: absolute;
	right: 8px;
}
.Login_ssoIcon__M1H7l {
	position: absolute;
	left: 2px;
}
.Login_ssoLoginBtnRow__1pxat ion-col {
	padding-left: 1.5em;
	margin-right: 0em;
}
.Login_ssoLoginBtnRow__1pxat ion-button {
	/* margin-right: 0em;
	margin-left: 2em; */
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.Login_provideEmailFont__oYG36 {
	color: var(--secondary);
}
.Login_hintContainer__3fudk {
	margin-top: 0.875rem;
	margin-bottom: 2rem;
}
.Login_hintContainer__3fudk ion-label {
	white-space: pre-line !important;
}
.Login_success__1cGwE {
	color: #56ae4d !important;
	padding: 10px;
	width: -webkit-min-content;
	width: min-content;
}
.Login_error__yIn7U {
	color: #9e0e0e !important;
	padding: 10px;
	width: -webkit-min-content;
	width: min-content;
}

.FMFooterLogo_wrapper__T_ra2 {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 0 15%;
}

.FMFooterLogo_line__LBYIy {
	flex-grow: 1;
	height: 2px;
	background-color: #b2b2b2;
}

.FMFooterLogo_footerImageWrapper__WEOk- {
	margin-bottom: 14px;
}

.FMFooterLogo_blueOceanLogo__3aw8k {
	margin: 15px 15px 28px;
}
.Checkbox_container__20jlM {
    display: block;
    position: relative;
    padding-left: 2em;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1em;
    -webkit-user-select: none;
    user-select: none;
}
  
/* Hide the browser's default checkbox */
.Checkbox_container__20jlM input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.Checkbox_checkmark__J3qaU {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    border: solid 1px #8e8c89;
    background-color: #ffffff;
}
  
/* On mouse-over, add a grey background color */
.Checkbox_container__20jlM:hover input ~ .Checkbox_checkmark__J3qaU {
    border: solid 2px #666666;
}
  
/* When the checkbox is checked, add a blue background */
.Checkbox_container__20jlM input:checked ~ .Checkbox_checkmark__J3qaU {
    background-color: var(--ion-color-primary);
    border: none;
}
  
/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox_checkmark__J3qaU:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the checkmark when checked */
.Checkbox_container__20jlM input:checked ~ .Checkbox_checkmark__J3qaU:after {
    display: block;
}
  
/* Style the checkmark/indicator */
.Checkbox_container__20jlM .Checkbox_checkmark__J3qaU:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
@media (max-width: 576px) {
	.LoginTemplate_contentGrid__3MlQn .LoginTemplate_topLogoContainer__1Hzeq {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.LoginTemplate_formContainer__2b_st {
		align-items: flex-start !important;
	}
	.LoginTemplate_formContainer__2b_st ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.LoginTemplate_contentGrid__3MlQn .LoginTemplate_logo__2zZwK {
		align-self: flex-end;
	}
	.LoginTemplate_btnRow__2XdnT {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.LoginTemplate_btnRow__2XdnT ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.LoginTemplate_blueoceanLogo__3M9yX {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.LoginTemplate_contentGrid__3MlQn .LoginTemplate_topLogoContainer__1Hzeq {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.LoginTemplate_formContainer__2b_st {
		align-items: flex-start !important;
	}
	.LoginTemplate_formContainer__2b_st ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.LoginTemplate_contentGrid__3MlQn .LoginTemplate_logo__2zZwK {
		align-self: flex-end;
	}
	.LoginTemplate_btnRow__2XdnT {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.LoginTemplate_btnRow__2XdnT ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.LoginTemplate_blueoceanLogo__3M9yX {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
.LoginTemplate_contentGrid__3MlQn,
.LoginTemplate_contentGridRow__Ylayg {
	height: 100%;
}
.LoginTemplate_contentGrid__3MlQn .LoginTemplate_leftContainer__1ADqg {
	height: 100%;
}
.LoginTemplate_homeCover__2iyom {
	display: block;
	width: 100%;
	height: 100%;
	background-size: cover;
	background: var(--login-page-image);
	background-size: contain;
	background-repeat: no-repeat;
}

.LoginTemplate_contentGrid__3MlQn .LoginTemplate_rightContainer__1ePN6 {
	display: flex;
	flex-direction: column;
}
.LoginTemplate_contentGrid__3MlQn ion-button {
	display: flex;
	text-transform: none;
}
.LoginTemplate_contentGrid__3MlQn .LoginTemplate_logo__2zZwK {
	margin-bottom: 0.5em;
	color: var(--ion-color-primary);
	font-weight: bold;
	font-size: 2.5em;
	text-align: center;
	display: block;
	width: 100%;
	min-height: 61px;
	background: var(--robot-logo);
	background-color: white;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 0.8em;
}
.LoginTemplate_contentGrid__3MlQn .LoginTemplate_lbInstruction__1uW_Z {
	display: block;
	max-width: 400px;
	color: var(--ion-color-gray);
	padding-bottom: 0.5em;
	margin-bottom: 1.5em;
	text-align: left !important;
	text-transform: capitalize;
}
.LoginTemplate_formContainer__2b_st {
	flex-grow: 1;
	align-items: center;
}
.LoginTemplate_formContainer__2b_st ion-list {
	min-width: 450px;
}
.LoginTemplate_btnRow__2XdnT {
	padding-top: 0.5em;
}
.LoginTemplate_btnRow__2XdnT ion-col {
	padding-left: 1em;
	margin-right: 1em;
}
.LoginTemplate_btnRow__2XdnT ion-button {
	max-width: 250px;
	margin: 0 auto;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.LoginTemplate_dividerContainer__35e-v {
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.LoginTemplate_dividerContainer__35e-v div:nth-of-type(1) {
	flex-grow: 1;
}
.LoginTemplate_dividerContainer__35e-v div:nth-of-type(3) {
	flex-grow: 1;
}
.LoginTemplate_divider__8GLFb {
	height: 2px;
	background-color: var(--ion-background-color-divider);
}
.LoginTemplate_logoContainer__1_Kbs {
	width: 109px;
	height: 67px;
}
.LoginTemplate_blueoceanLogo__3M9yX {
	width: calc(100% - 30px);
	height: 38px;
	margin: 9px 15px 20px;
}

.FMBrandedSplitPane_ionSplitPane__1fOlO {
    --side-width: 50%;
    --side-min-width: 50%;
    --side-max-width: 50%;
}
.FMHeaderGoBe_outerContainer__34aV5 {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.FMHeaderGoBe_innerImgContainer__3GJVl {
	min-width: 156px;
	width: 156px;
}

.FMHeaderGoBe_innerMsgContainer__2XzjV {
	margin: 12px 0;
	color: var(--ion-text-color-light);
    font-size: var(--ion-font-size);
}

.FMButtons_buttonPrimary__SjUwy {
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 0.5px;

	width: 100%;
	--padding-end: 34px;
	--padding-start: 34px;

	border: 1px solid var(--ion-color-primary);
	border-radius: var(--ion-btn-border-radius);

	--color-hover: var(--ion-color-primary) !important;

	--background-hover: var(--ion-color-primary-contrast) !important;
	--background-hover-opacity: 1;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;

	--box-shadow: none !important;
}

@media (min-width: 576px) {
	.FMButtons_buttonPrimary__SjUwy {
		width: auto;
	}
}

.AccountCreate_contentContainer__X7o_l {
	height: 100%;
	min-height: 100%;
	box-sizing: border-box;
	padding-top: 64px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.AccountCreate_confirmationContent__2GCgu ion-icon {
    font-size: 100px;
}

.AccountCreate_confirmationContent__2GCgu h2 {
	padding: 0;
	margin: 28px 0;
	font-family: 'Industry';
	font-size: 28px !important;
	color: --ion-text-color;
}

.AccountCreate_confirmationContent__2GCgu p {
	padding: 0;
	margin: 0;
	font-family: inherit;
	font-size: 18px !important;
	color: --ion-text-color;
	text-align: center;
	max-width: 80%;
}

.AccountCreate_confirmationContent__2GCgu .AccountCreate_headerContainer__9yVUE {
    margin-bottom: 15vh;
}

.AccountCreate_formContainer__24Y-d {
	flex: 1 1 auto;
	max-width: 475px;
}

.AccountCreate_formContainer__24Y-d ion-row {
	height: 74px;
}

.AccountCreate_formContainerError__2_DiG ion-row {
	height: 84px;
}

.AccountCreate_disclaimer__8sVdp {
	margin: 42px 0 12px;
	color: var(--ion-text-color-light);
	font-size: var(--ion-font-size);
}

.AccountCreate_privacyPolicyButton__1vNxl {
	display: inline;
	color: var(--ion-color-primary);
	cursor: pointer;
}

.AccountCreate_buttonsContainer__1HhsH {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.AccountCreate_buttonsContainer__1HhsH a {
	font-size: var(--ion-font-size);
	text-decoration: none;
}

.AccountCreate_confirmationContainer__k9dQH {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.AccountCreate_footerContainer__29YYV {
	width: 100%;
}

.PrivacyPolicyModal_privacyPolicyModal__15IV4 {
	--width: 613px;
}
.PrivacyPolicyModal_agreementContainer__j2jKz {
	width: 100%;
	height: 600px;
	max-height: 600px;
	overflow-y: scroll;
}
.PrivacyPolicyModal_buttonContainer__bBh5e {
	display: flex;
	justify-content: center;
	padding: 34px 0;
}

ul.lst-kix_8uromte14yar-7 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-6 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-2 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-2;
}
ul.lst-kix_8uromte14yar-8 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-3 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-2 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-5 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-4 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-1 {
	list-style-type: none;
}
ul.lst-kix_8uromte14yar-0 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-2.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-2 0;
}
ol.lst-kix_vwawyprdpav-8.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-8 0;
}
.lst-kix_dkg1v55wbi2c-5 > li:before {
	content: '-  ';
}
.lst-kix_dkg1v55wbi2c-4 > li:before {
	content: '-  ';
}
.lst-kix_esf4yiq3lmjd-7 > li:before {
	content: '\0025cb   ';
}
.lst-kix_dkg1v55wbi2c-3 > li:before {
	content: '-  ';
}
.lst-kix_esf4yiq3lmjd-8 > li:before {
	content: '\0025a0   ';
}
.lst-kix_78hcq49ygbyk-4 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '. ';
}
.lst-kix_dkg1v55wbi2c-1 > li:before {
	content: '-  ';
}
.lst-kix_78hcq49ygbyk-5 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '. ';
}
.lst-kix_dkg1v55wbi2c-0 > li:before {
	content: '-  ';
}
.lst-kix_dkg1v55wbi2c-2 > li:before {
	content: '-  ';
}
.lst-kix_78hcq49ygbyk-7 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-6, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-7, decimal) '. ';
}
.lst-kix_78hcq49ygbyk-6 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-6, decimal) '. ';
}
.lst-kix_vwawyprdpav-4 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-4;
}
.lst-kix_78hcq49ygbyk-8 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-4, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-5, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-6, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-7, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-8, decimal) '. ';
}
ul.lst-kix_w4raey95u019-5 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-4 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-3 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-2 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-8 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-7 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-2.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-2 0;
}
ul.lst-kix_w4raey95u019-6 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-2 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-2;
}
ul.lst-kix_w4raey95u019-1 {
	list-style-type: none;
}
ul.lst-kix_w4raey95u019-0 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-8 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-8;
}
ol.lst-kix_78hcq49ygbyk-8.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-8 0;
}
.lst-kix_78hcq49ygbyk-3 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-3, decimal) '. ';
}
.lst-kix_78hcq49ygbyk-2 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-2, decimal) '. ';
}
ul.lst-kix_99lgw0x0zg7c-6 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-6 > li:before {
	content: '\0025cf   ';
}
.lst-kix_78hcq49ygbyk-1 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '.'
		counter(lst-ctn-kix_78hcq49ygbyk-1, decimal) '. ';
}
ul.lst-kix_99lgw0x0zg7c-7 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-4 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-5 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_99lgw0x0zg7c-5 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-4 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_99lgw0x0zg7c-8 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-0 > li:before {
	content: '' counter(lst-ctn-kix_78hcq49ygbyk-0, decimal) '. ';
}
.lst-kix_78hcq49ygbyk-6 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-6;
}
ul.lst-kix_esf4yiq3lmjd-0 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-2 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_esf4yiq3lmjd-1 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-0 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-0;
}
.lst-kix_esf4yiq3lmjd-1 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_esf4yiq3lmjd-2 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-3 > li:before {
	content: '\0025cf   ';
}
ul.lst-kix_esf4yiq3lmjd-3 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-2 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-4 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-3 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-5 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-0 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-6 {
	list-style-type: none;
}
ul.lst-kix_99lgw0x0zg7c-1 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-7 {
	list-style-type: none;
}
ul.lst-kix_esf4yiq3lmjd-8 {
	list-style-type: none;
}
.lst-kix_esf4yiq3lmjd-0 > li:before {
	content: '\0025cf   ';
}
ol.lst-kix_78hcq49ygbyk-1.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-1 0;
}
ol.lst-kix_78hcq49ygbyk-7.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-7 0;
}
ol.lst-kix_vwawyprdpav-7.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-7 0;
}
ul.lst-kix_uvla8mc0uget-1 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-4.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-4 0;
}
ul.lst-kix_uvla8mc0uget-0 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-3 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-2 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-5 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-4 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-7 {
	list-style-type: none;
}
ul.lst-kix_uvla8mc0uget-6 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-1.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-1 0;
}
.lst-kix_johatq9hcprj-1 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-3.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-3 0;
}
.lst-kix_johatq9hcprj-3 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-5 > li:before {
	content: '-  ';
}
.lst-kix_vwawyprdpav-1 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-1;
}
ul.lst-kix_uvla8mc0uget-8 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-6.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-6 0;
}
.lst-kix_johatq9hcprj-7 > li:before {
	content: '-  ';
}
.lst-kix_78hcq49ygbyk-5 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-5;
}
ol.lst-kix_vwawyprdpav-3.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-3 0;
}
.lst-kix_78hcq49ygbyk-4 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-4;
}
.lst-kix_vwawyprdpav-6 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-6;
}
.lst-kix_dkg1v55wbi2c-7 > li:before {
	content: '-  ';
}
.lst-kix_vwawyprdpav-0 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-0;
}
ol.lst-kix_78hcq49ygbyk-4.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-4 0;
}
ul.lst-kix_dkg1v55wbi2c-1 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-2 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-0 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-5 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-1 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '. ';
}
ul.lst-kix_dkg1v55wbi2c-6 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-8 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_dkg1v55wbi2c-3 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-4 {
	list-style-type: none;
}
.lst-kix_vwawyprdpav-2 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'. ';
}
.lst-kix_vwawyprdpav-3 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '. ';
}
.lst-kix_vwawyprdpav-4 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '. ';
}
.lst-kix_vwawyprdpav-5 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-5;
}
.lst-kix_w4raey95u019-3 > li:before {
	content: '\0025cf   ';
}
.lst-kix_99lgw0x0zg7c-8 > li:before {
	content: '-  ';
}
.lst-kix_xzz4ue5r9r5k-2 > li:before {
	content: '\0025a0   ';
}
.lst-kix_xzz4ue5r9r5k-4 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_f8umtzpnrzg4-7 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-1 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-1;
}
.lst-kix_99lgw0x0zg7c-7 > li:before {
	content: '-  ';
}
ul.lst-kix_f8umtzpnrzg4-6 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-4 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_f8umtzpnrzg4-8 {
	list-style-type: none;
}
.lst-kix_xzz4ue5r9r5k-3 > li:before {
	content: '\0025cf   ';
}
.lst-kix_w4raey95u019-7 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_f8umtzpnrzg4-3 {
	list-style-type: none;
}
.lst-kix_99lgw0x0zg7c-4 > li:before {
	content: '-  ';
}
.lst-kix_99lgw0x0zg7c-5 > li:before {
	content: '-  ';
}
ul.lst-kix_f8umtzpnrzg4-2 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-5.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-5 0;
}
ul.lst-kix_dkg1v55wbi2c-7 {
	list-style-type: none;
}
ul.lst-kix_f8umtzpnrzg4-5 {
	list-style-type: none;
}
ul.lst-kix_dkg1v55wbi2c-8 {
	list-style-type: none;
}
ul.lst-kix_f8umtzpnrzg4-4 {
	list-style-type: none;
}
.lst-kix_xzz4ue5r9r5k-0 > li:before {
	content: '\0025cf   ';
}
.lst-kix_w4raey95u019-5 > li:before {
	content: '\0025a0   ';
}
.lst-kix_vwawyprdpav-0 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '. ';
}
.lst-kix_99lgw0x0zg7c-6 > li:before {
	content: '-  ';
}
.lst-kix_w4raey95u019-6 > li:before {
	content: '\0025cf   ';
}
ul.lst-kix_f8umtzpnrzg4-1 {
	list-style-type: none;
}
ul.lst-kix_f8umtzpnrzg4-0 {
	list-style-type: none;
}
.lst-kix_xzz4ue5r9r5k-1 > li:before {
	content: '\0025cb   ';
}
.lst-kix_f8umtzpnrzg4-6 > li:before {
	content: '\0025cf   ';
}
ol.lst-kix_vwawyprdpav-5.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-5 0;
}
.lst-kix_f8umtzpnrzg4-4 > li:before {
	content: '\0025cb   ';
}
.lst-kix_f8umtzpnrzg4-3 > li:before {
	content: '\0025cf   ';
}
.lst-kix_f8umtzpnrzg4-7 > li:before {
	content: '\0025cb   ';
}
.lst-kix_xzz4ue5r9r5k-7 > li:before {
	content: '\0025cb   ';
}
.lst-kix_xzz4ue5r9r5k-6 > li:before {
	content: '\0025cf   ';
}
.lst-kix_f8umtzpnrzg4-5 > li:before {
	content: '\0025a0   ';
}
.lst-kix_uvla8mc0uget-6 > li:before {
	content: '-  ';
}
.lst-kix_xzz4ue5r9r5k-5 > li:before {
	content: '\0025a0   ';
}
.lst-kix_vwawyprdpav-6 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-6, decimal) '. ';
}
.lst-kix_vwawyprdpav-7 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-6, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-7, decimal) '. ';
}
.lst-kix_uvla8mc0uget-7 > li:before {
	content: '-  ';
}
.lst-kix_f8umtzpnrzg4-0 > li:before {
	content: '\0025cf   ';
}
.lst-kix_vwawyprdpav-5 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'. ';
}
.lst-kix_vwawyprdpav-7 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-7;
}
.lst-kix_uvla8mc0uget-8 > li:before {
	content: '-  ';
}
.lst-kix_f8umtzpnrzg4-2 > li:before {
	content: '\0025a0   ';
}
.lst-kix_8uromte14yar-8 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-8 > li:before {
	content: '-  ';
}
.lst-kix_xzz4ue5r9r5k-8 > li:before {
	content: '\0025a0   ';
}
.lst-kix_vwawyprdpav-8 > li:before {
	content: '' counter(lst-ctn-kix_vwawyprdpav-0, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-1, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-2, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-3, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-4, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-5, decimal)
		'.' counter(lst-ctn-kix_vwawyprdpav-6, decimal) '.'
		counter(lst-ctn-kix_vwawyprdpav-7, decimal) '.' counter(lst-ctn-kix_vwawyprdpav-8, decimal)
		'. ';
}
.lst-kix_f8umtzpnrzg4-1 > li:before {
	content: '\0025cb   ';
}
.lst-kix_8uromte14yar-4 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-5 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-2 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-3 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-6 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-7 > li:before {
	content: '-  ';
}
.lst-kix_uvla8mc0uget-0 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-7 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-5 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-6 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-3 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-4 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-0.start {
	counter-reset: lst-ctn-kix_78hcq49ygbyk-0 0;
}
.lst-kix_uvla8mc0uget-5 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-0 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-2 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-1 {
	list-style-type: none;
}
.lst-kix_uvla8mc0uget-4 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-4 {
	list-style-type: none;
}
.lst-kix_8uromte14yar-0 > li:before {
	content: '-  ';
}
.lst-kix_8uromte14yar-1 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-0 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-3 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-6 {
	list-style-type: none;
}
ol.lst-kix_78hcq49ygbyk-5 {
	list-style-type: none;
}
.lst-kix_uvla8mc0uget-1 > li:before {
	content: '-  ';
}
.lst-kix_uvla8mc0uget-3 > li:before {
	content: '-  ';
}
ol.lst-kix_vwawyprdpav-6.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-6 0;
}
ol.lst-kix_78hcq49ygbyk-8 {
	list-style-type: none;
}
.lst-kix_u755olrqzope-1 > li:before {
	content: '-  ';
}
.lst-kix_u755olrqzope-2 > li:before {
	content: '-  ';
}
ol.lst-kix_78hcq49ygbyk-7 {
	list-style-type: none;
}
.lst-kix_uvla8mc0uget-2 > li:before {
	content: '-  ';
}
ul.lst-kix_johatq9hcprj-1 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-7 {
	list-style-type: none;
}
.lst-kix_99lgw0x0zg7c-0 > li:before {
	content: '-  ';
}
.lst-kix_99lgw0x0zg7c-1 > li:before {
	content: '-  ';
}
ul.lst-kix_johatq9hcprj-0 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-8 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-5 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-6 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-3 {
	list-style-type: none;
}
.lst-kix_99lgw0x0zg7c-3 > li:before {
	content: '-  ';
}
ul.lst-kix_xzz4ue5r9r5k-4 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-1 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-0 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-2 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-1 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-3 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-3;
}
ul.lst-kix_u755olrqzope-2 {
	list-style-type: none;
}
ul.lst-kix_xzz4ue5r9r5k-0 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-3 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-4 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-1 > li:before {
	content: '\0025cb   ';
}
ul.lst-kix_u755olrqzope-5 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-0.start {
	counter-reset: lst-ctn-kix_vwawyprdpav-0 0;
}
.lst-kix_99lgw0x0zg7c-2 > li:before {
	content: '-  ';
}
ul.lst-kix_u755olrqzope-6 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-2 > li:before {
	content: '\0025a0   ';
}
ul.lst-kix_u755olrqzope-7 {
	list-style-type: none;
}
ul.lst-kix_u755olrqzope-8 {
	list-style-type: none;
}
.lst-kix_w4raey95u019-0 > li:before {
	content: '\0025cf   ';
}
ul.lst-kix_johatq9hcprj-8 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-7 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-6 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-5 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-4 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-3 {
	list-style-type: none;
}
ul.lst-kix_johatq9hcprj-2 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-1 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-0 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-5 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-4 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-3 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-2 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-8 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-7 {
	list-style-type: none;
}
ol.lst-kix_vwawyprdpav-6 {
	list-style-type: none;
}
.lst-kix_78hcq49ygbyk-8 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-8;
}
.lst-kix_78hcq49ygbyk-7 > li {
	counter-increment: lst-ctn-kix_78hcq49ygbyk-7;
}
.lst-kix_johatq9hcprj-0 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-2 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-4 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-6 > li:before {
	content: '-  ';
}
.lst-kix_johatq9hcprj-8 > li:before {
	content: '-  ';
}
.lst-kix_f8umtzpnrzg4-8 > li:before {
	content: '\0025a0   ';
}
.lst-kix_dkg1v55wbi2c-6 > li:before {
	content: '-  ';
}
.lst-kix_vwawyprdpav-3 > li {
	counter-increment: lst-ctn-kix_vwawyprdpav-3;
}
.lst-kix_dkg1v55wbi2c-8 > li:before {
	content: '-  ';
}
ol {
	margin: 0;
	padding: 0;
}
table td,
table th {
	padding: 0;
}
.c3 {
	background-color: #ffffff;
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c0 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 11pt;
	font-family: 'Roboto';
	font-style: normal;
}
.c12 {
	margin-left: 36pt;
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c8 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
	height: 11pt;
}
.c1 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 10pt;
	font-family: 'Roboto';
	font-style: normal;
}
.c10 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 14pt;
	font-family: 'Quantico';
	font-style: italic;
}
.c14 {
	color: #000000;
	font-weight: 700;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 10pt;
	font-family: 'Roboto';
	font-style: normal;
}
.c21 {
	padding-top: 11pt;
	padding-bottom: 11pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c24 {
	padding-top: 0pt;
	padding-bottom: 10pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c18 {
	padding-top: 11pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.c20 {
	padding-top: 11pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c6 {
	padding-top: 0pt;
	padding-bottom: 11pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c9 {
	padding-top: 0pt;
	padding-bottom: 10pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: justify;
}
.c2 {
	background-color: #ffffff;
	font-size: 10pt;
	font-family: 'Roboto';
	font-weight: 400;
}
.c15 {
	font-size: 10pt;
	font-family: 'Roboto';
	font-weight: 400;
}
.c17 {
	-webkit-text-decoration-skip: none;
	text-decoration: underline;
	-webkit-text-decoration-skip-ink: none;
	        text-decoration-skip-ink: none;
}
.c22 {
	color: inherit;
	text-decoration: inherit;
}
.c11 {
	padding: 0;
	margin: 0;
}
.c23 {
	font-weight: 700;
	font-family: 'Roboto';
}
.c7 {
	margin-left: 36pt;
	padding-left: 0pt;
}
.c25 {
	max-width: 468pt;
	padding: 64pt 72pt 72pt 72pt;
}
.c5 {
	margin-left: 72pt;
	padding-left: 0pt;
}
.c16 {
	padding-left: 0pt;
}
.c13 {
	margin-left: 36pt;
}
.c4 {
	background-color: #ffffff;
}
.c19 {
	margin-left: 72pt;
}
.title {
	padding-top: 0pt;
	color: #000000;
	font-size: 26pt;
	padding-bottom: 3pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
.subtitle {
	padding-top: 0pt;
	color: #666666;
	font-size: 15pt;
	padding-bottom: 16pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
li {
	color: #000000;
	font-size: 11pt;
	font-family: 'Arial';
}
p {
	margin: 0;
	color: #000000;
	font-size: 11pt;
	font-family: 'Arial';
}
h1 {
	padding-top: 20pt;
	color: #000000;
	font-size: 20pt;
	padding-bottom: 6pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h2 {
	padding-top: 18pt;
	color: #000000;
	font-size: 16pt;
	padding-bottom: 6pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h3 {
	padding-top: 16pt;
	color: #434343;
	font-size: 14pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h4 {
	padding-top: 14pt;
	color: #666666;
	font-size: 12pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h5 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left;
}
h6 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: 'Arial';
	line-height: 1.15;
	page-break-after: avoid;
	font-style: italic;
	orphans: 2;
	widows: 2;
	text-align: left;
}

.CustomFormComponents_container__1veEK {
	margin: 0 0.5em 0.5em;
	position: relative;
	z-index: 2;
}

.CustomFormComponents_containerStandardInput__xrCsN {
	position: static;
	max-width: 300px;
}

.CustomFormComponents_containerNoFloat__FAr_c {
	margin: 0.5em;
}

.CustomFormComponents_containerMarginLess__2vGLL {
	margin: 0;
}

.CustomFormComponents_containerStandardInput__xrCsN {
	width: 100%;
	margin: 0;
}

.CustomFormComponents_controller__1frCX {
	border-bottom: 1px solid var(--ion-color-gray-tint);
}

.CustomFormComponents_controller__1frCX:hover {
	border-bottom: 1px solid var(--ion-color-gray-shade);
}

.CustomFormComponents_containerError__1YNOL .CustomFormComponents_controller__1frCX {
	border-bottom: 1px solid var(--form-field-error-color);
}

.CustomFormComponents_containerStandardInput__xrCsN .CustomFormComponents_controller__1frCX {
	border: 1px solid var(--form-field-hover-color);
	border-radius: 4px;
	padding-left: 0.5em;
}

.CustomFormComponents_label__3LpUQ {
	position: relative;
	top: 15px;
	transition: top 0.2s;
	color: var(--ion-text-color);
	font-size: 15px;
	font-weight: normal;
}

.CustomFormComponents_containerDisabled__3tDaq .CustomFormComponents_label__3LpUQ {
	opacity: 0.3;
}

.CustomFormComponents_containerSelected__2sBGV .CustomFormComponents_label__3LpUQ {
	top: 0;
	font-size: 11px;
}

.CustomFormComponents_containerNoFloat__FAr_c .CustomFormComponents_label__3LpUQ {
	top: 0;
}

.CustomFormComponents_requiredIndicator__3kcOl {
	padding: 0 2px;
	color: var(--ion-color-primary);
}

.CustomFormComponents_containerDisabled__3tDaq .CustomFormComponents_requiredIndicator__3kcOl {
	color: var(--ion-text-color);
	opacity: 0.3;
}

.CustomFormComponents_errorMessage__3lqJx {
	margin: 4px 0 0;
	font-size: 13px;
	color: var(--form-field-error-color);
}

.PhoneInput {
	height: 40px;
}

.PhoneInputInput {
	border: none;
}

.PhoneInputInput:focus {
	outline: none;
}

.ErrorModal_addModal__1YP6z {
    --width: 613px;
    --height: 479px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    min-height: 233px;
    --border-radius: 10px;
}
.ErrorModal_modalContent__3xODa {
    display: flex;
    flex-direction: column;    
    align-items: center;
    height: 100%;
}
.ErrorModal_modalHeader__1MJ7D {
    display:block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0;
}
.ErrorModal_headerContainer__2R83R {
    text-align: center;
}
.ErrorModal_headerTitle__330UX {
    font-family: 'Industry'!important;
    font-size: 60px!important;
    margin-bottom: 1.875rem;
}
.ErrorModal_msg__Z2F3T {
    font-family: 'Industry'!important;
    font-size: 28px!important;
    margin-bottom: 1.875rem;
    color: var(--ion-text-color);
}
.ErrorModal_hint__I0vi8 {
    font-family: 'Roboto'!important;
    font-size: 15px!important;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
}
.ErrorModal_msgContainer__RfKLE {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ion-text-color);
}
.ErrorModal_msgContainer__RfKLE .ErrorModal_msg__Z2F3T {
    font-size: 1.2rem!important;
}
.ErrorModal_btnContainer__2QwZV {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 130px;
}
.ErrorModal_btnContainer__2QwZV ion-button {
    --color: var(--ion-text-color);
    --border-width: 1px;
    --background-hover-opacity: 1;
    --transition: 0.5s;
    width: 185px;
    height: 50px;
    font-size: 15px;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
}
.ErrorModal_btnContainer__2QwZV ion-button:first-child {
    margin-left: 0.5rem;
}
.ForgotPassword_forgotSpan__252aD {
	text-transform: none;
}
.ForgotPassword_hintContainer__3QzPJ {
	margin-top: 0.875rem;
	margin-bottom: 2rem;
}
.ForgotPassword_hintContainer__3QzPJ ion-label {
	white-space: pre-line !important;
}
.ForgotPassword_btnContainer__rCx8o {
	display: flex;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: auto;
}
.ForgotPassword_btnContainer__rCx8o ion-button {
	max-width: 300px;
	font-size: 18px;
	--padding: 0 0.5rem;
	--transition: 0.5s;
}
.ForgotPassword_backBtn__pG9Ob {
	width: 168px;
	margin-right: 0.25rem !important;
}
.ForgotPassword_btnContainer__rCx8o ion-button:last-child {
	width: 212px;
	margin-left: 0.25rem !important;
}
.ForgotPassword_success__34L84 {
	color: #56ae4d !important;
	padding: 10px;
	width: -webkit-min-content;
	width: min-content;
}
.ForgotPassword_error__GEHx3 {
	color: #9e0e0e !important;
	padding: 10px;
	width: -webkit-min-content;
	width: min-content;
}

@media (max-width: 576px) {
    .MainContent_blueoceanLogo__2z1ZS {
        max-width: 55px!important;
        bottom: 20px!important;
    }
} 
@media (min-width: 576px) and (max-width: 768px) {
    .MainContent_blueoceanLogo__2z1ZS {
        max-width: 55px!important;
        bottom: 20px!important;
    }
}
.MainContent_mainContent__3f3jt {
    --background: var(--ion-main-background-color);
    --padding-start: 3.125rem;
    --padding-bottom: 0;
    --padding-end: 3.125rem;
    --padding-top: 2rem;
    --offset-top: 70px !important;
}
.MainContent_pageContainer___L3RK {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.MainContent_pageHeader__2-0JH {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    max-height: 40px;
    padding-bottom: 1rem;
}
.MainContent_pageTitle__1qI69 {
    color: var(--ion-text-color);
    font-size: 28px!important;
    font-family: 'Industry', auto;
}
.MainContent_pageContent__2cnDv {
    flex-grow: 1;
    margin-top: 1em;
}
.MainContent_dividerContainer__2Iw-L {
    width: calc(calc(100% + 70px) - 30%);
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.MainContent_dividerContainer__2Iw-L div:nth-of-type(1) {
    flex-grow: 1;
}
.MainContent_dividerContainer__2Iw-L div:nth-of-type(3) {
    flex-grow: 1;
}
.MainContent_divider__1Y2OD {
    width: 100%;
    height: 2px;
    background-color: var(--ion-background-color-divider);
}
.MainContent_logoContainer__1HCBY {
    width: 109px;
    height: 67px;
}
.MainContent_blueoceanLogo__2z1ZS {
    width: calc(100% - 30px);
    height: 38px;
    margin: 9px 15px 20px;
}
.MainContent_padding__1jAz5 {
    padding: 25px 0;
}
.MainContent_hidden__1UpZB {
    display: none;
}
@media (max-width: 576px) {
    .Header_searchBar__2X5HD {
        height: auto!important;
    }
} 
@media (min-width: 576px) and (max-width: 768px) {
    .Header_searchBar__2X5HD {
        height: auto!important;
        width: 1000px;
    }
}
.Header_headerBtn__31ngq {
    --background-hover: var(--ion-color-primary-contrast)!important;
    --background-hover-opacity: 0.1;
    --background-activated: var(--ion-color-primary-contrast)!important;
    --background-activated-opacity: 1;
    --background-focused: var(--ion-color-primary-contrast)!important;
    --background-focused-opacity: 1;
}
.Header_headerIcon__3RWeY {
    color: var(--ion-header-icon-color);
    /* --color-activated: black; */
}
.Header_searchBar__2X5HD {
    --placeholder-color: var(--ion-color-gray-tint);
    font-size: 13px;
    text-align: left;
    /* height: var(--ion-height-toolbar); */
    width: 100%;
    margin: 10px 8px 8px!important;
}
.Header_searchBar__2X5HD ion-icon {
    color: var(--ion-text-color)!important;
}
.Header_notifHeaderBtn__1xxLS {
    margin-right: 0.5em!important;
}
.Header_notifHeaderBtn__1xxLS .Header_badge__3AZ1x {
    position: absolute;
    top: -6px;
    right: -4px;
    padding: 5px 5px;
    border-radius: 50%;
    background-color: var(--ion-color-primary)!important;
    color: white;
    border: 2px solid white;
}
.Header_verticalDivider__2njnl {
    border-left: 1px solid #ECEBF0;
    width: 1px;
    height: 25px;
    padding-right: 0.5em;
}
.Header_userHeaderBtn__DL2sR ion-icon {
    color:#A5AEBD;
    padding-left: 0.5em;
}
.Header_logo__3_SJs {
    height: 30px;
    width: 76px;
    background-color: white;
    background: var(--robot-logo);
    background-repeat: no-repeat;
    background-position: left;
    margin-right: 16px;
}
.Header_userNameList__3st3e {
    color: var(--ion-text-color);
    /* background-color: var(--ion-color-primary-contrast); */
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}
.Header_userNameList__3st3e td:first {
    padding-top: 1em;
}
.Header_userNameList__3st3e td {
    padding: 0 2em;
    text-align: center;
}
.Header_listItem__my4Ch {
    border-bottom: 1px solid #efefef;
    padding-bottom: 1em;
    padding-top: 1em;
}
.Header_statusLeft__18UTh {
    padding-right: 0!important;
    font-weight: bold;
}
.Header_statusRight__wbNOI {
    padding-left: 0!important;
}
.Header_userNameList__3st3e tr:last-child div.Header_listItem__my4Ch {
    border-bottom: none;
}
.Header_userNameList__3st3e tr:first-child :hover {
    cursor: default;
}
.Header_userNameList__3st3e tr:hover {
    cursor: pointer;
}
.Header_userNameList__3st3e tr:first-child :hover .Header_listItem__my4Ch {
    color: var(--ion-text-color);
}
.Header_userNameList__3st3e tr:hover .Header_listItem__my4Ch {
    color: var(--ion-color-menu-item-hover);
}
.Header_searchResultsList__3rykW {
    width: 100%;
    max-height: 18.75rem;
    position: absolute;
    top: calc(var(--ion-height-toolbar) - 3px);
    z-index: 1;
    overflow-y: auto;
}
.Header_searchResultsList__3rykW tr:last-child {
    border-bottom: none;
}
.Header_hidden__NDfgl {
    display: none;
}
.StayLoginModal_addModal__1srBw {
	max-width: 660px;
}
.StayLoginModal_modalHeader__3BML3 {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.StayLoginModal_headerContainer__3n-lO {
	display: flex;
	margin: 0 auto;
}
.StayLoginModal_headerIcon__3GnDl {
	font-size: 55px;
}

.StayLoginModal_headerTitle__2ChMB {
	font-family: 'Industry';
	font-size: 28px !important;
}
.StayLoginModal_headerHint__Pe9Qa {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.StayLoginModal_modalContent__3nuBI {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.StayLoginModal_sessionContent__39KY4 {
	display: grid;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.StayLoginModal_btnRow__2_akh {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.StayLoginModal_btnRow__2_akh ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
	margin-bottom: 2rem;
}
.StayLoginModal_btnRow__2_akh ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.StayLoginModal_submitCol__2s0rV ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.StayLoginModal_cancelBtn__33WzO {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}

.Tabs_tabContainer__3Ni2k {
	--background: var(--ion-color-primary-contrast);
	padding: 0.5rem;
	justify-content: flex-start;
}
.Tabs_tabBtn__3triR {
	padding: 0.875rem;
	max-width: 13.5rem;
	/* font-size: 15px; */
	--color-checked: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-text-color);
	--indicator-color: var(--ion-color-primary);
}
.Tabs_tabIcon__ZbPTN {
	margin-right: 0.5rem;
}

.Tabs_tabIconOrganizations__EvJrD {
	margin-right: 0.5rem;
	color: var(--ion-color-primary);
}
.Tabs_tabTitle__FVu2t {
	text-transform: uppercase;
}

.Tabs_tabTitleOrganizations__2Lg3B {
	color: grey;
	text-transform: uppercase;
}

.Tabs_passedItem__3Wp9G {
	text-transform: uppercase;
	color: var(--ion-color-primary);
}

.Tabs_selectedItem__omRiB {
	text-transform: uppercase;
	color: black;
}

.Tabs_tabContent__2eMsN {
	display: flex;
	flex-grow: 1;
	margin-top: 1.113rem;
	margin-bottom: 1.5rem;
}

.AddOrganizationModal_addModal__2UTZs {
	--height: 90%;
	--max-height: 900px;
	--border-radius: 10px;
}
.AddOrganizationModal_addForm__QXai7 {
	height: 100%;
	width: 100%;
}
.AddOrganizationModal_modalContent__qaRI3 {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	height: 50%;
}

.AddOrganizationModal_modalHeader__15PDs {
	display: block;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 12px;
	padding: 0;
}
.AddOrganizationModal_modalContentContainer__34T-y {
	padding: 0 2rem;
    height: calc(100% - 95px);
    overflow-y: auto;
}
.AddOrganizationModal_modalContentContainerAllocate__3utzn {
	height: 40vh;
	max-height: 400px;
    overflow-y: auto;
}
.AddOrganizationModal_headerContainer__2rcq2 {
	display: flex;
	margin: 0 auto;
}
.AddOrganizationModal_headerIcon__DG1VT {
	margin-right: 0.5rem;
}
.AddOrganizationModal_headerTitle__2e9KT {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.AddOrganizationModal_tabContainer__2FBPa {
	--background: var(--ion-color-primary-contrast);
	/* padding: 0 0.5rem; */
	margin: 0 3rem;
	border-radius: 100px;
	border: 2px solid #dddddd;
}
.AddOrganizationModal_tabContainer__2FBPa ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.AddOrganizationModal_tabContainer__2FBPa ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.AddOrganizationModal_formList__3Ugmb {
	margin: 0 3rem;
	height: 100%;
}
.AddOrganizationModal_rowContainer__22Or3 {
	width: 100%;
	display: block;
	padding: 0;
}
.AddOrganizationModal_btnContainer__1yUPJ {
	height: 95px;
}
.AddOrganizationModal_formContainer__1YmWw {
	width: 100%;
	height: 100%;
}
.AddOrganizationModal_zipCityContainer__2gfFU {
	display: flex;
	justify-content: space-between;
}

.AddOrganizationModal_zipCityContainer__2gfFU > *  {
	width: 45%;
}

.AddOrganizationModal_countryContainer__kXL1A {
	display: inline!important;
	height: auto;
	width: auto;
}
.AddOrganizationModal_btnRow__9oyco {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
	padding: 0 2.3rem;
}
.AddOrganizationModal_btnRowAllocate__3sh8L {
    display: flex;
	align-items: flex-end;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
}
.AddOrganizationModal_btnRow__9oyco ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.AddOrganizationModal_btnRow__9oyco ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.AddOrganizationModal_submitBtn__1RKx4 {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
	margin-left: 0.5rem;
}
.AddOrganizationModal_submitCol__3Qo2j ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.AddOrganizationModal_cancelBtn__e4aev {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.AddOrganizationModal_searchBar___lI0v {
	--placeholder-color: var(--ion-text-color);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
}
.AddOrganizationModal_searchBar___lI0v ion-icon {
	color: var(--ion-text-color) !important;
}
.AddOrganizationModal_searchBar___lI0v input {
	padding-left: 0 !important;
	border-bottom: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
}
.AddOrganizationModal_robotsGrid__112l6 {
	width: 100%;
	margin: 3rem 3rem 2.5rem;
}
.AddOrganizationModal_robotsGrid__112l6 ion-col:first-child {
	margin-right: 1rem;
}
.AddOrganizationModal_robotsGrid__112l6 ion-col:last-child {
	margin-left: 1rem;
}
.AddOrganizationModal_colTitle__kB6iG {
	text-transform: capitalize;
	font-family: 'Industry';
	font-size: 18px !important;
	margin-bottom: 0.9rem;
}
.AddOrganizationModal_noSelection__dKHmr {
	margin-top: 0.9rem;
}
.AddOrganizationModal_noSelection__dKHmr ion-label {
	color: var(--ion-color-gray);
}
.AddOrganizationModal_robotItem__3TcEf {
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	background-color: var(--ion-color-primary-contrast);
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	--inner-padding-end: 0;
}
.AddOrganizationModal_robotPicContainer__27uqO {
	width: 62px;
	height: 38px;
	background-color: var(--ion-text-color);
	margin-left: 0.375rem;
}
.AddOrganizationModal_robotPic__2FSA4 {
	height: 100%;
	width: 42px;
	font-size: 1.8em;
	background-color: var(--ion-text-color);
	background: var(--robot-logo-white);
	background-repeat: no-repeat;
	background-position: left;
	margin-left: 0.3em;
}
.AddOrganizationModal_robotName__2sqak {
	margin-left: 0.5rem;
	font-weight: 500;
	flex-grow: 1;
}
.AddOrganizationModal_selectRobotBtn__zgShC {
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	/* margin: 0 0.5rem 0 0; */
	width: 23px;
	height: 23px;
}
.AddOrganizationModal_selectRobotBtn__zgShC ion-icon.AddOrganizationModal_fabIcon__2HdzB {
	width: 1rem;
	height: 1rem;
}
.AddOrganizationModal_hidden__2LmLR {
	display: none;
}
.AddOrganizationModal_allocateContainer__25zdV {
	height: 100%;
}
.AddOrganizationModal_required__qS3b9{
    color: var(--ion-color-primary);
}
.DeleteModal_addModal__2wlVj {
    --width: 613px;
    --height: 640px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    min-height: 233px;
    --border-radius: 10px;
}
.DeleteModal_modalContent__2asm6 {
    background: var(--ion-text-color);
    display: flex;
    flex-direction: column;    
    align-items: center;
    height: 100%;
    opacity: 0.9;
}
.DeleteModal_modalHeader__34szg {
    display:block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0;
}
.DeleteModal_headerContainer__1d1wM {
    text-align: center;
}
.DeleteModal_headerTitle__SyaSO {
    font-family: 'Industry'!important;
    font-size: 35px!important;
    margin-bottom: 1.875rem;
}
.DeleteModal_msgContainer__1H6Mv {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ion-color-primary-contrast);
    --background: var(--ion-text-color);
}
.DeleteModal_msgContainer__1H6Mv .DeleteModal_msg__2I0Ra {
    font-size: 1.2rem!important;
}
.DeleteModal_btnContainer___2shu {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    --background: var(--ion-text-color);
    min-height: 130px;
}
.DeleteModal_btnContainer___2shu ion-button {
    --color: var(--ion-color-primary-contrast);
    --border-width: 1px;
    --background-hover-opacity: 1;
    --transition: 0.5s;
    width: 185px;
    height: 50px;
    font-size: 15px;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
}
.DeleteModal_btnContainer___2shu ion-button:first-child {
    margin-left: 0.5rem;
}
.DeleteModal_cancelBtn__2HR7j {
    --color-hover: var(--ion-text-color)!important;
    --border-color: var(--ion-color-primary-contrast);
    --background: var(--ion-text-color);
    --background-hover: var(--ion-color-primary-contrast)!important;
    margin-right: 0.5rem;
}
.DeleteModal_deleteBtn__3wpYp {
    --color-hover: var(--ion-color-danger);
    --background: var(--ion-color-danger);
    --background-hover: var(--ion-text-color);
    --border-color: var(--ion-color-danger);
}
.InfoCard_infoContainer__1mrhl {
	display: flex;
	width: 100%;
	position: relative;
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}
.InfoCard_dataCard__16s6a {
	background-color: var(--ion-color-primary-contrast);
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	width: 100%;
	margin: 0;
}
.InfoCard_dataCard__16s6a ion-card-title {
	font-family: 'Industry';
	font-size: 18px;
	font-weight: bold;
	line-height: 0.72;
	padding-top: 0.1em;
}
.InfoCard_dataCard__16s6a ion-card-content {
	padding: 0;
}
.InfoCard_dataCardContent__1S8ac {
	--border-color: #f3f3f3;
	--inner-padding-end: 0;
	--padding-start: 0.5rem;
	--padding-end: 0.5rem;
}
.InfoCard_closeIcon__25AmS {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0.5rem 0 0;
	color: var(--ion-color-gray);
}
.InfoCard_closeIcon__25AmS:hover {
	cursor: pointer;
}
.InfoCard_profileContainer__3sM8P {
	display: flex;
	align-items: center;
}
.InfoCard_userAvatar__3KuJf {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.063rem;
	height: 100%;
	margin: 0 0.5rem 2rem 0;
	padding-top: 1rem;
}
.InfoCard_profilePic__1RPDF {
	width: 5.063rem;
	height: 5.063rem;
}
.InfoCard_editPicFab__2TqSb {
	position: absolute;
	bottom: 0.5rem;
}
.InfoCard_editPicBtn__1JS0Z {
	--background: rgba(255, 255, 255, 0.75);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
	border-radius: 50%;
}
.InfoCard_editPicIcon__22wdm {
	--color: var(--ion-text-color);
}
.InfoCard_noteContainer__3L1kx {
	display: flex;
	align-items: center;
}
.InfoCard_deleteFab__2b-Od,
.InfoCard_editFab__1E6er {
	position: absolute;
	right: 0;
}
.InfoCard_deleteFab__2b-Od ion-fab-button {
	margin: 0;
	bottom: 0;
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-color-danger-hover);
	--background-hover-opacity: 1;
	--color: var(--ion-color-primary-contrast);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.InfoCard_editFab__1E6er ion-fab-button {
	margin: 0;
	bottom: 0;
	--background: var(--ion-main-background-color);
	--color: var(--ion-color-gray-shade);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.InfoCard_deleteOverlay__1k61J {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	--background: var(--ion-text-color);
	background-color: var(--ion-text-color);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	z-index: 1;
	opacity: 0.9;
}
.InfoCard_deleteOverlay__1k61J ion-row:first-child {
	flex-grow: 1;
}
.InfoCard_confirmLb__3g3aZ {
	width: 100%;
	color: var(--ion-color-primary-contrast);
	text-align: center;
	align-self: center;
	font-family: 'Industry';
	font-weight: 500;
	font-size: 18px !important;
}
.InfoCard_closeFabBtn__Lze6X {
	--color: var(--ion-color-primary-contrast);
	--background: var(--ion-color-gray-shade);
	padding: 0.25rem;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.InfoCard_actionContainer__2LlHm {
	display: flex;
	margin: 0 2.25rem 1rem;
}
.InfoCard_cancelBtn__1huDs {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-text-color);
	--background: transparent;
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--border-color: var(--ion-color-primary-contrast);
	--border-width: 0.08rem;
	--border-style: solid;
	flex: 1 1;
	margin: 0 0.5rem 0 0;
	font-size: 13px;
}
.InfoCard_deleteBtn__2cG-3 {
	--background: var(--ion-color-danger);
	flex: 1 1;
	margin: 0 0 0 0.5rem;
	font-size: 13px;
}
.InfoCard_textWrap__2ty-r {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
	line-height: normal;
}
.InfoCard_disinfectionRedirectIcon__3ByST {
	position: relative;
	top: 2px;
}
.InfoCard_disinfectionsLink__1nU3y {
	padding-left: 4px;
}
.InfoCard_lastUsedIcon__2Vx83 {
	position: relative;
	top: 4px;
	padding-right: 4px;
}
.InfoCard_alignRight__3E6CL {
	text-align: right;
}

.UserForm_formGrid__31Wx6 {
	width: 100%;
	padding: 0;
}
.UserForm_formGrid__31Wx6 ion-list {
	width: 100%;
	margin-bottom: 1.2rem;
	padding-top: 0px;
}
.UserForm_formGrid__31Wx6 ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}
.UserForm_formGrid__31Wx6 ion-list-header {
	margin-top: 1.063rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}
.UserForm_formGrid__31Wx6 ion-input.UserForm_editable__1CIky {
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 300px;
}
.UserForm_formGrid__31Wx6 ion-input.UserForm_firstName__1I8rt {
	margin-right: 0.5rem;
}
.UserForm_formGrid__31Wx6 ion-label.UserForm_userName__3ygCQ {
	margin-left: 0.5rem;
}
.UserForm_formLb__vs_98 {
	font-weight: bold;
	margin-right: 4rem;
	min-width: 170px !important;
}
.UserForm_formLbItem__1CoFc {
	margin-right: 8px;
	background-color: #eeeeee;
	border-radius: 5px;
	height: 30px;
	padding: 7px 8px;
	flex: unset !important;
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}
.UserForm_inputLb__2FdD7 {
	margin-left: 0.5rem;
}
.UserForm_subTitleLb__2o1Uv {
	font-family: 'Industry';
	text-transform: capitalize;
	--color: var(--ion-color-gray) !important;
}
.UserForm_chkLb__2RlTP {
	margin-left: 0.5rem;
}
.UserForm_ddlLb__2KLhJ {
	flex: 1 1 !important;
}
.UserForm_selectLb__PA52c {
	margin-left: 0 !important;
	font-weight: bold;
	margin-right: 4rem;
	min-width: 170px !important;
}
.UserForm_groupSelect__3nYt5 {
	max-width: 300px;
	flex: 1 1;
}
.UserForm_hidden__UpurV {
	display: none !important;
}
.UserForm_labelFont__3iNPI {
	font-family: 'Industry-Demi';
}

.UserEdit_leftSide__2Kvuv {
    display: flex;
    flex-direction: column;
    min-width: 22.5rem;
    margin-right: 1.5rem;
}
.UserEdit_rightSide__3Xbit {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 1.063rem 2.5rem;
}
.UserEdit_rightSide__3Xbit:after {
    right: 100%;
    top: 12%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    /* border-color: rgba(136, 183, 213, 0); */
    border-right-color: var(--ion-color-primary-contrast);
    border-width: 14px;
    margin-top: -14px;
}
.UserEdit_adminNote__eRDzm {
    font-size: 0.813rem;
    margin-right: 0.25rem;
}
.UserEdit_recentContainer__1Nghu {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    padding: 1.063rem 0.5rem;
}
.UserEdit_recentLb__3zMbt {
    font-family: 'Industry';
    font-weight: bold;
    margin-left: 0.5rem;
}
.UserEdit_recentContainer__1Nghu ion-list {
    width: 100%;
}
.UserEdit_recentContainer__1Nghu .UserEdit_profilePic__Jghyl {
    width: 2.5rem;
    height: 2.5rem;    
}
.UserEdit_recentContainer__1Nghu ion-avatar {
    margin-right: 0.5rem;
}
.UserEdit_recentContainer__1Nghu .UserEdit_icon__JXCpW {
    color: var(--ion-text-color);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.UserEdit_recentContainer__1Nghu .UserEdit_info__1iiFP, .UserEdit_recentContainer__1Nghu .UserEdit_timeIcon__3PUbo {
    color: var(--ion-color-gray-shade);
}
.UserEdit_header__2bG9J {
    display: flex;
}
.UserEdit_editBtnContainer__2FrEc {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
.UserEdit_editBtn__KHH7l {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    --color: var(--ion-color-primary-contrast);
    margin-right: 1rem;
}
.UserEdit_editable__1_FZL {
    --background: var(--ion-color-primary);
    opacity: 1;
}
.UserEdit_btnContainer__3BYED {
    display: flex;
    justify-content: flex-end;
}
.UserEdit_detailsBtn__1seKt {
    --background: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary);

}
.UserEdit_formBtns__3nrOD {
    display: flex;
}
.UserEdit_formBtns__3nrOD ion-icon {
    color: var(--ion-color-gray);
}
.UserEdit_formBtns__3nrOD ion-icon:hover {
    cursor: pointer;
}
.UserEdit_hidden__2EUIw {
    display: none!important;
}
.UserEdit_savedLb__1IoeN {
    color: var(--ion-color-primary);
    font-size: 13px;
    margin-left: 0.5rem;
}
.UserEdit_moreNames__3vqUb {
    display: flex!important;
}
.UserEdit_andLb__22cBX {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
.UserEdit_moreInLb__2Wf59:hover {
    text-decoration: underline;
    cursor: pointer;
}
.UserEdit_groupNameLb__2vojX {
    display: flex;
}
.UserEdit_groupNameItem__2NyyL {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: block;
}
.UserEdit_groupNameItem__2NyyL li {
    margin-top: 0.25rem;
}
.ListGrid_listGrid__3Um_J {
	padding: 0;
	margin: 0;
	height: 100%;
	/* width: 100%; */
	flex-grow: 1;
}
.ListGrid_headerRow__rF-fT {
	display: flex;
	align-items: center;
	height: 2.813rem;
	color: var(--ion-color-primary-contrast);
	background-color: var(--ion-text-color);
	border-radius: 5px;
	font-family: 'Industry';
	font-weight: bold;
	padding-left: 1rem;
}
.ListGrid_centeredCol__2hzBj {
	text-align: center;
	display: flex;
	justify-content: center;
}
.ListGrid_rightAlignedCol__Vm330 {
	text-align: right;
	display: flex;
	justify-content: flex-end;
}
.ListGrid_dataRow__2znML {
	background-color: var(--ion-color-primary-contrast);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	margin-top: 0.5rem;
	align-items: center;
	padding-left: 0.5rem;
	height: 50px;
}
.ListGrid_dataRow__2znML:hover {
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	cursor: pointer;
}
.ListGrid_createdRow__3Noaz {
	background-color: #edf8ec;
}
.ListGrid_noData__6yAB7 {
	margin-top: 1rem;
	padding-left: 0.5rem;
}
.ListGrid_noData__6yAB7:hover {
	cursor: default;
}
.ListGrid_moreCol__1W9XD {
	max-width: 3.75rem;
}
.ListGrid_moreIcon__1PwwO {
	color: var(--ion-color-gray);
	border-radius: 50%;
	padding: 0.375rem;
}
.ListGrid_moreIcon__1PwwO:hover {
	background-color: var(--ion-main-background-color);
	cursor: pointer;
}
.ListGrid_paginationContainer__1pwVq {
	display: flex;
	align-items: center;
}
.ListGrid_pagingInfo__LKxdZ {
	flex-grow: 1;
	font-weight: bold;
}
.ListGrid_pagination__2emIQ {
	text-align: right;
	padding-top: 15px;
	padding-left: 15px;
	padding-right: 15px;
}
.ListGrid_pagination__2emIQ li {
	display: inline-block;
}
.ListGrid_pagination__2emIQ li > a {
	font-size: 13px;
	color: var(--ion-text-color);
	border-radius: 50%;
	padding: 9px 13px;
	margin: 0 3px;
	cursor: pointer;
}
.ListGrid_pagination__2emIQ li:first-child a,
.ListGrid_pagination__2emIQ li:last-child a {
	padding: 9px;
}
.ListGrid_pagination__2emIQ li a:hover {
	background-color: var(--ion-lb-color-light);
}
.ListGrid_pagination__2emIQ .ListGrid_icon__2YbL1 {
	top: 3px;
	position: relative;
}
.ListGrid_activePage__3mweV {
	background-color: var(--ion-color-gray-shade) !important;
	color: var(--ion-color-primary-contrast) !important;
	border-color: var(--ion-text-color) !important;
}
.ListGrid_disabledPage__nm1lc a {
	color: #ccc !important;
	cursor: default !important;
}
.ListGrid_disabledPage__nm1lc a:hover {
	background-color: transparent !important;
}
.ListGrid_info__3UzZh {
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
}
.ListGrid_hide__3RvHr {
	display: none;
}
.ListGrid_popup__wIIt7 {
	--box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.015);
	border-radius: 10px;
}
.ListGrid_elementContainer__3FFCF {
	height: inherit;
	display: flex;
	align-items: center;
}
.ListGrid_closeIcon__2E1mL {
	margin-left: 10px;
	width: 25px;
	height: 25px;
}
.ListGrid_columnHeader__yDm0x {
	display: flex;
	align-items: center;
}
.ListGrid_sortContainer__1vJlB {
	display: flex;
	flex-direction: column;
}
.ListGrid_sortIcon__1qjWc {
	color: #78787a;
	height: 0.7rem;
	margin-left: 0.2rem;
}
.ListGrid_sortIcon__1qjWc:hover,
.ListGrid_activeCol__qb1MI:hover {
	cursor: pointer;
}
.ListGrid_activeSort__3kIhe {
	color: var(--ion-color-primary-contrast);
}

/* Bootstrap Media Query Breakpoints

/* Extra Small Devices, Phones equivalent to class xs */
@media only screen and (min-width: 480px) {
	.grid_hideXs__ysFmC {
		display: none;
	}
	.grid_hideSm__3432s {
		display: inline;
	}
	.grid_hideMd__3KnN9 {
		display: inline;
	}
	.grid_hideLg__2u7ML {
		display: inline;
	}
}

/* Small Devices, Tablets equivalent to class sm */
@media only screen and (min-width: 768px) {
	.grid_hideXs__ysFmC {
		display: inline;
	}
	.grid_hideSm__3432s {
		display: inline;
	}
	.grid_hideMd__3KnN9 {
		display: inline;
	}
	.grid_hideLg__2u7ML {
		display: inline;
	}
}

/* Medium Devices, Desktops equivalent to class md */
@media only screen and (min-width: 992px) {
	.grid_hideXs__ysFmC {
		display: inline;
	}
	.grid_hideSm__3432s {
		display: inline;
	}
	.grid_hideMd__3KnN9 {
		display: none;
	}
	.grid_hideLg__2u7ML {
		display: inline;
	}
}

/* Large Devices, Wide Screens equivalent to class lg */
@media only screen and (min-width: 1200px) {
	.grid_hideXs__ysFmC {
		display: inline;
	}
	.grid_hideSm__3432s {
		display: inline;
	}
	.grid_hideMd__3KnN9 {
		display: inline;
	}
	.grid_hideLg__2u7ML {
		display: none;
	}
}

.UsersGrid_nameContainer__3GrWJ {
	display: flex;
	align-items: center;
}
.UsersGrid_userAvatar__2gc6q {
	display: flex;
	max-width: 2.5rem;
	height: 100%;
	margin-right: 0.5rem;
}
.UsersGrid_profilePic__1LYXr {
	width: 38px;
	height: 38px;
}
.UsersGrid_noPic__2BuND {
	color: var(--ion-text-color);
}
.UsersGrid_userNameLb__14VqP {
	justify-content: flex-start;
	flex-grow: 1;
}
.UsersGrid_roleContainer__3Kk6e {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.UsersGrid_shieldIcon__1oyEe {
	width: 18px;
	height: 18px;
}
.UsersGrid_groupNames__1x44v {
	text-align: left;
}
.UsersGrid_groupNameItem__399J3 {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.UsersGrid_groupNameItem__399J3 li {
	margin-top: 0.25rem;
}
.UsersGrid_groupLink__Kty14 {
	justify-content: center;
}
.UsersGrid_groupLink__Kty14:hover {
	text-decoration: underline;
	width: 100%;
}
.UsersGrid_noData__21nOi {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.UsersGrid_noDataHint__2ptMu {
	font-weight: normal;
	margin-bottom: 1rem;
}

.SearchBar_searchBar__1u-sB {
	--placeholder-color: var(--ion-color-gray-tint);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
	padding: 0;
}
.SearchBar_searchBar__1u-sB ion-icon {
	color: var(--ion-text-color) !important;
}
.SearchBar_searchBar__1u-sB button {
	right: 0.3rem !important;
}
.SearchBar_searchBar__1u-sB input {
	padding-left: 0.5rem !important;
	border: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
	-webkit-padding-end: 35px !important;
	        padding-inline-end: 35px !important;
	max-height: 36px !important;
	border-radius: 5px !important;
}

.FilterList_filterList__3YaYP {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.FilterList_filterLb__3BXWK {
	margin-left: 0.5rem;
	font-weight: bold;
	text-transform: uppercase;
}
.FilterList_filterItem__vhdKk {
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	border-radius: 0.313rem;
}
.FilterList_selected__3aOXH {
	--background: var(--ion-main-background-color);
}

.Users_leftSide__28Np0 {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.Users_rightSide__x6ndP {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Users_btnContainer__3lDCx {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.Users_addToGroupBtnContainer__3suKx {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.Users_inviteBtn__1nC-T,
.Users_addToGroupBtn__3-87b {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.Users_inviteIcon__ehcBO {
	margin-right: 0.5rem;
}
.Users_inviteLb__3_Xo4 {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}
.Users_addIcon__12RUx {
	font-size: 2rem;
}
/* more popup */
.Users_itemContent__17Edt {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.Users_moreList__2CY2O tr:last-child .Users_itemContent__17Edt {
	padding-bottom: 0.375rem;
}
.Users_itemLb__2F2Dl {
	color: var(--ion-more-lb-color);
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
	text-transform: capitalize;
}
.Users_itemLb__2F2Dl:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}
.Users_invitedUser__1Vl06 {
	display: flex;
	margin: 1rem 0.3rem 1rem 0;
	color: var(--ion-color-gray-shade);
}
.Users_invitedUser__1Vl06 ion-icon {
	margin-right: 0.5rem;
}
.Users_invitedUser__1Vl06 span {
	text-transform: capitalize;
	margin-right: 0.3rem;
	display: contents;
}

.Users_selectUsersContainer__33YOz {
	padding: 5px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Users_disabledSelectUserBtn__3zctA {
	--color: white;
	--border-color: var(--ion-text-color);
	--background: var(--ion-color-primary);
	--border-width: 1px;
	opacity: 0.25;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.Users_selectUsersBtn__11g5M {
	--color: white;
	--border-color: var(--ion-color-primary) !important;
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.Users_headerTitle__2swkU {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.Users_modalHeader__24JNd {
	flex-direction: column;
}

.Users_selectedUsersContainer__1ickU {
	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 30px;
	max-height: 170px;
	overflow-y: scroll;
}

.Users_selectedUsersContainer__1ickU::-webkit-scrollbar {
	width: 8px;
}

.Users_selectedUsersContainer__1ickU::-webkit-scrollbar-track {
	border-radius: 10px;
}

.Users_selectedUsersContainer__1ickU::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px 6px var(--ion-color-gray-tint);
}

.Users_userContainer__1Ycei {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	height: 30px;
	margin: 2px 5px;
	border-radius: 5px;
	background-color: var(--ion-main-background-color);
}

.Users_deleteUserBtn__INIeM {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-color-gray);
	--box-shadow: none;
	height: 50px;
	width: 50px;
	margin: 0 -10px;
}

.UserGroups_leftSide__zmAHJ {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.UserGroups_rightSide__39osN {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.UserGroups_createBtnContainer__2FUNf,
.UserGroups_inviteBtnContainer__3WKlE {
	display: flex;
	align-items: center;
	padding-bottom: 1.375rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.UserGroups_inviteBtnContainer__3WKlE {
	padding-top: 1.375rem;
}
.UserGroups_createBtn__r2RDP,
.UserGroups_inviteBtn__1-h1T {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.UserGroups_inviteIcon__1Ko9M {
	margin-right: 0.5rem;
}
.UserGroups_inviteLb__IHrrh {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}

.UserGroups_nameContainer__1ITqV {
	display: flex;
	align-items: center;
}
.UserGroups_userAvatar__2XSiN {
	max-width: 2.5rem;
	height: 100%;
	margin-right: 0.5rem;
}
.UserGroups_profilePic__3vOm6 {
	width: 38px;
	height: 38px;
}
.UserGroups_noPic__2eQVs {
	color: var(--ion-text-color);
}
/* Create modal */
.UserGroups_addModal__3_B52 {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.UserGroups_addForm__13mzA {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
.UserGroups_modalContent__2gJm1 {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.UserGroups_userGroupNameContainer__2CAH5 div:first-child {
	width: 100%;
}
.UserGroups_modalContent__2gJm1 .UserGroups_usersContent__3Am6L {
	flex-grow: 1;
}
.UserGroups_usersContent__3Am6L ion-list {
	width: 100%;
}
.UserGroups_header__3o6lw {
	width: 100%;
}
.UserGroups_modalHeader__XAYm9 {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.UserGroups_headerContainer__2ANL9 {
	display: flex;
	margin: 0 auto;
}
.UserGroups_headerIcon__UZGei {
	margin-right: 0.5rem;
}
.UserGroups_headerTitle__GEKlV {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.UserGroups_headerHint__3knQ0 {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.UserGroups_groupName__12fiQ {
	font-weight: 600;
}
.UserGroups_usersRow__1G0CN {
	margin-top: 1.2rem;
	margin-left: 0.5rem;
}
.UserGroups_usersRow__1G0CN ion-col {
	flex: 1 1;
	overflow-y: auto;
}
.UserGroups_usersRow__1G0CN ion-col:first-child {
	padding-right: 2.4rem;
}
.UserGroups_usersList__3BOL8,
.UserGroups_noSelection__3OF7I {
	overflow-y: auto;
	max-height: 300px;
	padding: 5px !important;
}
.UserGroups_noSelection__3OF7I {
	margin-top: 0.9rem;
}
.UserGroups_noSelection__3OF7I ion-label {
	color: var(--ion-color-gray);
}
.UserGroups_noUsers__3cJp9 {
	display: flex;
	margin-top: 1.3rem;
	color: var(--ion-color-gray);
}
.UserGroups_usersSelected__QlFNJ {
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 1.4rem;
	color: var(--ion-color-gray);
}
.UserGroups_btnRow__2j73B {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.UserGroups_btnRow__2j73B ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.UserGroups_btnRow__2j73B ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.UserGroups_submitCol__20O0h ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.UserGroups_cancelBtn__2kaqg {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.UserGroups_colTitle__2hzwQ {
	text-transform: capitalize;
	font-family: 'Industry';
	font-size: 18px !important;
	margin-bottom: 0.9rem;
}
.UserGroups_searchBar__1giOa {
	--placeholder-color: var(--ion-text-color);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
}
.UserGroups_searchBar__1giOa ion-icon {
	color: var(--ion-text-color) !important;
}
.UserGroups_searchBar__1giOa input {
	padding-left: 0 !important;
	border-bottom: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
}
.UserGroups_userItem__1SXQ3 {
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	background-color: var(--ion-color-primary-contrast);
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	--inner-padding-end: 0;
}
.UserGroups_userPicContainer__38c8R {
	width: 62px;
	height: 38px;
	margin-left: 0.375rem;
}
.UserGroups_userPic__1LTrz {
	height: 100%;
	width: 42px;
	font-size: 1.8em;
	margin-left: 0.3em;
}
.UserGroups_userName__2S4Mv {
	margin-left: 0.5rem;
	font-weight: 500;
	flex-grow: 1;
}
.UserGroups_selectBtn__1eZIF {
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	/* margin: 0 0.5rem 0 0; */
	width: 23px;
	height: 23px;
}
.UserGroups_selectBtn__1eZIF ion-icon.UserGroups_fabIcon__dEbWH {
	width: 1rem;
	height: 1rem;
}
.UserGroups_hidden__2JH8A {
	display: none;
}
.UserGroups_membersCount__28cOC {
	display: flex;
	justify-content: center;
}
/* more popup */
.UserGroups_itemContent__3RGyz {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.UserGroups_moreList__1SZgx tr:last-child .UserGroups_itemContent__3RGyz {
	padding-bottom: 0.375rem;
}
.UserGroups_itemLb__200Gt {
	color: var(--ion-more-lb-color);
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
	text-transform: capitalize;
}
.UserGroups_itemLb__200Gt:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}
.UserGroups_nameLb__2UNyP {
    flex-grow: 1;
}
.UserGroups_noData__2iBpT {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.UserGroups_noDataHint__BMXpb {
	font-weight: normal;
	margin-bottom: 1rem;
}
/* Edit user group modal*/
.UserGroups_editModal__GbaWC {
	--width: 613px;
	--height: 640px;
	min-height: 633px;
	--border-radius: 10px;
}
.UserGroups_editModal__GbaWC .UserGroups_headerHint__3knQ0 {
	margin: 0.5rem 0;
}
.UserGroups_editModal__GbaWC .UserGroups_btnRow__2j73B {
	margin-bottom: 1.8rem;
}
.UserGroups_editModal__GbaWC .UserGroups_modalHeader__XAYm9 {
	margin-bottom: 2rem;
}
.UserGroups_deleteFab__tVUNQ {
	margin: 10px;
}
.UserGroups_deleteFab__tVUNQ ion-fab-button {
	margin: 0;
	bottom: 0;
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-color-danger-hover);
	--background-hover-opacity: 1;
	--color: var(--ion-color-primary-contrast);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.UserGroups_deleteOverlay__2IxT6 {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	--background: var(--ion-text-color);
	background-color: var(--ion-text-color);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	z-index: 1;
	opacity: 0.9;
}
.UserGroups_deleteOverlay__2IxT6 ion-row:first-child {
	flex-grow: 1;
}
.UserGroups_editGrid__1XWFe {
	margin-top: 1.5rem;
	padding: 0 3rem;
}
.UserGroups_fullImage___XYay {
	min-width: 200px;
	min-height: 200px;
}
.UserGroups_groupAvatar__3M8yd {
	margin: auto !important;
	height: 200px;
	width: 200px;
}
.UserGroups_editableContent__29zs7 {
	flex-grow: 1;
}
.UserGroups_editableContent__29zs7 ion-list {
	width: 100%;
}
.UserGroups_addUsersItem__3BBN8 {
	margin-bottom: 2rem;
}
.UserGroups_addUsersItem__3BBN8 ion-button {
	font-size: 15px;
	text-transform: capitalize;
	margin: 0 auto;
	--color-hover: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.UserGroups_addUsersItem__3BBN8 ion-icon {
	margin-right: 0.3rem;
	height: 25px;
	width: 25px;
}
.UserGroups_addedRow__3ul9T {
	display: block;
}
.UserGroups_addedLb__1KYGG {
	margin-top: 0;
	text-align: center;
}
.UserGroups_addedLb__1KYGG ion-icon {
	position: relative;
	top: 5px;
	margin-right: 0.3rem;
}
.UserGroups_robotGroupSelect__oNidj {
	margin-top: 0.75rem;
}
.UserGroups_robotGroupSelect__oNidj div {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
}
.UserGroups_tipContent__1RXOs {
	background-color: black;
}
/************************* UserGroup Edit ******************************/
.UserGroups_editLeftSide__3UErc {
	display: flex;
	flex-direction: column;
	width: 22.5rem;
	margin-right: 1.5rem;
}
.UserGroups_recentContainer__1Maaz {
	display: flex;
	flex-grow: 1;
	width: 100%;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 0.5rem;
}
.UserGroups_recentLb__30PuO {
	font-family: 'Industry';
	font-weight: bold;
	margin-left: 0.5rem;
}
.UserGroups_recentContainer__1Maaz ion-list {
	width: 100%;
}
.UserGroups_recentContainer__1Maaz .UserGroups_profilePic__3vOm6 {
	width: 2.5rem;
	height: 2.5rem;
}
.UserGroups_recentContainer__1Maaz ion-avatar {
	margin-right: 0.5rem;
}
.UserGroups_recentContainer__1Maaz .UserGroups_icon__2KxPp {
	color: var(--ion-text-color);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.UserGroups_recentContainer__1Maaz .UserGroups_info__1zj_F,
.UserGroups_recentContainer__1Maaz .UserGroups_timeIcon__2qLV5 {
	color: var(--ion-color-gray-shade);
}
.UserGroups_createdUserGroup__1nSKg {
	display: flex;
	text-align: center;
	justify-content: center;
	color: var(--ion-color-gray-shade);
}
.UserGroups_createIcon__kkF5Q{
	display: flex;
	text-align: center;
	justify-content: center;
}
.UserGroups_createdUserGroup__1nSKg ion-icon {
	margin-right: 0.5rem;
}
.UserGroups_createdUserGroup__1nSKg span {
	text-transform: capitalize;
	margin-right: 0.3rem;
	display: contents;
}

.UserGroups_deviceGroupTooltip__2DKxy {
	cursor: pointer;
}
.UserGroups_deviceGroupTitle__lYEWf {
	color: #fff;
}
.UserGroups_groupNameItem__fn-2f {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.UserGroups_groupNameItem__fn-2f li {
	margin-top: 0.25rem;
}
.UserGroups_groupLink__2_UJu:hover {
	text-decoration: underline;
	width: 100%;
}
.UserGroups_groupNameLb__3Q-zY {
	display: flex;
}

.CreateAddItemsModal_addModal__sDLW_ {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.CreateAddItemsModal_mainContainer__2uo7j {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 50px;
	padding-right: 50px;
	padding-bottom: 50px;
}
.CreateAddItemsModal_modalContent__sm5mU {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.CreateAddItemsModal_groupNameContainer__1Fm9M div:first-child {
	width: 100%;
}
.CreateAddItemsModal_modalContent__sm5mU .CreateAddItemsModal_itemsContent__1ZR2N {
	flex-grow: 1;
}
.CreateAddItemsModal_itemsContent__1ZR2N ion-list {
	width: 100%;
}
.CreateAddItemsModal_header__24IN7 {
	width: 100%;
}
.CreateAddItemsModal_modalHeader__1IXjP {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.CreateAddItemsModal_headerContainer__17Rgd {
	display: flex;
	margin: 0 auto;
}
.CreateAddItemsModal_headerIcon__2aRnh {
	margin-right: 0.5rem;
}
.CreateAddItemsModal_headerTitle__3PdBO {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.CreateAddItemsModal_headerHint__3FlWA {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.CreateAddItemsModal_usersRow__3OD77 {
	margin-top: 1.2rem;
	margin-left: 0.5rem;
}
.CreateAddItemsModal_usersRow__3OD77 ion-col {
	flex: 1 1;
	overflow-y: auto;
}
.CreateAddItemsModal_usersRow__3OD77 ion-col:first-child {
	padding-right: 2.4rem;
}
.CreateAddItemsModal_usersList__1Onhc,
.CreateAddItemsModal_noSelection__77ZpE {
	overflow-y: auto;
	max-height: 300px;
	padding: 5px !important;
}
.CreateAddItemsModal_noSelection__77ZpE {
	margin-top: 0.9rem;
}
.CreateAddItemsModal_noSelection__77ZpE ion-label {
	color: var(--ion-color-gray);
}
.CreateAddItemsModal_noUsers__22AF1 {
	display: flex;
	margin-top: 1.3rem;
	color: var(--ion-color-gray);
}
.CreateAddItemsModal_usersSelected__KK0KD {
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 1.4rem;
	color: var(--ion-color-gray);
}
.CreateAddItemsModal_btnRow__3QDcd {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.CreateAddItemsModal_btnRow__3QDcd ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.CreateAddItemsModal_btnRow__3QDcd ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.CreateAddItemsModal_submitCol__3LIAv ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.CreateAddItemsModal_cancelBtn__2beel {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.CreateAddItemsModal_colTitle__1jNom {
	text-transform: capitalize;
	font-family: 'Industry';
	font-size: 18px !important;
	margin-bottom: 0.9rem;
}
.CreateAddItemsModal_searchBar__3iD_C {
	--placeholder-color: var(--ion-text-color);
	--placeholder-opacity: 1;
	font-size: 13px;
	text-align: left;
	width: 100%;
	margin: 10px 0 8px !important;
}
.CreateAddItemsModal_searchBar__3iD_C ion-icon {
	color: var(--ion-text-color) !important;
}
.CreateAddItemsModal_searchBar__3iD_C input {
	padding-left: 0 !important;
	border-bottom: 1px solid var(--ion-color-gray-tint) !important;
	font-size: 15px !important;
}
.CreateAddItemsModal_userItem__2mvMd {
	margin-bottom: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0.375rem;
	--inner-padding-end: 0;
}
.CreateAddItemsModal_userPicContainer__2hwmv {
	width: 62px;
	height: 38px;
	margin-left: 0.375rem;
}
.CreateAddItemsModal_userPic__13S5n {
	height: 100%;
	width: 42px;
	font-size: 1.8em;
	margin-left: 0.3em;
}
.CreateAddItemsModal_userName__3T2pt {
	margin-left: 0.5rem;
	font-weight: 500;
	flex-grow: 1;
	max-width: 88px;
}
.CreateAddItemsModal_selectBtn__1KViO {
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-text-color);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	border-radius: 50%;
	/* margin: 0 0.5rem 0 0; */
	width: 23px;
	height: 23px;
}
.CreateAddItemsModal_selectBtn__1KViO ion-icon.CreateAddItemsModal_fabIcon__-YfRd {
	width: 1rem;
	height: 1rem;
}
.CreateAddItemsModal_hidden__3Xnft {
	display: none;
}

.ReactSelect_selectField__2nZ8d {
    border-radius: 5px;
    margin-right: 8px;
    color: var(--ion-text-color); /*#6d6e70;*/
    min-width: 120px;
}
.ReactSelect_selectField__2nZ8d span {
    width: 0;
}
.ReactSelect_selectField__2nZ8d .ReactSelect_menuList__1z453:first-child {
    background-color: red!important;
}
.ReactSelect_selectLabel__2t2DD {
    color: var(--ion-text-color)!important;
    margin-left: 10px!important;
}
.ReactSelect_errorMsg__uht4w {
    color: var(--form-field-error-color);
    font-size: 0.8em;
    width: 100%;    
    display: block;
    padding-top: 5px;
    padding-left: 8px;
}
.EditImageModal_addModal__1zUQI {
    --width: 613px;
    --height: 640px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    min-height: 233px;
    --border-radius: 10px;
}
.EditImageModal_modalContent__2rpbA {
    background: var(--ion-color-primary-contrast);
    display: flex;
    flex-direction: column;    
    align-items: center;
    height: 100%;
}
.EditImageModal_modalHeader__2gyvk {
    display:block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0;
}
.EditImageModal_headerContainer__2Fvbm {
    width: 100%;
}
.EditImageModal_headerTitle__3tc7o {
    font-family: 'Industry'!important;
    font-size: 28px!important;
    margin-top: 3rem;
    margin-bottom: 1.875rem;
    display: block;
    text-align: center;
}
.EditImageModal_subTitle__2IBtu {
    color: var(--ion-color-gray-shade);
}
/* .editImageContainer {
	width: 321px;
	margin: 24px auto 6px;
	height: calc(53px + 6px);
	--inner-padding-end: 0;
} */
.EditImageModal_msgContainer__1lqw5 {
    flex: 1 1;
    margin-top: 1.5rem;
    width: 60%;
    color: var(--ion-color-primary-contrast);
}
.EditImageModal_fullImage__2f-jW {
	min-width: 200px;
	min-height: 200px;
}
.EditImageModal_userAvatar__1SUVJ {
	margin: auto !important;
	height: 200px;
    width: 200px;
    /* background-color: var(--ion-text-color); */
}
.EditImageModal_tabContainer__3GT3P {
	--background: var(--ion-color-primary-contrast);
	border-radius: 5px;
	border: 1px solid var(--ion-color-gray-shade);
	display: inline-flex;
	width: 100%;
    height: 57px;
    margin-bottom: 2.75rem;
}
.EditImageModal_tabContainer__3GT3P ion-segment-button,
.EditImageModal_tabContainer__3GT3P .EditImageModal_urlExpand__pJxCt {
    --color: var(--ion-color-gray-shade);
	--indicator-box-shadow: none;
	--padding-bottom: 15px;
	--padding-top: 16px;
	--padding-left: 2px !important;
    --padding-right: 2px !important;
    --background-hover: var(--ion-main-background-color);
    --background-hover-opacity: 1;
    --transition: 0.5s;
	border-radius: 0px;
    margin: 0;
}
.EditImageModal_urlExpand__pJxCt {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.EditImageModal_expandInput__3jNv5 {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.EditImageModal_iconContainer__3NfeL {
	width: -webkit-max-content;
	width: max-content;
}
.EditImageModal_icon__2UQ-n {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.EditImageModal_iconExpanded__E-QXq {
    color: var(--ion-color-gray-shade);
    width: 26px;
	height: 26px;
    margin: 14px 7px 10px 16px;
}
/* .tabContainer ion-segment-button:first-child {
	border-right: 1px solid var(--ion-color-gray-shade);
} */
.EditImageModal_tabContainer__3GT3P ion-segment-button:last-child {
	border-left: 1px solid var(--ion-color-gray-shade);
}
.EditImageModal_tabContainer__3GT3P ion-icon {
  width: 26px;
	height: 26px;
  margin-right: 0.5rem;
}
.EditImageModal_iconExpanded__E-QXq {
    color: var(--ion-color-gray-shade);
    width: 26px;
      height: 26px;
    margin: 14px 7px 10px 16px;
}
.EditImageModal_btnContainer__ozXDR {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 130px;
}
.EditImageModal_btnContainer__ozXDR ion-button {
    --border-width: 1px;
    --background-hover-opacity: 1;
    --transition: 0.5s;
    width: 185px;
    height: 50px;
    font-size: 15px;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
}
.EditImageModal_btnContainer__ozXDR ion-button:first-child {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.EditImageModal_cancelBtn__2M7bg {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.EditImageModal_saveBtn__YjvmI {
    --color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
    --background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
}
.EditImageModal_hidden__3cEis {
	display: none;
}
.UserMoveGroup_addModal__2YXBs {
	--border-radius: 10px;
}
.UserMoveGroup_addForm__3DnuB {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.UserMoveGroup_modalContent__dFA92 {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.UserMoveGroup_modalContent__dFA92 ion-row:first-child {
	flex-grow: 1;
}
.UserMoveGroup_modalHeader__gYPWk {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.UserMoveGroup_headerContainer__3Z5sj {
	display: flex;
	margin: 0 auto;
}

.UserMoveGroup_headerTitle___TC_O {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.UserMoveGroup_select__WrRir {
	margin-top: 0.75rem;
}
.UserMoveGroup_select__WrRir div {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
}

.UserMoveGroup_btnRow__2GebY {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.UserMoveGroup_btnRow__2GebY ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.UserMoveGroup_btnRow__2GebY ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.UserMoveGroup_submitCol__2HryA ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.UserMoveGroup_cancelBtn__3988P {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}

.UserMoveGroup_groupName__pK15i {
	margin-top: 10px;
	font-weight: 400;
	color: var(--ion-color-gray-shade);
	font-size: 20px !important;
}

.UserMoveGroup_groupContainer__382dd {
	padding-bottom: 5px;
	border-bottom: 1px solid var(--ion-color-gray-shade);
}

.UserMoveGroup_from__1IDU3 {
	color: var(--ion-color-gray-shade) !important;
}

.Robots_leftSide__2m1oy {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0px 12px;
	margin-right: 1rem;
}
.Robots_rightSide__3Dtpa {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.Robots_addRobotBtnContainer__2ELli {
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
	height: 73px;
}
.Robots_transferRobotBtnContainer__3ctjq {
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
	height: 73px;
}
.Robots_addBtn__1SStK {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
	min-width: 36px;
	min-height: 36px;
}
.Robots_addRobotIcon__385Z0 {
	margin-bottom: 8px;
	margin-right: 0.5rem;
	width: 22px;
	height: 22px;
	margin-left: 8px;
}
.Robots_addLb__1LVPk {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}

.Robots_nameContainer__-7Yk-,
.Robots_groupContainer__2Dw7g {
	display: flex;
	align-items: center;
}
.Robots_robotAvatar__1-zYW {
	display: flex;
	justify-content: center;
	height: 100%;
	width: 60px;
	margin-right: 5px;
	border-radius: 0;
}
.Robots_robotAvatarOffline__3ilMp {
	display: flex;
	max-width: 2.5rem;
	height: 100%;
	margin-right: 0.5rem;
}
.Robots_profilePic__DcpK9 {
	width: 38px;
	height: 38px;
}
.Robots_noPic__2FBkT {
	color: var(--ion-text-color);
	background-color: var(--ion-text-color);
}
/* more popup */
.Robots_itemContent__2K0zQ {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.Robots_moreList__19SQK tr:last-child .Robots_itemContent__2K0zQ {
	padding-bottom: 0.375rem;
}
.Robots_itemLb__1vZcn {
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
}
.Robots_itemLb__1vZcn:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}

.Robots_battTextContainer__2nUXW {
	display: flex;
}
.Robots_batteryText__3kRCD {
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 6px;
}
.Robots_batteryTextGrey__3lxW9 {
	font-size: 13px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 6px;
	color: var(--ion-color-gray-shade);
}
.Robots_batteryRoster__33IzF {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.Robots_batteryDetailsWrapper__2ItLq {
	display: none;
}
.Robots_batteryRoster__33IzF:hover .Robots_batteryDetailsWrapper__2ItLq {
	display: block;
	position: absolute;
	bottom: 30px;
	right: -140px;
}
.Robots_batteryRosterOff__D1E2F:hover .Robots_batteryDetailsWrapper__2ItLq {
	display: none;
}
.Robots_favoriteWrapper__2rxCp {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.Robots_favoriteWrapper__2rxCp img {
	width: 100%;
	height: 100%;
}
.Robots_offlinePic__2yOsN {
	background-color: #dddddd;
	color: #a5a5a6;
}
.Robots_noData__3G1aJ {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.Robots_noDataHint__3EgQw {
	font-weight: normal;
	margin-bottom: 1rem;
}

.Robots_transferRobotsContainer__2gic1 {
	padding: 5px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Robots_disabledSelectRobotBtn__1pwBt {
	--color: white;
	--border-color: var(--ion-text-color);
	--background: var(--ion-color-primary);
	--border-width: 1px;
	opacity: 0.25;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.Robots_selectRobotBtn__oTO6D {
	--color: white;
	--border-color: var(--ion-color-primary) !important;
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	height: 50px;
	width: 100%;
	--border-radius: 5px;
	text-transform: initial;
}

.Robots_headerTitle__3vpNO {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.Robots_modalHeader__30KhK {
	flex-direction: column;
}

.Robots_selectedRobotsContainer__3caSZ {
	display: flex;
	align-items: center;
	width: 100%;
	max-height: 200px;
	overflow-y: scroll;
}
.Robots_selectedRobotsContainerNoScroll__6io7K {
	display: flex;
	align-items: center;
	width: 100%;
	max-height: 200px;
}
.Robots_selectedRobotsContainer__3caSZ::-webkit-scrollbar {
	width: 8px;
}

.Robots_selectedRobotsContainer__3caSZ::-webkit-scrollbar-track {
	border-radius: 10px;
}

.Robots_selectedRobotsContainer__3caSZ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px 6px var(--ion-color-gray-tint);
}

.Robots_robotContainer__DSZqp {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	height: 30px;
	margin: 2px 5px;
	margin-top: 12px;
	border-radius: 5px;
	background-color: var(--ion-main-background-color);
}

.Robots_deleteRobotBtn__HinwR {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-color-gray);
	--box-shadow: none;
	height: 50px;
	width: 50px;
	margin: 0 -10px;
}
.Robots_textGrey__30vZY,
.Robots_userNameLb__Hjql_ {
	text-overflow: ellipsis;
	overflow: hidden;
	width: 140px;
	white-space: nowrap;
	font-weight: 500;
	color: rgba(36, 37, 37, 1);
}
.Robots_nameLb__tUe-B {
	flex-grow: 1;
}
.Robots_textGrey__30vZY {
	color: rgb(170, 170, 170);
}

.Robots_textGrey__30vZY:hover,
.Robots_userNameLb__Hjql_:hover {
	text-overflow: none;
	overflow: auto;
	min-width: 140px;
	width: auto;
	white-space: nowrap;
	font-weight: 500;
	color: rgba(36, 37, 37, 1);
}

.Robots_batteryContainer__21KEz {
	display: flex;
	align-items: center;
}
.Robots_batteryPercentage__2fKTm {
	padding-left: 10px;
}
.Robots_decommissionedColor__4K-f4 {
	color: var(--ion-color-gray-shade) !important;
}

.RobotEdit_leftSide__2l9D7 {
	display: flex;
	flex-direction: column;
	min-width: 22.5rem;
	margin-right: 1.5rem;
}
.RobotEdit_rightSide__x33on {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background-color: var(--ion-color-primary-contrast);
	position: relative;
	border-radius: 5px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 2.5rem;
}
.RobotEdit_rightSide__x33on:after {
	right: 100%;
	top: 12%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	/* border-color: rgba(136, 183, 213, 0); */
	border-right-color: var(--ion-color-primary-contrast);
	border-width: 14px;
	margin-top: -14px;
}
.RobotEdit_adminNote__awgVk {
	font-size: 0.813rem;
	margin-right: 0.25rem;
}
.RobotEdit_recentContainer__RtMYq {
	display: flex;
	flex-grow: 1;
	width: 100%;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 0.5rem;
}
.RobotEdit_recentLb__3zZWx {
	font-family: 'Industry';
	font-weight: bold;
	margin-left: 0.5rem;
}
.RobotEdit_recentContainer__RtMYq ion-list {
	width: 100%;
}
.RobotEdit_recentContainer__RtMYq .RobotEdit_profilePic__25aUf {
	width: 2.5rem;
	height: 2.5rem;
}
.RobotEdit_recentContainer__RtMYq ion-avatar {
	margin-right: 0.5rem;
}
.RobotEdit_recentContainer__RtMYq .RobotEdit_icon__t8Uhf {
	color: var(--ion-text-color);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.RobotEdit_recentContainer__RtMYq .RobotEdit_info__2AWOZ,
.RobotEdit_recentContainer__RtMYq .RobotEdit_timeIcon__3v4LA {
	color: var(--ion-color-gray-shade);
}
.RobotEdit_header__ODklf {
	display: flex;
	align-items: center;
}
.RobotEdit_editBtnContainer__2ZKBm {
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.RobotEdit_editBtn__3sWVq {
	--padding-top: 1rem;
	--padding-bottom: 1rem;
	--padding-start: 0.5rem;
	--padding-end: 0.5rem;
	--color: var(--ion-color-primary-contrast);
	margin-right: 1rem;
}
.RobotEdit_editable__23RUZ {
	--background: #377831;
	opacity: 1;
}
.RobotEdit_btnContainer__3BhFu {
	display: flex;
	justify-content: flex-end;
}
.RobotEdit_tabContainer__39K2Q {
	--background: var(--ion-color-primary-contrast);
	margin: 0 3rem;
	border-radius: 100px;
	border: 2px solid #dddddd;
	max-width: 370px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 36px;
}
.RobotEdit_tabContainer__39K2Q ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.RobotEdit_tabContainer__39K2Q ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.RobotEdit_detailsBtn__2uRZr {
	max-width: 110px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.RobotEdit_networkBtn__KPCp7 {
	max-width: 110px;
}
.RobotEdit_healthBtn__DWAL9 {
	max-width: 110px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.RobotEdit_iconMargin__-hykB {
	margin: 0 5px;
}
.RobotEdit_formBtns__9642p {
	display: flex;
}
.RobotEdit_formBtns__9642p ion-icon {
	color: var(--ion-color-gray);
}
.RobotEdit_formBtns__9642p ion-icon:hover {
	cursor: pointer;
}
.RobotEdit_hidden__3a7ve {
	display: none !important;
}
.RobotEdit_savedLb__eee7l {
	color: var(--ion-color-primary);
	font-size: 13px;
	margin-left: 0.5rem;
}
.RobotEdit_moreNames__28Al1 {
	display: flex !important;
}
.RobotEdit_andLb__1YPcU {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}
.RobotEdit_moreInLb__3mVT2:hover {
	text-decoration: underline;
	cursor: pointer;
}
.RobotEdit_groupNameLb__3CK19 {
	display: flex;
}
.RobotEdit_groupNameItem__3TEHv {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.RobotEdit_groupNameItem__3TEHv li {
	margin-top: 0.25rem;
}
.RobotEdit_avatarClass__1MdXx {
	display: flex;
	justify-content: center;
	border-radius: 0;
	width: 5.063rem;
	height: 100%;
	margin: 1.68rem 0.5rem 2rem 0.5rem;
}
.RobotEdit_avatarClass__1MdXx img {
	width: 100%;
	height: 100%;
	border-radius: 0 !important;
}
.RobotEdit_editAvatarClass__MI3o7 {
	display: flex;
	justify-content: center;
	border-radius: 0;
	width: 11.563rem;
	height: 100%;
	margin: 0 !important;
}
.RobotEdit_editAvatarClass__MI3o7 img {
	width: 100%;
	min-width: auto;
	min-height: auto;
	border-radius: 0 !important;
}
.RobotEdit_segmentContent___UyWD {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	overflow-y: scroll;
	overflow: hidden;
	padding: 0px;
}
.RobotEdit_segmentContent___UyWD ion-row:first-child {
	flex-grow: 1;
	height: 85%;
}
.RobotEdit_detailsContainer__29Q5v {
	width: 100%;
}
.RobotEdit_trash__2Tois {
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-color-danger-hover);
	margin-right: 2px !important;
}
.RobotEdit_btnMargin__2NaDT {
	margin-right: 1rem;
}
.RobotEdit_serviceInfoIcon__2RVRJ {
	font-size: x-large;
	padding-top: 5px;
}
.RobotEdit_serviceInfoSubIcon__k9nof {
	position: relative;
	top: 4px;
	padding-right: 5px;
}
.RobotEdit_greyLabel__3v1tQ {
	color: grey !important;
}
.RobotEdit_serviceInfoItem__3M-3S {
	border-bottom: 1px solid #f5f5f5;
	padding-top: 10px;
	padding-bottom: 10px;
}
.RobotEdit_batteryPercentage__2sqpX {
	padding-left: 10px;
}
.RobotEdit_uvdStatus__3qzJ2 {
	padding-top: 10px;
}

.RobotForm_editForm__2NKyL {
	width: 100%;
}
.RobotForm_formGrid__1ZnkF {
	width: 100%;
	padding: 0;
}
.RobotForm_formGrid__1ZnkF ion-list {
	width: 100%;
	margin-bottom: 1.2rem;
	padding-top: 0px;
}
.RobotForm_formGrid__1ZnkF ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}
.RobotForm_formGrid__1ZnkF ion-list-header {
	margin-top: 1.063rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}
.RobotForm_formGrid__1ZnkF ion-input.RobotForm_editable__2ZFVV {
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 300px;
}
.RobotForm_formGrid__1ZnkF ion-input.RobotForm_firstName__l6sYy {
	margin-right: 0.5rem;
}
.RobotForm_formGrid__1ZnkF ion-label.RobotForm_userName__1GeBP {
	margin-left: 0.5rem;
}
.RobotForm_formLb__EI9zt {
	font-weight: bold;
	margin-right: 4rem;
	min-width: 170px !important;
}
.RobotForm_formLbItem__11XoO {
	margin-right: 8px;
	background-color: #eeeeee;
	border-radius: 5px;
	height: 30px;
	padding: 7px 8px;
	flex: unset !important;
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}
.RobotForm_inputLb__3fn9R {
	margin-left: 0.5rem;
}
.RobotForm_subTitleLb__1zD9z {
	font-family: 'Industry';
	text-transform: capitalize;
	--color: var(--ion-color-gray) !important;
}
.RobotForm_chkLb__3q7XR {
	margin-left: 0.5rem;
}
.RobotForm_ddlLb__-3eSs {
	flex: 1 1 !important;
}
.RobotForm_selectLb__1U0Q3 {
	margin-left: 0 !important;
	font-weight: bold;
	margin-right: 4rem;
	min-width: 170px !important;
}
.RobotForm_groupSelect__3YGr4 {
	max-width: 300px;
	flex: 1 1;
}
.RobotForm_timeZoneSelect__1MYnD {
	width: 100%;
	max-width: 300px;
}
.RobotForm_battTextContainer__QrMFe {
	display: inline-flex;
}
.RobotForm_batteryText__W9ZE9 {
	padding-left: 0.5rem;
	padding-right: 0.25rem;
}
.RobotForm_batteryText__W9ZE9,
.RobotForm_chargingText__25sBf {
	font-size: 15px;
	text-align: center;
	display: flex;
	align-items: center;
}
.RobotForm_batteryTextGrey__rVpt7 {
	font-size: 15px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 6px;
	color: var(--ion-color-gray-shade);
}
.RobotForm_batteryRoster__2QwBX {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.RobotForm_batteryDetailsWrapper__1k1rs {
	display: none;
}
.RobotForm_batteryDetailsWrapperShow__2SVQb {
	display: block;
	position: absolute !important;
	top: 155px;
	left: 235px;
	z-index: 2;
}
.RobotForm_batteryRosterOff__1oZo0:hover .RobotForm_batteryDetailsWrapper__1k1rs {
	display: none;
}
.RobotForm_favoriteWrapper__1VS8O {
	width: 20px;
	height: 20px;
	cursor: pointer;
}
.RobotForm_favoriteWrapper__1VS8O img {
	width: 100%;
	height: 100%;
}
.RobotForm_availableIcon__2Vz9n {
	color: var(--ion-color-primary);
	vertical-align: middle;
}
.RobotForm_hidden__3YcJY {
	display: none !important;
}
.RobotForm_labelFont__3jBqU {
	font-family: 'Industry-Demi';
}

.AddNewRobot_selectContainer__tj9Y9 {
	margin: 0 0.5em 0.5em 0.5em;
}

.AddNewRobot_width__9f0dr {
	width: 9px;
}

.AddNewRobot_hidden__O8xQ4 {
	display: none;
}

.AddNewRobot_alignCenter__2eCr7 {
	text-align: center;
}

.AddNewRobot_fullWidth__2hUDP {
	width: 100%;
}

.AddNewRobot_addModal__qzrPA {
	--width: 613px;
	--height: 816px;
	min-height: 733px;
	--border-radius: 10px;
}
.AddNewRobot_addForm__2PR9U {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.AddNewRobot_modalContent__3r7i3 {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.AddNewRobot_modalContent__3r7i3 ion-row:first-child {
	flex-grow: 1;
}
.AddNewRobot_modalHeader__3kJ7W {
	display: block;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
}
.AddNewRobot_headerContainer__25ToO {
	display: flex;
	margin: 0 auto;
}
.AddNewRobot_headerIcon__3bDSv {
	margin-right: 0.5rem;
}
.AddNewRobot_icon__27g8E {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.AddNewRobot_iconExpanded__107gA {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.AddNewRobot_headerTitle__9e_IK {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.AddNewRobot_formList__17LGq {
	width: 100%;
	margin: 0 3rem;
}
.AddNewRobot_expandInput__2vnih {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.AddNewRobot_iconContainer__3AKZH {
	width: -webkit-max-content;
	width: max-content;
}
.AddNewRobot_btnRow__64ZB5 {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.AddNewRobot_btnRow__64ZB5 ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.AddNewRobot_btnRow__64ZB5 ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.AddNewRobot_submitCol__1biHh ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.AddNewRobot_cancelBtn__2xMvl {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}

.AddNewRobot_errorContainer__eeIZ- {
	color: var(--ion-color-danger);
	display: flex;
	align-items: center;
}

.AddNewRobot_passwordInput__1NNQ-{
	margin-right:40px
}
.RobotMoveGroup_addModal__1I-wu {
	--border-radius: 10px;
}
.RobotMoveGroup_addForm__1zgm7 {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.RobotMoveGroup_modalContent__1IbHX {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.RobotMoveGroup_modalContent__1IbHX ion-row:first-child {
	flex-grow: 1;
}
.RobotMoveGroup_modalHeader__VDy2t {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.RobotMoveGroup_headerContainer__MHTcJ {
	display: flex;
	margin: 0 auto;
}

.RobotMoveGroup_headerTitle__2TC0T {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}

.RobotMoveGroup_select__3FSPy {
	margin-top: 0.75rem;
}
.RobotMoveGroup_select__3FSPy div {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
}

.RobotMoveGroup_btnRow__3vBNI {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.RobotMoveGroup_btnRow__3vBNI ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.RobotMoveGroup_btnRow__3vBNI ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.RobotMoveGroup_submitCol__K3JID ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.RobotMoveGroup_cancelBtn__2V67T {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}

.RobotMoveGroup_groupName__1K4J0 {
	margin-top: 10px;
	font-weight: 400;
	color: var(--ion-color-gray-shade);
	font-size: 20px !important;
}

.RobotMoveGroup_groupContainer__3u1JS {
	padding-bottom: 5px;
	border-bottom: 1px solid var(--ion-color-gray-shade);
}

.RobotMoveGroup_from__2ZP_o {
	color: var(--ion-color-gray-shade) !important;
}

.RobotGroups_leftSide__3pyvz {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.RobotGroups_rightSide__3JlsJ {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.RobotGroups_nameContainer__3wiMV {
	display: flex;
	align-items: center;
}
.RobotGroups_groupAvatar__1EVVo {
	display: flex;
	max-width: 2.5rem;
	height: 100%;
	margin-right: 0.5rem;
}
.RobotGroups_groupPic__1uT4A {
	width: 100%;
	height: 100%;
	border-radius: 0;
}
/* more popup */
.RobotGroups_itemContent__NeV8x {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.RobotGroups_moreList__1taHw tr:last-child .RobotGroups_itemContent__NeV8x {
	padding-bottom: 0.375rem;
}
.RobotGroups_nameLb__2-hHS {
	flex-grow: 1;
}
.RobotGroups_itemLb__3Sq3u {
	color: var(--ion-more-lb-color);
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
	text-transform: capitalize;
}
.RobotGroups_itemLb__3Sq3u:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}
/* create group button */
.RobotGroups_createBtnContainer__29BbR {
	display: flex;
	align-items: center;
	padding-bottom: 1.375rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.RobotGroups_createBtn__3YvHO {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.RobotGroups_createIcon___lihP {
	height: 20px;
	width: 20px;
	margin-right: 0.3rem;
	margin-bottom: 0.3rem;
}
.RobotGroups_createLb__3Hd-v {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
}
.RobotGroups_robotsCount__Yj747 {
	display: flex;
	justify-content: center;
}
/************************* RobotGroup Edit ******************************/
.RobotGroups_editLeftSide__2tZ7x {
	display: flex;
	flex-direction: column;
	width: 22.5rem;
	margin-right: 1.5rem;
}
.RobotGroups_recentContainer__3oXRe {
	display: flex;
	flex-grow: 1;
	width: 100%;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 5px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 0.5rem;
}
.RobotGroups_recentLb__3xKbJ {
	font-family: 'Industry';
	font-weight: bold;
	margin-left: 0.5rem;
}
.RobotGroups_recentContainer__3oXRe ion-list {
	width: 100%;
}
.RobotGroups_recentContainer__3oXRe .RobotGroups_profilePic__2G8QS {
	width: 2.5rem;
	height: 2.5rem;
}
.RobotGroups_recentContainer__3oXRe ion-avatar {
	margin-right: 0.5rem;
}
.RobotGroups_recentContainer__3oXRe .RobotGroups_icon__v_aMP {
	color: var(--ion-text-color);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.RobotGroups_recentContainer__3oXRe .RobotGroups_info__3JwBY,
.RobotGroups_recentContainer__3oXRe .RobotGroups_timeIcon__Lp_Z9 {
	color: var(--ion-color-gray-shade);
}
.RobotGroups_createdUserGroup__3QZyC {
	display: flex;
	text-align: center;
	justify-content: center;
	color: var(--ion-color-gray-shade);
}
.RobotGroups_createdUserGroup__3QZyC ion-icon {
	margin-right: 0.5rem;
}
.RobotGroups_createIcon___lihP{
	display: flex;
	text-align: center;
	justify-content: center;
}
.RobotGroups_createdUserGroup__3QZyC span {
	text-transform: capitalize;
	margin-right: 0.3rem;
	display: contents;
}
.RobotGroups_hasRobotsLb__2UALy {
	text-align: center;
	margin-top: 3rem;
}
/************************* Robots Grid ******************************/
.RobotGroups_nameContainer__3wiMV {
	display: flex;
	align-items: center;
}
.RobotGroups_avatar__3FRbG {
	max-width: 2.5rem;
	height: 100%;
	margin-right: 0.5rem;
}
.RobotGroups_profilePic__2G8QS {
	width: 38px;
	height: 38px;
}
.RobotGroups_noPic__JvPS1 {
	color: var(--ion-color-primary-contrast);
}
.RobotGroups_offlinePic__2-Cj- {
	color: var(--ion-color-gray);
	padding: 0 0.75rem;
}
.RobotGroups_offlineColor__2lSuy {
	color: var(--ion-color-gray);
	flex-grow: 1;
}
.RobotGroups_online__2cgbp {
	flex-grow: 1;
}
.RobotGroups_decommissionedColor__1RWXu {
	color: var(--ion-color-gray-shade);
}
.RobotGroups_groupNameLb__2bw_h {
	display: flex;
}
.RobotGroups_groupNameItem__1NUmC {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.RobotGroups_groupNameItem__1NUmC li {
	margin-top: 0.25rem;
}
.RobotGroups_groupLink__3ia_H:hover {
	text-decoration: underline;
	width: 100%;
}
/* more popup */
.RobotGroups_itemContent__NeV8x {
	text-align: left;
	padding: 0.375rem 0.375rem 0;
}
.RobotGroups_moreList__1taHw tr:last-child .RobotGroups_itemContent__NeV8x {
	padding-bottom: 0.375rem;
}
.RobotGroups_itemLb__3Sq3u {
	display: flex;
	width: 100%;
	padding: 0.375rem 0.5rem !important;
}
.RobotGroups_itemLb__3Sq3u:hover {
	background-color: var(--ion-main-background-color);
	border-radius: 5px;
	cursor: pointer;
}
/* Edit user group modal*/
.RobotGroups_addForm__37zGz {
	height: 100%;
	margin: 2.25rem 2.3rem;
	display: flex;
	flex-direction: column;
}
.RobotGroups_modalContent__DK4qE {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
}
.RobotGroups_editGrid__38WiJ {
	margin-top: 1.5rem;
	padding: 0 3rem;
}
.RobotGroups_header__2xNjt {
	width: 100%;
}
.RobotGroups_modalHeader__38_vz {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
	margin-top: 2.5rem;
}
.RobotGroups_headerContainer__1FOEf {
	display: flex;
	margin: 0 auto;
}
.RobotGroups_headerTitle__B6k8L {
	font-family: 'Industry';
	font-size: 28px !important;
	text-transform: capitalize;
}
.RobotGroups_headerHint__1Myaq {
	color: var(--ion-color-gray-shade);
	margin: 1rem 0;
}
.RobotGroups_editModal__3_zlw {
	--width: 613px;
	--height: 640px;
	min-height: 633px;
	--border-radius: 10px;
}
.RobotGroups_editModal__3_zlw .RobotGroups_headerHint__1Myaq {
	margin: 0.5rem 0;
}
.RobotGroups_editModal__3_zlw .RobotGroups_btnRow__8aLhi {
	margin-bottom: 1.8rem;
}
.RobotGroups_editModal__3_zlw .RobotGroups_modalHeader__38_vz {
	margin-bottom: 2rem;
}
.RobotGroups_deleteFab__1Zmu7 {
	margin: 10px;
}
.RobotGroups_deleteFab__1Zmu7 ion-fab-button {
	margin: 0;
	bottom: 0;
	--background: var(--ion-color-danger);
	--background-hover: var(--ion-color-danger-hover);
	--background-hover-opacity: 1;
	--color: var(--ion-color-primary-contrast);
	--box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}
.RobotGroups_deleteOverlay__6ONWc {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	--background: var(--ion-text-color);
	background-color: var(--ion-text-color);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	z-index: 1;
	opacity: 0.9;
}
.RobotGroups_deleteOverlay__6ONWc ion-row:first-child {
	flex-grow: 1;
}
.RobotGroups_editableContent__1_aP5 {
	flex-grow: 1;
}
.RobotGroups_editableContent__1_aP5 ion-list {
	width: 100%;
}
.RobotGroups_addUsersItem__2omiI {
	margin-bottom: 2rem;
}
.RobotGroups_addUsersItem__2omiI ion-button {
	font-size: 15px;
	text-transform: capitalize;
	margin: 0 auto;
	--color-hover: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-color-primary);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.RobotGroups_addUsersItem__2omiI ion-icon {
	margin-right: 0.3rem;
	height: 25px;
	width: 25px;
}
.RobotGroups_addedRow__385G_ {
	display: block;
}
.RobotGroups_addedLb__2_Ohk {
	margin-top: 0;
	text-align: center;
}
.RobotGroups_addedLb__2_Ohk ion-icon {
	position: relative;
	top: 5px;
	margin-right: 0.3rem;
}

.RobotGroups_btnRow__8aLhi {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.RobotGroups_btnRow__8aLhi ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}
.RobotGroups_btnRow__8aLhi ion-col:first-child {
	display: flex;
	justify-content: flex-end;
}
.RobotGroups_submitCol__2w-uB ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}
.RobotGroups_cancelBtn__1BQU6 {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.RobotGroups_userGroupTooltip__3cWNJ {
	cursor: pointer;
}
.RobotGroups_userGroupTitle__3qu7e {
	color: #fff;
}

.OrganizationForm_detailsHeader__2OWSy {
	margin-top: 1.063rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
	padding: 0.8rem 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 45px;
}
.OrganizationForm_detailsCol__J4uLF {
	margin-right: 1rem;
}
.OrganizationForm_tableHeader__1r7ME {
	width: 100%;
	margin-top: 1.063rem;
}
.OrganizationForm_editForm__1rFXp,
.OrganizationForm_ssoDiv__1jYBT {
	width: 100%;
}
.OrganizationForm_formGrid__3a3ZG {
	width: 100%;
	padding: 0;
}
.OrganizationForm_formGrid__3a3ZG ion-list {
	width: 100%;
	margin-bottom: 0.3rem;
}
.OrganizationForm_formGrid__3a3ZG ion-item {
	opacity: 1;
	--border-color: rgba(0, 0, 0, 0.04);
	--highlight-color-focused: none;
}
.OrganizationForm_formGrid__3a3ZG .OrganizationForm_createdOnLb__2WTb5 {
	margin-left: 0.5rem;
}
.OrganizationForm_formGrid__3a3ZG .OrganizationForm_phoneItem__zivKY {
	display: flex;
}
.OrganizationForm_formGrid__3a3ZG .OrganizationForm_phoneItem__zivKY ion-label {
	margin-left: 1rem;
	align-self: center;
}
.OrganizationForm_formGrid__3a3ZG ion-input.OrganizationForm_editable__3wz9A {
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 300px;
}
.OrganizationForm_formGrid__3a3ZG ion-textarea.OrganizationForm_editable__3wz9A {
	border-radius: 4px;
	border: 1px solid #ccc;
}
.OrganizationForm_formGrid__3a3ZG ion-input.OrganizationForm_companyName__BPNSQ {
	margin-right: 0.5rem;
}
.OrganizationForm_formLb__1y1HP {
	font-weight: bold;
	margin-right: 4rem;
	min-width: 210px !important;
}
.OrganizationForm_inputLb__2Ab0U {
	margin-left: 0.5rem;
}
.OrganizationForm_inputLbContact__211zX {
	margin-left: 0.5rem !important;
	max-width: 100% !important;
}
.OrganizationForm_inputLbAgreement__1iWpJ {
	color: var(--ion-color-primary) !important;
}
.OrganizationForm_inputLbAgreement__1iWpJ:hover {
	cursor: pointer !important;
}
.OrganizationForm_subTitle__1l4XE {
	margin: 1rem;
}
.OrganizationForm_subTitleLb__2bSUg {
	font-family: 'Industry';
	text-transform: capitalize;
	color: var(--ion-color-gray);
}
.OrganizationForm_chkLb__1BrB2 {
	margin-left: 0.5rem;
}
.OrganizationForm_ddlLb__KPltM {
	flex: 1 1 !important;
}
.OrganizationForm_selectLb__15nVh {
	margin-left: 0 !important;
	font-weight: bold;
	margin-right: 6.5rem;

	min-width: 170px !important;
}
.OrganizationForm_groupSelect__l6xMd {
	max-width: 300px;
	flex: 1 1;
}
.OrganizationForm_hidden__UKKeb {
	display: none !important;
}

.OrganizationForm_fullWidth__3dfO3 {
	width: 100%;
}

.OrganizationForm_agreementFrame__2d9Dc {
	border: none;
	width: 100%;

	height: 597.2px !important;
}

.OrganizationForm_modalHeader__2EYK_ {
	text-align: center;
}
.OrganizationForm_addModal__24356 {
	--width: 613px;
	--height: 813px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
	min-height: 233px;
	--border-radius: 10px;
}
.OrganizationForm_domainConfigurationModal__296HF {
	max-width: 813px;
}
.OrganizationForm_modalContent__2t_gF {
	background: var(--ion-color-primary-contrast);
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}
.OrganizationForm_modalHeader__2EYK_ {
	display: block;
	text-align: center;
	margin-bottom: 1rem;
	padding: 0;
}
.OrganizationForm_headerContainer__2APGA {
	width: 100%;
	text-align: left;
	margin-left: 22px;
}
.OrganizationForm_headerTitle__3FJ2m {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	margin-top: 3rem;
	margin-bottom: 1.875rem;
	display: block;
}
.OrganizationForm_domainConfigurationHeaderTitle__3NcST {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	margin-top: 3rem;
	margin-bottom: 1rem;
	display: block;
}
.OrganizationForm_subTitle__1l4XE {
	color: var(--ion-color-gray-shade);
}
.OrganizationForm_modalDomainConfHeaderContainer__23rBB {
	width: 100%;
	margin-left: 22px;
}
/* .editImageContainer {
	width: 321px;
	margin: 24px auto 6px;
	height: calc(53px + 6px);
	--inner-padding-end: 0;
} */
.OrganizationForm_msgContainer__2QEzN {
	flex: 1 1;
	margin-top: 1.5rem;
	width: 60%;
	color: var(--ion-color-primary-contrast);
}
.OrganizationForm_fullImage__1bbUE {
	min-width: 200px;
	min-height: 200px;
}
.OrganizationForm_userAvatar__3IJkA {
	margin: auto !important;
	height: 200px;
	width: 200px;
	/* background-color: var(--ion-text-color); */
}
.OrganizationForm_tabContainer__D2fy0 {
	--background: var(--ion-color-primary-contrast);
	border-radius: 5px;
	border: 1px solid var(--ion-color-gray-shade);
	display: inline-flex;
	width: 100%;
	height: 57px;
	margin-bottom: 2.75rem;
}
.OrganizationForm_tabContainer__D2fy0 ion-segment-button,
.OrganizationForm_tabContainer__D2fy0 .OrganizationForm_urlExpand__jApME {
	--color: var(--ion-color-gray-shade);
	--indicator-box-shadow: none;
	--padding-bottom: 15px;
	--padding-top: 16px;
	--padding-left: 2px !important;
	--padding-right: 2px !important;
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--transition: 0.5s;
	border-radius: 0px;
	margin: 0;
}
.OrganizationForm_urlExpand__jApME {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.OrganizationForm_expandInput__3qi_2 {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.OrganizationForm_iconContainer__2apIQ {
	width: -webkit-max-content;
	width: max-content;
}
.OrganizationForm_icon__282Fk {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.OrganizationForm_iconExpanded__2Nffu {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
/* .tabContainer ion-segment-button:first-child {
	border-right: 1px solid var(--ion-color-gray-shade);
} */
.OrganizationForm_tabContainer__D2fy0 ion-segment-button:last-child {
	border-left: 1px solid var(--ion-color-gray-shade);
}
.OrganizationForm_tabContainer__D2fy0 ion-icon {
	width: 26px;
	height: 26px;
	margin-right: 0.5rem;
}
.OrganizationForm_iconExpanded__2Nffu {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.OrganizationForm_btnContainer__vKE2q {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	min-height: 130px;
}
.OrganizationForm_btnContainer__vKE2q ion-button {
	--border-width: 1px;
	--background-hover-opacity: 1;
	--transition: 0.5s;
	width: 185px;
	height: 50px;
	font-size: 15px;
	margin-top: 0.5rem;
	margin-bottom: 1.2rem;
}
.OrganizationForm_btnContainer__vKE2q ion-button:first-child {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.OrganizationForm_cancelBtn__1UKXY {
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
}
.OrganizationForm_saveBtn__1u4-y {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
}
.OrganizationForm_hidden__UKKeb {
	display: none;
}
.OrganizationForm_agreementRow__1FOIz {
	height: 50px !important;
	width: 100%;
	border-bottom: 1px solid #f1f1f3;
	align-items: center;
}
.OrganizationForm_agreementRowContent__3J-aR {
	height: 100%;
	margin-left: 0.5rem;
	font-family: Roboto;
	font-size: 15px;
	color: #1e1f22;
	line-height: 1.33;
	text-align: left;
}
.OrganizationForm_noTopPadding__2Ib8Z {
	padding-top: 0;
}

.OrganizationForm_btnRow__1tfaL ion-button {
	width: 185px;
	margin: 0 auto;
}

.OrganizationForm_submitCol__3EYLI ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}

.OrganizationForm_countrySelectItem__utZEd {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	min-height: 48px;
	padding-left: 16px;
}

.OrganizationForm_countrySelectItem__utZEd > ion-label {
	max-width: 210px;
}

.OrganizationForm_errorMsg__YSIBz {
	color: var(--form-field-error-color);
	font-size: 0.8em;
}
.OrganizationForm_formBtns__soFzH {
	display: flex;
}
.OrganizationForm_formBtns__soFzH ion-icon {
	color: var(--ion-color-gray);
}
.OrganizationForm_formBtns__soFzH ion-icon:hover {
	cursor: pointer;
}
.OrganizationForm_copyOrgCallbackURLBtn__2c96F {
	cursor: pointer;
}
.OrganizationForm_copiedOrgCallbackURL__136MR {
	text-align: right;
	max-width: 80px;
}
.OrganizationForm_labelFont__1N8i3 {
	font-family: 'Industry-Demi';
}
.OrganizationForm_countryDiv__m7o8m{
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 300px;
	width: 100%;
}
.OrganizationDomainsGrid_nameContainer__2-AfL {
	display: flex;
	align-items: center;
}
.OrganizationDomainsGrid_domainNameLb__1iFtR {
	justify-content: flex-start;
	max-width: 100% !important;
}
.OrganizationDomainsGrid_domainVerificationContainer__3o4-e {
	display: flex;
	align-items: center;
	max-width: 120px;
}
.OrganizationDomainsGrid_domainVerificationBtn__6z61x {
	max-width: -webkit-max-content !important;
	max-width: max-content !important;
	width: -webkit-max-content !important;
	width: max-content !important;
	justify-content: flex-start;
}
.OrganizationDomainsGrid_copyVerificationTokenBtn__3L_bL {
	margin-left: 10px;
	cursor: pointer;
}
.OrganizationDomainsGrid_noData__3GeZ3 {
	color: var(--ion-color-gray-shade);
	font-weight: bold;
	width: 100%;
	min-width: 100%;
	text-align: center;
	margin-top: 1rem;
}
.OrganizationDomainsGrid_noDataHint__3IGCx {
	font-weight: normal;
	margin-bottom: 1rem;
}

.OrganizationDomainsGrid_listGrid__1rlvk {
	padding: 0;
	margin: 0;
	height: 100%;
	flex-grow: 1;
}
.OrganizationDomainsGrid_headerRow__VR6Kt {
	display: flex;
	align-items: center;
	height: 2.813rem;
	background: var(--ion-main-background-color);
	border-radius: 5px;
	font-family: 'Industry';
	padding-left: 1rem;
}
.OrganizationDomainsGrid_centeredCol__172JO {
	text-align: center;
	display: flex;
	justify-content: center;
}
.OrganizationDomainsGrid_dataRow__2DzPj {
	background-color: var(--ion-color-primary-contrast);
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	margin-top: 0.5rem;
	align-items: center;
	padding-left: 0.5rem;
}
.OrganizationDomainsGrid_createdRow__wqytB {
	background-color: #edf8ec;
}
.OrganizationDomainsGrid_noData__3GeZ3 {
	margin-top: 1rem;
	padding-left: 0.5rem;
}
.OrganizationDomainsGrid_noData__3GeZ3:hover {
	cursor: default;
}
.OrganizationDomainsGrid_moreCol__ebJII {
	max-width: 3.75rem;
}
.OrganizationDomainsGrid_moreIcon__R2C9R {
	color: var(--ion-color-gray);
	border-radius: 50%;
	padding: 0.375rem;
}
.OrganizationDomainsGrid_moreIcon__R2C9R:hover {
	background-color: var(--ion-main-background-color);
	cursor: pointer;
}
.OrganizationDomainsGrid_info__1z1ek {
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
}
.OrganizationDomainsGrid_hide__ABo1- {
	display: none;
}
.OrganizationDomainsGrid_popup__3T_pQ {
	--box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.015);
	border-radius: 10px;
}
.OrganizationDomainsGrid_elementContainer__2s7aZ {
	height: inherit;
	display: inline-flex;
	align-items: center;
}
.OrganizationDomainsGrid_closeIcon__2dOa0 {
	margin-left: 10px;
	width: 25px;
	height: 25px;
}
.OrganizationDomainsGrid_columnHeader__1I9xL {
	display: flex;
	align-items: center;
	text-transform: uppercase;
}
.OrganizationDomainsGrid_domainAddInput__2MSRN {
	border-radius: 4px;
	border: 1px solid #ccc;
	max-width: 250px;
	height: 35px;
}
.OrganizationDomainsGrid_domainValidation__FcqwZ {
	color: #ff0000;
}
.OrganizationDomainsGrid_submitCol__3qDkI ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}

.OrganizationInfo_leftSide__32KNc {
	display: flex;
	flex-direction: column;
	width: 13.25rem;
	background-color: var(--ion-color-primary-contrast);
	border-radius: 0.313rem;
	padding: 0.825rem 0.5rem 0;
	margin-right: 1rem;
}
.OrganizationInfo_rightSide__1XNOf {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background-color: var(--ion-color-primary-contrast);
	position: relative;
	border-radius: 5px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
	padding: 1.063rem 2.5rem;
}
.OrganizationInfo_header__29U2S {
	display: flex;
	align-items: center;
}
.OrganizationInfo_formBtns__28Qnd {
	display: flex;
}
.OrganizationInfo_formBtns__28Qnd ion-icon {
	color: var(--ion-color-gray);
}
.OrganizationInfo_formBtns__28Qnd ion-icon:hover {
	cursor: pointer;
}
.OrganizationInfo_hidden__27bLL {
	display: none !important;
}
.OrganizationInfo_newBtnContainer__2_bAE {
	display: flex;
	align-items: center;
	padding-bottom: 1.425rem;
	padding-left: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.OrganizationInfo_newLb__dB3zX {
	flex-grow: 1;
	font-weight: bold;
	font-family: 'Industry';
	text-transform: capitalize;
}
.OrganizationInfo_newBtn__3oyqq {
	--padding-end: 0.5rem;
	--padding-start: 0.5rem;
	--color: var(--ion-text-color);
	--background: var(--ion-color-primary-contrast);
}
.OrganizationInfo_editBtnContainer__NhP7U {
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.OrganizationInfo_editBtn__3Byca {
	--padding-top: 1rem;
	--padding-bottom: 1rem;
	--padding-start: 0.5rem;
	--padding-end: 0.5rem;
	--color: var(--ion-color-primary-contrast);
	margin: 0px 0px 0px 2px;
}
.OrganizationInfo_editable__2k9Ff {
	--background: #377831;
	opacity: 1;
}
.OrganizationInfo_savedLb__20kyC {
	color: var(--ion-color-primary);
	font-size: 13px;
	margin-left: 0.5rem;
}
.OrganizationInfo_saveErrorLb__2Jz3z {
	color: var(--ion-color-danger);
	font-size: 13px;
	margin-left: 0.5rem;
}
.OrganizationInfo_tabContainer__31OBq {
	--background: var(--ion-color-primary-contrast);
	margin: 0 3rem;
	border-radius: 100px;
	border: 2px solid #dddddd;
	max-width: 600px;
}
.OrganizationInfo_tabContainer__31OBq ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.OrganizationInfo_tabContainer__31OBq ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.OrganizationInfo_detailsBtn__1fksX {
	max-width: 90px;
	--background: var(--ion-color-primary-contrast);
	--color: var(--ion-color-primary);
}
.OrganizationInfo_goBeCareBtn__25JKe {
	max-width: 110px;
}
.OrganizationInfo_ssoBtn__4WOod {
	max-width: 90px;
}
.OrganizationInfo_segmentContent__2uoqT {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	height: 50%;
	overflow-y: scroll;
	overflow: hidden;
	padding-top: 0px !important;
}
.OrganizationInfo_segmentContent__2uoqT ion-row:first-child {
	flex-grow: 1;
}
.OrganizationInfo_goBeHeader__3l-vE {
	background-color: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	padding: 0.5rem 1rem 0.8rem;
	border-radius: 5px;
	margin-top: 0.438rem;
}

.FleetManagement_main__2bqRP {
	height: 100%;
	display: flex;
	flex-flow: column;
	padding: 0 !important;
}
.FleetManagement_orgSelect__2A5uM {
	font-family: 'Industry';
	font-size: 28px;
	border-radius: 5px;
	border: solid 1px #d2d2d2;
	padding-right: 1rem;
	height: 65px;
}
.FleetManagement_orgSelect__2A5uM::part(text) {
	padding-right: 0.5rem;
}
.FleetManagement_orgSelect__2A5uM::part(icon)
{
	/* display: none; */
}
.FleetManagement_fabBtnContainer__2bu94 {
	position: relative;
	display: flex;
    z-index: 0;
}
.FleetManagement_deleteFabBtn__3RcTj {
	margin-left: 0.5rem!important;
}
.FleetManagement_addFabBtn__RDtoZ, .FleetManagement_editFabBtn__h7xwK, .FleetManagement_deleteFabBtn__3RcTj {
    --background: #dddddd;
    --color: var(--ion-text-color);
    --box-shadow: none;
	border-radius: 50%;
	max-width: 37px;
	max-height: 37px;
    margin: 0 0 0 0.5rem;
}
.FleetManagement_fabIcon__2dFFd {
    color: #777779;
}
.FleetManagement_orgDeleteDivTooltip__2aqO6 {
	cursor: pointer;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
.FleetManagement_orgDeleteTitle__DvAs_ {
	color: #fff;
}
.FleetManagement_orgDeleteTooltip__wuMHV {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.FleetManagement_orgDeleteTooltip__wuMHV li {
	margin-top: 2px;
}

.ant-cascader {
	background-color: #eeeeee;
	border: 1px solid #d2d2d2;
	border-radius: 5px;
	height: 65px;
	width: auto;
    min-width: 150px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border:none;
    background-color:#eeeeee;
    height: 63px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	   height: 63px;
	   border:none;
	    background-color: #eeeeee;
	}
.ant-select-selection-item {
	font-family: Industry;
	font-style: normal;
	font-size: 28px;
	width: -webkit-max-content;
	width: max-content;
	margin-top: 15px;
}
.ant-cascader-menus {
	font-family: Roboto;
	font-style: normal;
	font-size: 15px;
}

.ant-select-selection-search-input {
	background-color: #eeeeee;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
	top: 12px;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-cascader-menu-item:hover {
	background-color: var(--ion-color-gray);
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
	background-color: var(--ion-color-gray-tint);
}
.Helpcenter_helpCenterContent__1Whr1 {
    height: 98%;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 5px;
    box-shadow: 3px 3px 8px 3px rgba(221,221,221,0.5);
}
.Settings_settingsContent__1DsSA {
    height: 98%;
    background-color: var(--ion-color-primary-contrast);
    border-radius: 5px;
    box-shadow: 3px 3px 8px 3px rgba(221,221,221,0.5);
}



.Settings_leftSide__394XG {
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin-right: 1rem;
    padding-left: 0;
}

.Settings_rightSide__1ObL2 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 40px;
    min-height: 550px;
}

.Settings_triangle__CbGan {
    right: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: var(--ion-color-primary-contrast);
    border-width: 14px;
    margin-top: -14px;
}

.Settings_hide__1pqVP {
    display: none;

}

.Settings_list__34ufD {
    padding: 0;
    background-color: transparent;
    overflow: auto;
    height: 100%;
    max-height: 560px;
}

.Settings_list__34ufD ion-item {
    --background: transparent;
    --background-hover: #dddddd;
    --background-hover-opacity: 1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    /* height: 50px;
    width: 200px; */
}

.Settings_list__34ufD ion-item:hover {
    cursor: pointer;
}

.Settings_selectedItem__2H6hw {
    --background: var(--ion-color-primary-contrast) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}

.Settings_leftCol__1RBzV {
    max-width: 22.5rem;
}

.Settings_btnCol__3hSox {
    display: flex;
    justify-content: flex-end;
}

.Settings_btn__2TJ0U {
    height: 50px;
    --border-width: 1px;
    --border-style: solid;
    --background: transparent;
    --background-hover-opacity: 1;
    --box-shadow: none;
    --transition: 0.5s;
}

.Settings_backBtn__2btnf {
    max-width: 9.5rem;
    --color: var(--ion-text-color);
    --color-hover: var(--ion-color-primary-contrast);
    --border-color: var(--ion-text-color);
    --background-hover: var(--ion-text-color);
}

.Settings_backBtn__2btnf ion-icon {
    padding-bottom: 0.1rem;
}

.Settings_nextBtn__32Gl2 {
    width: 11.563rem;
    --color: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    --background: var(--ion-color-primary);
    --background-hover: var(--ion-main-background-color);
}
.ProfileSettings_personIcon__3kOLJ {
	margin-right: 0.5rem;
}
/* Profile settings */
.ProfileSettings_formGrid__2viBG {
	width: 100%;
	padding: 0;
}
.ProfileSettings_formGrid__2viBG ion-list {
	width: 100%;
	padding: 0;
}
.ProfileSettings_formGrid__2viBG .ProfileSettings_firstCol__7ru9k {
	margin-right: 5rem;
}
.ProfileSettings_formGrid__2viBG ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}
.ProfileSettings_formGrid__2viBG ion-list-header {
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}
.ProfileSettings_msgContainer__2bECn {
	flex: 1 1;
	margin-top: 1.5rem;
	width: 100%;
	color: var(--ion-color-primary-contrast);
}
.ProfileSettings_fullImage__37-Qy {
	min-width: 200px;
	min-height: 200px;
}
.ProfileSettings_userAvatar__a7vfH {
	margin: auto !important;
	height: 200px;
	width: 200px;
}
.ProfileSettings_urlExpand__fI8gG {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.ProfileSettings_expandInput__26ksp {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.ProfileSettings_iconContainer__14UMG {
	width: -webkit-max-content;
	width: max-content;
}
.ProfileSettings_icon__3UIRp {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.ProfileSettings_iconExpanded__16mjQ {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.ProfileSettings_hidden__2w4jF {
	display: none;
}
.ProfileSettings_chkContainer__RLp9x {
	margin-top: 0 !important;
	--padding-start: 0;
	padding-left: 1rem;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
}

.ProfileSettings_chkContainerPrinciple__2e50m {
	font-weight: bold;
}
.ProfileSettings_chkContainer__RLp9x ion-label {
	font-size: 15px !important;
	padding-top: 0.15rem;
	padding-left: 0.5rem;
}
.ProfileSettings_selectContainer__3yKqQ {
	margin: 0 0.5em 0.5em 0.5em;
}
.ProfileSettings_profileListHeader__3UGpO {
	--background: transparent !important;
}
.ProfileSettings_profileHeader__1yoDJ {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}
.ProfileSettings_linkContainer__GTPC7 {
	display: flex;
	margin-top: 3rem;
}
.ProfileSettings_linkContainer__GTPC7 ion-icon {
	color: var(--ion-color-primary);
}
.ProfileSettings_link__AtqJR {
	color: var(--ion-color-primary);
}
.ProfileSettings_link__AtqJR:hover,
.ProfileSettings_linkContainer__GTPC7 ion-icon:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
/* Notifications settings */
.ProfileSettings_notificationsGrid__1nwrE {
	margin: 0;
}
.ProfileSettings_notifyWhenRow__3XGLU {
	background-color: var(--ion-main-background-color);
}
.ProfileSettings_notifyWhenRow__3XGLU ion-item {
	--background: var(--ion-main-background-color);
	padding-left: 1rem;
}
.ProfileSettings_notifyWhenRow__3XGLU ion-label {
	text-transform: uppercase;
	font-family: 'Industry-Demi';
	margin: 0;
}
.ProfileSettings_notifyWhenRow__3XGLU ion-checkbox {
	margin: 0;
}

.ProfileSettings_innerLabelCheckbox__2gAmv {
	margin-left: 10px;
}
.ProfileSettings_submitCol__2duDW ion-button {
	--color: var(--ion-color-primary-contrast);
	--color-hover: var(--ion-color-primary);
	--border-width: 1px;
	--background: var(--ion-color-primary);
	--background-hover: var(--ion-color-primary-contrast);
	--background-hover-opacity: 1;
	--transition: 0.5s;
}

.ProfileSettings_btnRow__32mRT {
	display: flex;
	align-items: flex-end;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}
.ProfileSettings_btnRow__32mRT ion-button {
	width: 185px;
	height: 50px;
	font-size: 15px;
}

.ProfileSettings_detailsHeader__1SGJx {
	margin-top: 1.063rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
	padding: 0.8rem 1rem;
	font-weight: bold;
}

.ProfileSettings_agreementRowContent__FGc8q {
	height: 100%;
	margin-left: 0.5rem;
	font-family: Roboto;
	font-size: 15px;
	color: #1e1f22;
	line-height: 1.33;
	text-align: left;
}
.ProfileSettings_detailsCol__gNWnL {
	margin-right: 1rem;
}
.ProfileSettings_noTopPadding__3IyHa {
	padding-top: 0;
}

.ProfileSettings_agreementRow__25DpM {
	height: 50px !important;
	width: 100%;
	border-bottom: 1px solid #f1f1f3;
	align-items: center;
}

.ProfileSettings_inputLbAgreement__3lnmh {
	color: var(--ion-color-primary) !important;
}
.ProfileSettings_inputLbAgreement__3lnmh:hover {
	text-decoration: underline;
	color: #377831 !important;
	cursor: pointer !important;
}
.ProfileSettings_errorContainer__2-7mZ {
	color: var(--ion-color-danger);
}

.ProfileSettings_formItemContainer__1JRQ6 {
	margin: 0 0.5em 0.5em;
}

.ProfileSettings_formItem__2ydvF {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.ProfileSettings_formItem__2ydvF:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.ProfileSettings_formItem__2ydvF:active {
	--highlight-background: var(--form-field-active-color);
}

.ProfileSettings_formItem__2ydvF ion-label {
	margin-bottom: 10px;
}

.ProfileSettings_formItem__2ydvF ion-label.ProfileSettings_lbColor__NX4ZW {
	color: var(--ion-text-color) !important;
}

.ProfileSettings_formItem__2ydvF .ProfileSettings_colorFocused__1p7wD,
.ProfileSettings_formItem__2ydvF ion-label.ProfileSettings_colorFocused__1p7wD {
	color: var(--ion-color-primary) !important;
}

.ProfileSettings_formItem__2ydvF .ProfileSettings_hasFocus__3LRVa,
.ProfileSettings_formItem__2ydvF ion-label.ProfileSettings_hasFocus__3LRVa {
	color: var(--ion-color-primary) !important;
}

.ProfileSettings_formItem__2ydvF ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.ProfileSettings_checkMarkContainer__1Ceh0 {
	bottom: 90px !important;
}

.ProfileSettings_checkMarkConfirmationContainer__wMhGT {
	position: absolute;
	bottom: 17px;
	right: 64px;
	z-index: 1000;
}

.ProfileSettings_checkMarkContainerError__1dw_J {
	bottom: 105px !important;
}

.ProfileSettings_settingsListHeader__2jl6s {
	margin: 0px !important;
}
.ProfileSettings_labelFont__21lBr {
	font-family: 'Industry-Demi';
}

.PasswordRequirements_container__WRfDg {
	display: block;
	position: absolute;
	-webkit-transform: translateY(74px);
	        transform: translateY(74px);
	right: 0;
	width: 300px;
	padding: 5px 20px;
	z-index: 99999999999;
	background: #fff;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 0px 2px 10px #00000027;
}

.PasswordRequirements_container__WRfDg::before {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-right: 8px solid white;
	left: -8px;
	top: 50px;
}

.PasswordRequirements_iconContainer__q6cTX {
	position: absolute;
	display: block;
	-webkit-transform: translateY(40px);
	        transform: translateY(40px);
	right: 60px;
	z-index: 99999;
}
.PasswordRequirements_groupNameItem__35_pD {
	margin: 0;
	padding: 5px 15px;
	display: block;
}
.PasswordRequirements_groupNameItem__35_pD li {
	margin-top: 0.25rem;
	font: Roboto;
	font-size: 15px;
	text-align: left;
	letter-spacing: 0px;
}

.PasswordRequirements_invalid__309s9:before {
}

.PasswordRequirements_valid__3esNM {
	list-style-type: none;
	color: var(--ion-color-primary);
}
.PasswordRequirements_valid__3esNM:before {
	content: '✓  ';
	position: absolute;
	left: 15px;
	width: 1em;
	height: 1em;
}

.PilotAppSettings_icon__sSOfU {
	margin-right: 0.5rem;
}

/* Organization details */
.PilotAppSettings_formGrid__143TV {
	height: 700px;
	width: 100%;
	padding: 0;
	margin-bottom: 3.5rem;
	
}

.PilotAppSettings_formGrid__143TV ion-list {
	width: 100%;
	padding: 0;
}

.PilotAppSettings_formGrid__143TV .PilotAppSettings_firstCol__3U9hJ {
	margin-right: 5rem;
	margin-top:15px;
}



.PilotAppSettings_formGrid__143TV ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}

.PilotAppSettings_formGrid__143TV ion-list-header {
	margin-top: 1.063rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.PilotAppSettings_detailHeader__2vlep {
	font-family: 'Industry-Demi';
}

.PilotAppSettings_selectContainer__Z1zLv {
	margin: 0 0.5em 0.5em 0.5em;
}


.PilotAppSettings_urlExpand__2FA8P {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.PilotAppSettings_expandInput__RxBlF {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}

.PilotAppSettings_iconContainer__3s7gh {
	width: -webkit-max-content;
	width: max-content;
}

.PilotAppSettings_icon__sSOfU {
	width: 13.8px;
	height: 14.8px;
	margin-right: 8px;
}

.PilotAppSettings_iconExpanded__2j3sD {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}

.PilotAppSettings_hidden__1VNJg {
	display: none;
}

.PilotAppSettings_createdOnLb__2nR58 {
	--color: var(--ion-color-gray) !important;
	margin-top: 2rem;
}

.PilotAppSettings_profileListHeader__3WBOq {
	--background: transparent !important;
}

.PilotAppSettings_profileHeader__3OxQt {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}

.PilotAppSettings_sameAsLb__nSB-a {
	color: var(--ion-color-gray-shade) !important;
	text-transform: none !important;
	padding-left: 0.5rem;
	font-family: 'Roboto' !important;
	font-size: 13px !important;
}

.PilotAppSettings_sameAsChk__1vlyn {
	margin-left: 0.5rem;
}

.PilotAppSettings_billingList__2v-_I {
	margin-right: 2rem;
}

.PilotAppSettings_zipCityContainer__33o0b {
	display: flex;
}

.PilotAppSettings_zipCityContainer__33o0b div {
	width: 100%;
}

.PilotAppSettings_formItemContainer__1oW5s {
	margin: 0 0.5em 0.5em;
}

.PilotAppSettings_formItem___qKje {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.PilotAppSettings_formItem___qKje:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.PilotAppSettings_formItem___qKje:active {
	--highlight-background: var(--form-field-active-color);
}

.PilotAppSettings_formItem___qKje ion-label {
	margin-bottom: 10px;
}

.PilotAppSettings_formItem___qKje ion-label.PilotAppSettings_lbColor__2-pEb {
	color: var(--ion-text-color) !important;
}

.PilotAppSettings_formItem___qKje .PilotAppSettings_colorFocused__-7v7v,
.PilotAppSettings_formItem___qKje ion-label.PilotAppSettings_colorFocused__-7v7v {
	color: var(--ion-color-primary) !important;
}

.PilotAppSettings_formItem___qKje .PilotAppSettings_hasFocus__2VGUa,
.PilotAppSettings_formItem___qKje ion-label.PilotAppSettings_hasFocus__2VGUa {
	color: var(--ion-color-primary) !important;
}

.PilotAppSettings_formItem___qKje ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.PilotAppSettings_errorItem__32zj4 {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}

.PilotAppSettings_errorMsg__3l77D {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}

.PilotAppSettings_inputIcon__dbILT {
	display: inline-flex !important;
	width: 100%;
}

.PilotAppSettings_inputIconSize__4EkZT {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

.PilotAppSettings_robotTimeZoneItem__t7bRf{
	margin-top:5px
}

.PilotAppSettings_robotTimeZoneSelect__3uiQj{
	margin-top:5px;
	width:100%
}


.PilotAppSettings_rectangleLocalVoice__2VTBV {
	transition: 0.5s;
	width: 0px;
	overflow: hidden;
}

.PilotAppSettings_rectangleLocalVoice__2VTBV:hover {
	width: 160px;
	height: 50px;
	opacity: 1 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: default;
	transition: 0.5s;
}

.PilotAppSettings_sliderContainer__2BFag {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 30px;
	width: 100%;
	padding: 10px;
	margin-left:-15px;
	
}

.PilotAppSettings_sliderDiv__2fG1y{
	background-color: #eeeeee;
	max-width:296px ;
	height:50px;
	border-radius: 50px;
	margin-top:25px;
	margin-left:-20px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.PilotAppSettings_sliderDiv2__1-Vax{
	background-color: #eeeeee;
	max-width:296px ;
	height:50px;
	border-radius: 50px;
	margin-top:21px;
	margin-left:-20px;
}

.PilotAppSettings_volumeStatusText__3pO0z{
	color: var(--ion-color-primary) !important;
font-size:18px !important;

}

.PilotAppSettings_volumeStatusTextWhenIs0__3uZti{color: red !important;
	font-size:18px !important;}

.PilotAppSettings_ionIcon__2SsqH{
	font-size:20px;
	color:#a5a5a6;
}

.PilotAppSettings_ionIconOff__3tKYx{
	font-size:20px;
	color:red;
}
.PilotAppSettings_leftCol__pqEM9{
	text-align:right;
}

.PilotAppSettings_rightCol__2ZQsO{
	/* margin-top:10px; */
}

.PilotAppSettings_ionIconVolume__1s2W-{
	color: var(--ion-color-primary) !important;
	margin-top:35px;
}

.PilotAppSettings_firstSliderLabel__QRkk_{
	max-width:60px;
}

.PilotAppSettings_secondSliderLabel__13u3u{
	margin-top:35px !important;
	color:#78787a;
	max-width:60px;
}

.PilotAppSettings_secondSliderLabel__13u3u:hover{
	cursor:pointer;
}


.PilotAppSettings_borderCameraScreen__Xdy1Z{
	height:300px;
	width:280px;
	background-color: #1e1f22;
	padding:20px;
	margin-top:50px;
	margin-left:65px;
}

.PilotAppSettings_borderCameraScreen__Xdy1Z .PilotAppSettings_CameraScreen__3wH3w{
	width:240px;
	height: 260px;
	background-color: #78787a;
	
}

.PilotAppSettings_sliderContainer__2BFag {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 30px;
	width: 100%;
	padding: 10px;
}
.PilotAppSettings_sliderContainer__2BFag .PilotAppSettings_inputR__3NTjs {
	/* -webkit-appearance: none; */
	-webkit-appearance: none;
	        appearance: none;
	width: 150px;
	height: 6px;
	border-radius: 2px;
	opacity: 1;
	outline: none;
	cursor: pointer;
}
.PilotAppSettings_sliderContainer__2BFag .PilotAppSettings_inputR__3NTjs::-webkit-slider-thumb {
	-webkit-appearance: none;
	        appearance: none;
	/* -webkit-appearance: none; */
	background: #56ae4d;
	height: 25px;
	width: 25px;
	border-radius: 50%;
}
.PilotAppSettings_cursorointer__4CQsx {
	cursor: pointer;
}



.PilotAppSettings_sliderCol__HH2hR{
	margin-left:-20px;
	margin-top:-6px;
}



.PilotAppSettings_leftColWithSelect__2od-u{
	min-width:400px;
}

.PilotAppSettings_rightColWithSelect__1MAzG{
	min-width:400px;
}

.PilotAppSettings_rowSliders__JU18g{
	min-width:300px;
}

.PilotAppSettings_label__3mIGU{
	min-width:50px
}

 .PilotAppSettings_videoLandscape__3-aaN {
	width:294px;
	height:391px;
	overflow: hidden;	
  }

  .PilotAppSettings_videoRow__mOyr-{
	  margin-top:30px;
	  margin-left:-15px;
  }

  .PilotAppSettings_videoStream__1ceiL{
	height:100%;
	-webkit-transform:translateX(-20%);
	        transform:translateX(-20%)
  }

  /* .videoLandscape::after{
	  display: block;
	  content:''; scaleX(-1)
	  padding-top: 130%;
	position: absolute;
	top:0;
  } */

  .PilotAppSettings_whiteLogoWrapper__1e2pL {
	position: absolute;
	width: 50px;
	top: 50px;
	right: 10px;
}

.PilotAppSettings_audioRecognizeContainer__3xzeq {
	width: 15px;
	height: 15px;
	
	top: 50px;
	left: 10px;
	display: flex;
	margin-top:40px;
	margin-left:25px;
	justify-content: space-between;
	align-items: center;
	
}

.PilotAppSettings_audioRecognizeContainer__3xzeq .PilotAppSettings_audioStrength__2YMRP {
	background-color: #56ae4d;
	width: 4px;
	max-height: 10px;
	border-radius: 100%;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}

.PilotAppSettings_audioRecognizeContainer__3xzeq .PilotAppSettings_audioStrengthMax__3LdnK{
	background-color: #56ae4d;
	width: 4px;
	max-height: 20px;
	border-radius: 100%;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
}
.sliderContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 0px;
	width: 100%;
	padding: 0px;
}
.inputR {
	-webkit-appearance: none;
	appearance: none;
	width: 150px;
	height: 6px;
	border-radius: 2px;
	opacity: 1;
	outline: none;
	cursor: pointer;
}

.inputR::-webkit-slider-thumb {
	appearance: none;
	-webkit-appearance: none;
	background: #56ae4d;
	height: 25px;
	width: 25px;
	border-radius: 50%;
}
.cursorointer {
	cursor: pointer;
}

@media (max-width: 576px) {
	.SetPassword_passwordGrid__330gb .SetPassword_topLogoContainer__2gAcd {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.SetPassword_passwordFormContainer__11hvj {
		align-items: flex-start !important;
	}
	.SetPassword_passwordFormContainer__11hvj ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.SetPassword_passwordGrid__330gb .SetPassword_logo__1_3N1 {
		align-self: flex-end;
	}
	.SetPassword_passwordForm__2W6Ft {
		width: 100%;
		max-width: none !important;
		padding: 30px 20px !important;
	}
	.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.SetPassword_blueoceanLogo__1MEAL {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.SetPassword_passwordGrid__330gb .SetPassword_topLogoContainer__2gAcd {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.SetPassword_passwordFormContainer__11hvj {
		align-items: flex-start !important;
	}
	.SetPassword_passwordFormContainer__11hvj ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.SetPassword_passwordGrid__330gb .SetPassword_logo__1_3N1 {
		align-self: flex-end;
	}
	.SetPassword_passwordForm__2W6Ft {
		width: 100%;
		max-width: none !important;
		padding: 30px 20px !important;
	}
	.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.SetPassword_blueoceanLogo__1MEAL {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
.SetPassword_passwordGrid__330gb,
.SetPassword_passwordGridRow__19NOV {
	height: 100%;
}
.SetPassword_passwordGrid__330gb .SetPassword_leftContainer__2IKN7 {
	height: 100%;
	background-color: var(--ion-color-primary);
}
.SetPassword_passwordGrid__330gb .SetPassword_rightContainer__30TOY {
	display: flex;
	flex-direction: column;
}
.SetPassword_passwordFormContainer__11hvj {
	flex-grow: 1;
	align-items: center;
}
.SetPassword_passwordGrid__330gb ion-button {
	display: flex;
	text-transform: none;
}
.SetPassword_passwordGrid__330gb .SetPassword_logo__1_3N1 {
	margin-bottom: 0.5em;
	color: var(--ion-color-primary);
	font-weight: bold;
	font-size: 2.5em;
	text-align: center;

	display: block;
	width: 100%;
	min-height: 108px;
	background-color: white;
	background: var(--robot-logo);
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 0.8em;
}
.SetPassword_passwordGrid__330gb .SetPassword_lbForgotten__23eRb {
	display: block;
	width: 100%;
	color: var(--ion-lb-color-light);
	padding-bottom: 0.5em;
}
.SetPassword_passwordGrid__330gb .SetPassword_lbInstruction__31Cit {
	display: block;
	max-width: 400px;
	color: var(--ion-text-color);
	padding-bottom: 0.5em;
	margin-top: 1.5em;
	text-align: left !important;
}
.SetPassword_passwordForm__2W6Ft {
	padding: 20px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.SetPassword_passwordForm__2W6Ft ion-row {
	padding-top: 1em;
}
.SetPassword_passwordForm__2W6Ft .SetPassword_forgotPswList__swFui {
	width: 100%;
}
.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc {
	padding-top: 3em;
}
.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc ion-col {
	padding-left: 1em;
	margin-right: 1em;
}
.SetPassword_passwordForm__2W6Ft .SetPassword_btnRow__Cd0pc ion-button {
	max-width: 300px;
	margin: 0 auto;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.SetPassword_errorContainer__37TqN {
	color: var(--ion-color-danger);
}
.SetPassword_dividerContainer__ntmfl {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.SetPassword_dividerContainer__ntmfl div:nth-of-type(1) {
	flex-grow: 1;
}
.SetPassword_dividerContainer__ntmfl div:nth-of-type(2) {
	margin-top: 1.15em;
}
.SetPassword_dividerContainer__ntmfl div:nth-of-type(3) {
	flex-grow: 1;
}
.SetPassword_divider__bWL0F {
	width: 95%;
	height: 1px;
	background-color: var(--ion-background-color-divider);
	border-top: var(--ion-border-top-color-divider);
	border-bottom: var(--ion-border-bottom-color-divider);
}
.SetPassword_blueoceanLogo__1MEAL {
	fill: var(--ion-background-color-divider);
	width: 70px;
	height: 50px;
	/* font-size: 70px!important; */
}
.SetPassword_lbOr__3GSod {
	margin-top: 1.5em;
}
.SetPassword_txtLogin__1anHT {
	color: var(--ion-color-primary);
	text-transform: uppercase;
}
.SetPassword_txtLogin__1anHT:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
.SetPassword_goToLoginCol__2oNX5 {
	padding-top: 2.5em;
}
.SetPassword_errorContainer__37TqN {
	color: var(--ion-color-danger);
}
.SetPassword_btnFont__3P17H {
	font-size: 18px;
}
.SetPassword_checkMarkContainerError__Zpq1c {
	bottom: 120px !important;
}
.SetPassword_checkMarkConfirmationContainer__2ga9n {
	position: absolute;
    bottom: 30px;
    right: 64px;
    z-index: 1000;
}
@media (max-width: 576px) {
	.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_topLogoContainer__EIXUY {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.AcceptInvite_passwordFormContainer__1xap2 {
		align-items: flex-start !important;
	}
	.AcceptInvite_passwordFormContainer__1xap2 ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_logo__1yrqT {
		align-self: flex-end;
	}
	.AcceptInvite_passwordForm___dFNd {
		width: 100%;
		max-width: none !important;
		padding: 30px 20px !important;
	}
	.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.AcceptInvite_blueoceanLogo__3lI0p {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_topLogoContainer__EIXUY {
		background-color: var(--ion-color-primary);
		flex-grow: 1;
	}
	.AcceptInvite_passwordFormContainer__1xap2 {
		align-items: flex-start !important;
	}
	.AcceptInvite_passwordFormContainer__1xap2 ion-grid {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 0 !important;
	}
	.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_logo__1yrqT {
		align-self: flex-end;
	}
	.AcceptInvite_passwordForm___dFNd {
		width: 100%;
		max-width: none !important;
		padding: 30px 20px !important;
	}
	.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd {
		padding-top: 1em !important;
		padding-bottom: 2em;
	}
	.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd ion-col {
		padding-left: 0 !important;
		margin-right: 0 !important;
	}
	.AcceptInvite_blueoceanLogo__3lI0p {
		max-width: 55px !important;
		bottom: 20px !important;
	}
}
.AcceptInvite_passwordGrid__1SBMf,
.AcceptInvite_passwordGridRow__1WkTT {
	height: 100%;
}
.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_leftContainer__iZzez {
	height: 100%;
	background-color: var(--ion-color-primary);
	background: var(--login-page-image);
    background-size: cover;
}
.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_rightContainer__2cbBG {
	display: flex;
	flex-direction: column;
}
.AcceptInvite_passwordFormContainer__1xap2 {
	flex-grow: 1;
	align-items: center;
}
.AcceptInvite_passwordGrid__1SBMf ion-button {
	display: flex;
	text-transform: none;
}
.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_logo__1yrqT {
	margin-bottom: 0.5em;
	color: var(--ion-color-primary);
	font-weight: bold;
	font-size: 2.5em;
	text-align: center;

	display: block;
	width: 100%;
	min-height: 108px;
	background-color: white;
	background: var(--robot-logo);
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 0.8em;
}
.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_lbForgotten__4A8p5 {
	display: block;
	width: 100%;
	color: var(--ion-lb-color-light);
	padding-bottom: 0.5em;
}
.AcceptInvite_passwordGrid__1SBMf .AcceptInvite_lbInstruction__1cjUH {
	display: block;
	max-width: 400px;
	color: var(--ion-text-color);
	padding-bottom: 0.5em;
	margin-top: 1.5em;
	text-align: left !important;
}
.AcceptInvite_passwordForm___dFNd {
	padding: 20px;
	max-width: 65%;
	display: block;
	margin: 0 auto;
}
.AcceptInvite_passwordForm___dFNd ion-row {
	padding-top: 1em;
}
.AcceptInvite_passwordForm___dFNd .AcceptInvite_forgotPswList__1SARs {
	width: 100%;
}
.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd {
	padding-top: 3em;
}
.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd ion-col {
	padding-left: 1em;
	margin-right: 1em;
}
.AcceptInvite_passwordForm___dFNd .AcceptInvite_btnRow__3SANd ion-button {
	max-width: 300px;
	margin: 0 auto;
	text-transform: uppercase;
	--background-activated: var(--ion-color-primary);
	--background-activated-opacity: 0.85;
}
.AcceptInvite_errorContainer__2LmsB {
	color: var(--ion-color-danger);
}
.AcceptInvite_dividerContainer__1xqId {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.AcceptInvite_dividerContainer__1xqId div:nth-of-type(1) {
	flex-grow: 1;
}
.AcceptInvite_dividerContainer__1xqId div:nth-of-type(2) {
	margin-top: 1.15em;
}
.AcceptInvite_dividerContainer__1xqId div:nth-of-type(3) {
	flex-grow: 1;
}
.AcceptInvite_divider__3fdhr {
	width: 95%;
	height: 1px;
	background-color: var(--ion-background-color-divider);
	border-top: var(--ion-border-top-color-divider);
	border-bottom: var(--ion-border-bottom-color-divider);
}
.AcceptInvite_blueoceanLogo__3lI0p {
	fill: var(--ion-background-color-divider);
	width: 70px;
	height: 50px;
	/* font-size: 70px!important; */
}
.AcceptInvite_lbOr__D4Ko1 {
	margin-top: 1.5em;
}
.AcceptInvite_txtLogin__yTnR2 {
	color: var(--ion-color-primary);
	text-transform: uppercase;
}
.AcceptInvite_txtLogin__yTnR2:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
.AcceptInvite_goToLoginCol__cUWQi {
	padding-top: 2.5em;
}
.AcceptInvite_checkMarkContainerError__3j4pm {
	bottom: 120px !important;
}
.AcceptInvite_checkMarkConfirmationContainer__3Ey20{
	position: absolute;
    bottom: 32px;
    right: 59px;
    z-index: 1000;
}

.FormikInputItem_formItemContainer__3BKr1 {
	margin: 0 0.5em 0.5em;
}
.FormikInputItem_formItem__j3-3b {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}
.FormikInputItem_formItem__j3-3b:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}
.FormikInputItem_formItem__j3-3b:active {
	--highlight-background: var(--form-field-active-color);
}
.FormikInputItem_formItem__j3-3b ion-label {
	margin-bottom: 10px;
}
.FormikInputItem_formItem__j3-3b ion-label[position='floating'] {
	margin-bottom: 8px;
}
.FormikInputItem_formItem__j3-3b ion-label.FormikInputItem_lbColor__kNIx3 {
	color: var(--ion-text-color) !important;
}
.FormikInputItem_formItem__j3-3b .FormikInputItem_colorFocused__3o1zF,
.FormikInputItem_formItem__j3-3b ion-label.FormikInputItem_colorFocused__3o1zF {
	color: var(--ion-color-primary) !important;
}
.FormikInputItem_formItem__j3-3b .FormikInputItem_colorError__vdc4o,
.FormikInputItem_formItem__j3-3b ion-label.FormikInputItem_colorError__vdc4o {
	color: var(--form-field-error-color) !important;
}
.FormikInputItem_formItem__j3-3b ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}
.FormikInputItem_errorItem__2yeph {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}
.FormikInputItem_errorMsg__32ugO {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}
.FormikInputItem_inputIcon__2KB6T {
	display: inline-flex !important;
	width: 100%;
}
.FormikInputItem_inputIconSize__yJVvA {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: background-color 50000s ease-in-out 0s;
	transition: background-color 50000s ease-in-out 0s;
}

.SetLanguage_languageSelectContainer__31TBg {
    margin: 0 auto;
    padding: 112px 0;
    max-width: 400px;
}
.Agreements_contentList__ow4dP {
	min-width: 100% !important;
	padding: 0 4rem;
}
.Agreements_hintLb__14Nef {
	color: var(--ion-text-color);
}
.Agreements_tabContainer__2vDnT {
	--background: var(--ion-color-primary-contrast);
	margin: 1rem 0 1.5rem 0;
	border-radius: 100px;
	border: 2px solid #dddddd;
}
.Agreements_tabContainer__2vDnT ion-segment-button {
	--color: var(--ion-text-color);
	--color-checked: var(--ion-color-primary);
	--color-hover: var(--ion-text-color);
	--background-hover: var(--ion-main-background-color);
	--background-hover-opacity: 1;
	--indicator-box-shadow: none;
	--border-color: #ddd;
	--padding-bottom: 0.45rem;
	--padding-top: 0.45rem;
	margin: 0;
}
.Agreements_tabContainer__2vDnT ion-segment-button::before {
	margin-top: 0;
	margin-bottom: 0;
	opacity: 1;
}
.Agreements_tabContainerAlone__2TdNE {
	display: none;
}
.Agreements_longerTab__35HGc {
	min-width: 270px;
}
.Agreements_tabContent__iKVf0 {
	width: 100%;
	height: 519px;
	border-radius: 10px;
	border: solid 1px #dddddd;
	padding: 1rem;
	overflow-y: auto;
}
.Agreements_tabContent__iKVf0 ion-item {
	margin-top: 1rem;
	--background-hover: none;
}
.Agreements_descriptionContainer__3D0IS {
	width: 100%;
	white-space: pre-line !important;
}
.Agreements_chkContainer__EjsWJ {
	margin-top: 0 !important;
	--padding-start: 0;
}
.Agreements_chkContainer__EjsWJ ion-label {
	font-size: 28px !important;
	padding-top: 0.15rem;
	padding-left: 0.5rem;
	font-family: Industry-Demi !important;
}
.Agreements_btnContainer__14WyH {
	display: flex;
}
.Agreements_btnContainer__14WyH ion-button {
	max-width: 300px;
	font-size: 15px;
}
.Agreements_btnContainer__14WyH ion-button.Agreements_languageBtn__3fb4r {
	width: 185px;
	margin-left: 3.5rem;
}
.Agreements_btnContainer__14WyH ion-button:last-child {
	width: 250px;
	margin-right: 4.5rem;
}
.Agreements_acceptAllContainer__2uTAb {
	--padding-start: 0;
	--background-hover: none;
	margin-left: 3.5rem;
	margin-bottom: 0rem;
}
.Agreements_acceptAllContainer__2uTAb ion-label {
	padding-top: 0.15rem;
	padding-left: 0.5rem;
}
.Agreements_agreementFrame__2RoBr {
	border: none;
	width: 100%;
	padding: 0px 20px;
}
.Agreements_wrap__LZcJk {
	white-space: normal;
}

.AccountSetup_personIcon__3t6d_ {
	margin-right: 0.5rem;
}
/* Profile settings */
.AccountSetup_formGrid__3_uXF {
	width: 100%;
	padding: 0;
}
.AccountSetup_formGrid__3_uXF ion-list {
	width: 100%;
	padding: 0;
}
.AccountSetup_formItemContainer__135Z0 {
    margin: 0 0 0em 0em;
}
.AccountSetup_formGrid__3_uXF .AccountSetup_firstCol__3V_Cy {
	margin-right: 5rem;
	padding-left: 16px;
}
.AccountSetup_formGrid__3_uXF ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}
.AccountSetup_formGrid__3_uXF ion-list-header {
	margin-top: 1.5rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}
.AccountSetup_msgContainer__1gt68 {
	flex: 1 1;
	margin-top: 1.5rem;
	width: 100%;
	color: var(--ion-color-primary-contrast);
}
.AccountSetup_fullImage__1Y3wH {
	min-width: 200px;
	min-height: 200px;
}
.AccountSetup_userAvatar__9cYpJ {
	margin: auto !important;
	height: 200px;
	width: 200px;
}
.AccountSetup_urlExpand__33Wwc {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.AccountSetup_expandInput__3J50f {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.AccountSetup_iconContainer__2oY3f {
	width: -webkit-max-content;
	width: max-content;
}
.AccountSetup_icon__1YeIU {
	width: 26px;
	height: 26px;
	margin-right: 8px;
}
.AccountSetup_iconExpanded__3V4is {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}
.AccountSetup_hidden__28TAv {
	display: none;
}
.AccountSetup_chkContainer__ozflI {
	margin-top: 0 !important;
	--padding-start: 0;
	padding-left: 1rem;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
}
.AccountSetup_chkContainer__ozflI ion-label {
	font-size: 15px !important;
	padding-top: 0.15rem;
	padding-left: 0.5rem;
}
.AccountSetup_selectContainer__2Ft2P {
	margin: 0 0.5em 0.5em 0em;
}
.AccountSetup_customFormComponentContainer__3sZBH {
	padding-top: 0.8em;
	width: 100%;
}
.AccountSetup_profileListHeader__29O6T {
	--background: transparent !important;
}
.AccountSetup_profileHeader__2NV-Q {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}
.AccountSetup_linkContainer__1pQZ_ {
	display: flex;
	margin-top: 3rem;
}
.AccountSetup_linkContainer__1pQZ_ ion-icon {
	color: var(--ion-color-primary);
}
.AccountSetup_link__f5jUA {
	color: var(--ion-color-primary);
}
.AccountSetup_link__f5jUA:hover,
.AccountSetup_linkContainer__1pQZ_ ion-icon:hover {
	color: var(--ion-color-primary-shade);
	cursor: pointer;
}
/* Notifications settings */
.AccountSetup_notificationsGrid__2nSwI {
	margin: 0;
	--ion-grid-columns: 2;
	--ion-grid-padding: 0;
	--ion-grid-width: 100%;
}
.AccountSetup_notificationsGrid__2nSwI ion-col {
	--ion-grid-column-padding: 0;
}
.AccountSetup_notificationsGrid__2nSwI hr {
	margin: 4px 12px;
	border-top: 1px solid var(--ion-main-background-color);
}
.AccountSetup_notificationsGrid__2nSwI ion-label {
	padding-left: 16px;
}
.AccountSetup_notificationsGridHeader__2KOb8 {
	border-radius: 5px;
	background-color: var(--ion-main-background-color);
}
.AccountSetup_notificationsGridHeader__2KOb8 ion-col {
	display: flex;
	align-items: center;
	height: 45px;
	padding-left: 16px;
}
.AccountSetup_notificationsGridHeader__2KOb8 ion-label {
	text-transform: uppercase;
	font-family: 'Industry-Demi';
	font-size: 15px;
	font-weight: 500;
}
.AccountSetup_checkMarkContainer__3AVWT {
	right: 40px;
}

.AccountSetup_checkMarkContainerError__1E5j2 {
	bottom: 105px !important;
}

.AccountSetup_passwordTooltip__36gVt {
	bottom: 20px !important;
}
.AccountSetup_detailHeader__J5OB- {
    font-family: 'Industry-Demi';
}
.AccountSetup_formItem__34GuK {
    margin-left: 0rem;
}
.AccountSetup_checkMarkConfirmationContainer__2RmtC{
	position: absolute;
    bottom: 10px;
    right: 40px;
    z-index: 1000;
}
.OrganizationSetup_icon__2MTdp {
    margin-right: 0.5rem;
}
/* Organization details */
.OrganizationSetup_formGrid__29NvH {
    width: 100%;
    padding: 0;
    margin-bottom: 3.5rem;
}
.OrganizationSetup_formGrid__29NvH ion-list {
    width: 100%;
    padding: 0;
}
.OrganizationSetup_formItemContainer__1zqjj {
    margin: 0 0 0em 0em;
}
.OrganizationSetup_formGrid__29NvH .OrganizationSetup_firstCol__BliXi {
    margin-right: 5rem;
    padding-left: 16px;
}
.OrganizationSetup_firstCol__BliXi, .OrganizationSetup_secondCol__hZmNE {
    padding-left: 16px;
}
.OrganizationSetup_formGrid__29NvH ion-item {
    opacity: 1;
    --border-color: var(--ion-main-background-color);
}
.OrganizationSetup_formGrid__29NvH ion-list-header {
    margin-top: 1.5rem;
    --background: var(--ion-main-background-color);
    font-family: 'Industry';
    text-transform: uppercase;
    border-radius: 5px;
}
.OrganizationSetup_detailHeader__3O7lF {
    font-family: 'Industry-Demi';
}
.OrganizationSetup_selectContainer__2DDOS {
	margin: 0 0.5em 0.5em 0em;
}
.OrganizationSetup_customFormComponentContainer__yR06O {
	padding-top: 0.8em;
	width: 100%;
}
.OrganizationSetup_msgContainer__1xR_l {
    flex: 1 1;
    margin-top: 1.5rem;
    width: 60%;
    color: var(--ion-color-primary-contrast);
}
.OrganizationSetup_fullImage__2LSZu {
	min-width: 200px;
	min-height: 200px;
}
.OrganizationSetup_userAvatar__17L98 {
	margin: auto !important;
	height: 200px;
    width: 200px;
}
.OrganizationSetup_urlExpand__2cSbx {
	width: 100%;
	display: inline-flex;
	align-items: center;
}
.OrganizationSetup_expandInput__2lWuo {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}
.OrganizationSetup_iconContainer__2NXYD {
	width: -webkit-max-content;
	width: max-content;
}
.OrganizationSetup_icon__2MTdp {
    width: 13.8px;
    height: 14.8px;
	margin-right: 8px;
}
.OrganizationSetup_iconExpanded__3ua66 {
    color: var(--ion-color-gray-shade);
    width: 26px;
	height: 26px;
    margin: 14px 7px 10px 16px;
}
.OrganizationSetup_hidden__2ozlF {
	display: none;
}
.OrganizationSetup_createdAt__1clqK {
    --color: var(--ion-color-gray)!important;
    margin: 32px 0px;
}

.OrganizationSetup_profileListHeader__1LIuX {
    --background: transparent!important;
}
.OrganizationSetup_profileHeader__3qG2N {
    font-family: 'Industry'!important;
    font-size: 28px!important;
    display: flex;
    justify-content: center;
    width: 100%;
}
/* Billing and shipping*/
.OrganizationSetup_billingShippingGrid__2F3LV {
    margin: 0;
}
.OrganizationSetup_billingShippingGrid__2F3LV ion-row.OrganizationSetup_header__3RsSt {
    margin-top: 1.5rem;
    background: var(--ion-main-background-color);
    font-family: 'Industry';
    text-transform: uppercase;
    border-radius: 5px;
}
.OrganizationSetup_billingShippingGrid__2F3LV ion-row.OrganizationSetup_header__3RsSt ion-col {
    padding: 0;
}
.OrganizationSetup_billingShippingGrid__2F3LV ion-row.OrganizationSetup_header__3RsSt ion-item {
    --background: transparent;
}
.OrganizationSetup_billingShippingGrid__2F3LV ion-row.OrganizationSetup_header__3RsSt ion-label {
    font-family: 'Industry-Demi';
    text-transform: uppercase;
}
.OrganizationSetup_billingShippingGrid__2F3LV .OrganizationSetup_chkContainer__2yZXX .OrganizationSetup_detailHeader__3O7lF {
    display: contents;
}
.OrganizationSetup_sameAsLb__26n9l {
    color: var(--ion-color-gray-shade)!important;
    text-transform: none!important;
    padding-left: 0.5rem;
    font-family: 'Roboto'!important;
    font-size: 13px!important;
}
.OrganizationSetup_sameAsChk__dRXp- {
    margin-left: 0.5rem;
}
.OrganizationSetup_billingList__Jr44h {
    margin-right: 2rem;
}
.OrganizationSetup_zipCityContainer__3-RTG {
    width: 100%;
    display: flex;
}
.OrganizationSetup_zipCityContainer__3-RTG ion-item:nth-child(1) {
    width: 100px;
    margin-right: 16px;
}
.OrganizationSetup_zipCityContainer__3-RTG ion-item:nth-child(2) {
    flex-grow: 1;
}

.OrganizationSetup_formItemContainer__1zqjj {
    margin: 0 0.5em 0.5em;
}
.OrganizationSetup_formItem__3FXXw {
    margin-bottom:12.5px;
    --highlight-color-focused: var(--ion-color-gray-shade);
    --highlight-color-invalid: var(--form-field-error-color);
    --highlight-height: 1px;
    --padding-start: 0;
    --padding-end: 0;
}
.OrganizationSetup_formItem__3FXXw:hover {
    --border-color: var(--ion-color-gray-shade);
    --border-width: 0 0 1px 0;
}
.OrganizationSetup_formItem__3FXXw:active {    
    --highlight-background: var(--form-field-active-color);
}
.OrganizationSetup_formItem__3FXXw ion-label {
    margin-bottom: 20px;
    margin-top:10px
}
.OrganizationSetup_formItem__3FXXw ion-label.OrganizationSetup_lbColor__in6dq {
    color: var(--ion-text-color)!important;
}
.OrganizationSetup_formItem__3FXXw .OrganizationSetup_colorFocused__1A9jM, .OrganizationSetup_formItem__3FXXw ion-label.OrganizationSetup_colorFocused__1A9jM {
    color: var(--ion-color-primary)!important;
}
.OrganizationSetup_formItem__3FXXw .OrganizationSetup_colorError__3mjFp, .OrganizationSetup_formItem__3FXXw ion-label.OrganizationSetup_colorError__3mjFp {
    color:red!important;
}
.OrganizationSetup_formItem__3FXXw ion-text {
    color: var(--ion-color-primary);
    padding-left: 3px;
}
.OrganizationSetup_required__SGeL8{
    color: var(--ion-color-primary);

}
.OrganizationSetup_countrySelectContainer__I4ll5 {
	width: 100%;
	display: block;
	padding: 0;
}
.OrganizationSetup_detailHeaderItem__1wucZ {
    display: flex;
    height: 100%;
}
.OrganizationSetup_formItem__3FXXw {
    margin-left: 0rem;
}
.RobotsSetup_icon__2sH7M {
	margin-right: 0.5rem;
}

/* Organization details */
.RobotsSetup_formGrid__3k8J6 {
	width: 100%;
	padding: 0;
	margin-bottom: 3.5rem;
}

.RobotsSetup_formGrid__3k8J6 ion-list {
	width: 100%;
	padding: 0;
}

.RobotsSetup_formGrid__3k8J6 .RobotsSetup_firstCol__1tIuw {
	margin-right: 5rem;
}

.RobotsSetup_formGrid__3k8J6 ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}

.RobotsSetup_formGrid__3k8J6 ion-list-header {
	margin-top: 1.063rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.RobotsSetup_detailHeader__1Yd9s {
	font-family: 'Industry-Demi';
}

.RobotsSetup_selectContainer__KTGs2 {
	margin: 0 0.5em 0.5em 0.5em;
}

.RobotsSetup_msgContainer__Ag12d {
	flex: 1 1;
	margin-top: 1.5rem;
	width: 60%;
	color: var(--ion-color-primary-contrast);
}

.RobotsSetup_fullImage__2pJ9R {
	border-radius: 0;
	width: 120px;
	height: 120px;
	object-fit: contain !important;
	margin: auto
}

.RobotsSetup_avatarClass__2LdMd {
	display: flex;
	background-color: var(--ion-text-color);
	border-radius: 0px;
	height: 200px;
	width: 185px;
	margin: auto;
}

.RobotsSetup_urlExpand__1eRDy {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.RobotsSetup_expandInput__20N6g {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}

.RobotsSetup_iconContainer__-2tmC {
	width: -webkit-max-content;
	width: max-content;
}

.RobotsSetup_icon__2sH7M {
	width: 13.8px;
	height: 14.8px;
	margin-right: 8px;
}

.RobotsSetup_iconExpanded__3L4C4 {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}

.RobotsSetup_hidden__cODGe {
	display: none;
}

.RobotsSetup_createdOnLb__z0REb {
	--color: var(--ion-color-gray) !important;
	margin-top: 2rem;
}

.RobotsSetup_profileListHeader__6UrX5 {
	--background: transparent !important;
}

.RobotsSetup_profileHeader__NHkEH {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}

/* Billing and shipping*/
.RobotsSetup_billingShippingGrid__-euho {
	margin: 0;
}

.RobotsSetup_billingShippingGrid__-euho ion-row.RobotsSetup_header__2VFTZ {
	margin-top: 1.063rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.RobotsSetup_billingShippingGrid__-euho ion-row.RobotsSetup_header__2VFTZ ion-col {
	padding: 0;
}

.RobotsSetup_billingShippingGrid__-euho ion-row.RobotsSetup_header__2VFTZ ion-item {
	--background: transparent;
}

.RobotsSetup_billingShippingGrid__-euho ion-row.RobotsSetup_header__2VFTZ ion-label {
	font-family: 'Industry-Demi';
	text-transform: uppercase;
}

.RobotsSetup_billingShippingGrid__-euho .RobotsSetup_chkContainer__2egu- .RobotsSetup_detailHeader__1Yd9s {
	display: contents;
}

.RobotsSetup_sameAsLb__2IDc6 {
	color: var(--ion-color-gray-shade) !important;
	text-transform: none !important;
	padding-left: 0.5rem;
	font-family: 'Roboto' !important;
	font-size: 13px !important;
}

.RobotsSetup_sameAsChk__233jL {
	margin-left: 0.5rem;
}

.RobotsSetup_billingList__lGHJt {
	margin-right: 2rem;
}

.RobotsSetup_zipCityContainer__25ah- {
	display: flex;
}

.RobotsSetup_zipCityContainer__25ah- div {
	width: 100%;
}

.RobotsSetup_formItemContainer__2DFai {
	margin: 0 0.5em 0.5em;
}

.RobotsSetup_formItem__2lXjF {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.RobotsSetup_formItem__2lXjF:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.RobotsSetup_formItem__2lXjF:active {
	--highlight-background: var(--form-field-active-color);
}

.RobotsSetup_formItem__2lXjF ion-label {
	margin-bottom: 10px;
}

.RobotsSetup_formItem__2lXjF ion-label.RobotsSetup_lbColor__2z4AE {
	color: var(--ion-text-color) !important;
}

.RobotsSetup_formItem__2lXjF .RobotsSetup_colorFocused__1f-4t,
.RobotsSetup_formItem__2lXjF ion-label.RobotsSetup_colorFocused__1f-4t {
	color: var(--ion-color-primary) !important;
}

.RobotsSetup_formItem__2lXjF .RobotsSetup_hasFocus__uKCGt,
.RobotsSetup_formItem__2lXjF ion-label.RobotsSetup_hasFocus__uKCGt {
	color: var(--ion-color-primary) !important;
}

.RobotsSetup_formItem__2lXjF ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.RobotsSetup_errorItem__2U-UC {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}

.RobotsSetup_errorMsg__3zhzW {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}

.RobotsSetup_inputIcon__1wFiv {
	display: inline-flex !important;
	width: 100%;
}

.RobotsSetup_inputIconSize__1pT49 {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

.RobotsSetup_robotTimeZoneItem__2z1JL{
	margin-top:5px
}

.RobotsSetup_robotTimeZoneSelect__2BqTQ{
	margin-top:5px;
	width:100%
}

.RobotsSetup_robotSetupForm__3NE2H{
	padding:1px 0 0 20px
}

.UserGroupSetup_icon__1Q2cd {
	margin-right: 0.5rem;
}

/* Organization details */
.UserGroupSetup_formGrid__13fpP {
	width: 100%;
	padding: 0;
	margin-bottom: 3.5rem;
}

.UserGroupSetup_formGrid__13fpP ion-list {
	width: 100%;
	padding: 0;
}

.UserGroupSetup_formGrid__13fpP .UserGroupSetup_firstCol__2yd-U {
	margin-right: 5rem;
	padding-left: 16px;
}

.UserGroupSetup_formGrid__13fpP ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}

.UserGroupSetup_formGrid__13fpP ion-list-header {
	margin-top: 1.5rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.UserGroupSetup_detailHeader__ofFq_ {
	font-family: 'Industry-Demi';
}

.UserGroupSetup_selectContainer__vWcOp {
	margin: 0 0.5em 0.5em 0.5em;
}

.UserGroupSetup_msgContainer__i1Mz1 {
	flex: 1 1;
	margin-top: 1.5rem;
	width: 60%;
	color: var(--ion-color-primary-contrast);
}

.UserGroupSetup_fullImage__21c2i {
	border-radius: 0;
	width: 120px;
	height: 120px;
	object-fit: contain !important;
	margin: auto
}

.UserGroupSetup_avatarClass__1cy0t {
	display: flex;
	background-color: var(--ion-text-color);
	border-radius: 0px;
	height: 200px;
	width: 185px;
	margin: auto;
}

.UserGroupSetup_urlExpand__2pGpz {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.UserGroupSetup_expandInput__3RDcR {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}

.UserGroupSetup_iconContainer__v72jt {
	width: -webkit-max-content;
	width: max-content;
}

.UserGroupSetup_icon__1Q2cd {
	width: 15.8px;
	height: 19.8px;
	margin-right: 8px;
}

.UserGroupSetup_iconExpanded__1W5Wk {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}

.UserGroupSetup_hidden__2PllF {
	display: none;
}

.UserGroupSetup_createdOnLb__1XE4b {
	--color: var(--ion-color-gray) !important;
	margin-top: 2rem;
}

.UserGroupSetup_profileListHeader__10ozK {
	--background: transparent !important;
}

.UserGroupSetup_profileHeader__1u7Pr {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}

/* Billing and shipping*/
.UserGroupSetup_billingShippingGrid__3rUZ9 {
	margin: 0;
}

.UserGroupSetup_billingShippingGrid__3rUZ9 ion-row.UserGroupSetup_header__3KppS {
	margin-top: 1.5rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.UserGroupSetup_billingShippingGrid__3rUZ9 ion-row.UserGroupSetup_header__3KppS ion-col {
	padding: 0;
}

.UserGroupSetup_billingShippingGrid__3rUZ9 ion-row.UserGroupSetup_header__3KppS ion-item {
	--background: transparent;
}

.UserGroupSetup_billingShippingGrid__3rUZ9 ion-row.UserGroupSetup_header__3KppS ion-label {
	font-family: 'Industry-Demi';
	text-transform: uppercase;
}

.UserGroupSetup_billingShippingGrid__3rUZ9 .UserGroupSetup_chkContainer__WdF8s .UserGroupSetup_detailHeader__ofFq_ {
	display: contents;
}

.UserGroupSetup_sameAsLb___u8UV {
	color: var(--ion-color-gray-shade) !important;
	text-transform: none !important;
	padding-left: 0.5rem;
	font-family: 'Roboto' !important;
	font-size: 13px !important;
}

.UserGroupSetup_sameAsChk__2YFjL {
	margin-left: 0.5rem;
}

.UserGroupSetup_billingList__18EOO {
	margin-right: 2rem;
}

.UserGroupSetup_zipCityContainer__R3nuc {
	display: flex;
}

.UserGroupSetup_zipCityContainer__R3nuc div {
	width: 100%;
}

.UserGroupSetup_formItemContainer__1mVsI {
	margin: 0 0.5em 0.5em;
}

.UserGroupSetup_formItem__3q8A4 {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.UserGroupSetup_formItem__3q8A4:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.UserGroupSetup_formItem__3q8A4:active {
	--highlight-background: var(--form-field-active-color);
}

.UserGroupSetup_formItem__3q8A4 ion-label {
	margin-bottom: 10px;
}

.UserGroupSetup_formItem__3q8A4 ion-label.UserGroupSetup_lbColor__2Qz5e {
	color: var(--ion-text-color) !important;
}

.UserGroupSetup_formItem__3q8A4 .UserGroupSetup_colorFocused__1EbxP,
.UserGroupSetup_formItem__3q8A4 ion-label.UserGroupSetup_colorFocused__1EbxP {
	color: var(--ion-color-primary) !important;
}

.UserGroupSetup_formItem__3q8A4 .UserGroupSetup_hasFocus__1lRDl,
.UserGroupSetup_formItem__3q8A4 ion-label.UserGroupSetup_hasFocus__1lRDl {
	color: var(--ion-color-primary) !important;
}

.UserGroupSetup_formItem__3q8A4 ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.UserGroupSetup_errorItem__FANGY {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}

.UserGroupSetup_errorMsg__oZRje {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}

.UserGroupSetup_inputIcon__EYuXO {
	display: inline-flex !important;
	width: 100%;
}

.UserGroupSetup_inputIconSize__3Nhye {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

.UserGroupSetup_robotTimeZoneItem__pds9w{
	margin-top:5px
}

.UserGroupSetup_robotTimeZoneSelect__3zAvm{
	margin-top:5px;
	width:100%
}

.UserGroupSetup_robotSetupForm__3NGFL{
	padding:1px 0 0 20px
}

.UserGroupSetup_inGroupLabel__3gMkO{
	display: block;
	margin-bottom: 20px;
	font-size:14px;
}

.UserGroupSetup_hasNoRobot__1NOs0{
	margin-top:10px;
	color:#a5a5a6;
	
}

.UserGroupSetup_createBtn__3FZEA{
	width:138px;
	height:53px;
	text-transform: initial;
	height: 45px;
	width: 150px;
}

.UserGroupSetup_initialText__3_dsb{
	text-transform: initial;

}
.UserGroupSetup_spanContainer__3D6XG{
	min-height:20px;
}

.UserGroupSetup_linkContainer__3_LRq {
    display: flex;
	margin-top: 3rem;
	padding-left: 16px;
}
.UserGroupSetup_linkContainer__3_LRq ion-icon {
    color: var(--ion-color-primary);
}
.UserGroupSetup_link__J2CTw {
    color: var(--ion-color-primary);
}
.UserGroupSetup_link__J2CTw:hover, .UserGroupSetup_linkContainer__3_LRq ion-icon:hover {
    color: var(--ion-color-primary-shade);
    cursor: pointer;
}

.UserGroupSetup_IonChip__4-m-y{
	background-color:#eeeeee ;
	color:#1e1f22
}

.UserGroupSetup_close__2hDro{
	background-color: #a5a5a6;
	color:#eeeeee;
	border-radius: 50%;
	
}

.UserGroupSetup_close__2hDro:hover{
	background:#1e1f22
}

.UserGroupSetup_userAvatar__2ggua {
	margin: auto !important;
	height: 200px;
    width: 200px;
}
.UserGroupSetup_fullImage__21c2i {
	min-width: 200px;
	min-height: 200px;
}
.UserGroupSetup_usersRow__rUb6T{
	max-height: 120px;
    overflow: auto;
}

.UserGroupSetup_groupNameItem__3oZvw {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.UserGroupSetup_groupNameItem__3oZvw li {
	margin-top: 0.25rem;
}
.UserGroupSetup_andLb__1kiRp {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}

.UserGroupSetup_moreInLb__2WyZi:hover {
	text-decoration: underline;
	cursor: pointer;
}

.UserGroupSetup_tooltipIonChip__3zWvG{
	margin-left:30px
}

.UserGroupSetup_IonChipLabel__2rajV{
	margin-right:20px
}
.UserGroupSetup_invitedUser__3V1OL {
	margin-top:20px;
	display: flex;
	margin: 1rem 0.3rem 1rem 0;
	color: var(--ion-color-gray-shade);
}

.UserGroupSetup_invitedUser__3V1OL ion-icon {
	margin-right: 0.5rem;
}

.UserGroupSetup_invitedUser__3V1OL span {
	text-transform: capitalize;
	margin-right: 0.3rem;
	display: contents;
}

.UserGroupSetup_addIcon__3C4vK{
	font-size: 1.5rem;
	margin-right: 10px;
}

.UserGroupSetup_IonChipLabel__2rajV{
	margin-right:20px
}

.OrganizationSetupSubMenu_list__2K8Xu {
    padding: 0;
    background-color: transparent;
    overflow: auto;
    height: 100%;
    max-height: 560px;
}

.OrganizationSetupSubMenu_list__2K8Xu ion-item {
    --background: transparent;
    --background-hover: #dddddd;
    --background-hover-opacity: 1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    /* height: 50px;
    width: 200px; */
}

.OrganizationSetupSubMenu_list__2K8Xu ion-item:hover {
    cursor: pointer;
}

.OrganizationSetupSubMenu_selectedItem__3sqrI {
    --background: var(--ion-color-primary-contrast) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}

.OrganizationSetup_mainTitle__1-k4Z {
    font-family: 'Industry';
    font-size: 28px !important;
}

.OrganizationSetup_tabContentGrid__4FmzE {
    padding: 0;

}

.OrganizationSetup_setupTabBtn__21Y0T {
    --color-checked: var(--ion-color-primary);
    --indicator-color: transparent;
    --indicator-box-shadow: none;
    padding: 0.25rem 0;
}

.OrganizationSetup_contentGrid__1ra2r {
    padding: 0;
}

.OrganizationSetup_leftSide__3Jedb {
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin-right: 1rem;
    padding-left: 0;
}

.OrganizationSetup_rightSide__2CNC6 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 1rem 2.5rem;
    height: 560px;
}

.OrganizationSetup_triangle__3Et1k {
    right: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: var(--ion-color-primary-contrast);
    border-width: 14px;
    margin-top: -14px;
}

.OrganizationSetup_hide__3I7kf {
    display: none;

}

.OrganizationSetup_list__1xlS9 {
    padding: 0;
    background-color: transparent;
    overflow: auto;
    height: 100%;
    max-height: 560px;
}

.OrganizationSetup_list__1xlS9 ion-item {
    --background: transparent;
    --background-hover: #dddddd;
    --background-hover-opacity: 1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    /* height: 50px;
    width: 200px; */
}

.OrganizationSetup_list__1xlS9 ion-item:hover {
    cursor: pointer;
}

.OrganizationSetup_selectedItem__pFBWO {
    --background: var(--ion-color-primary-contrast) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}

.OrganizationSetup_leftCol__1MVWc {
    max-width: 22.5rem;
}

.OrganizationSetup_btnCol__3auT4 {
    display: flex;
    justify-content: flex-end;
    padding-right:0px;
}

.OrganizationSetup_btn__1YTpI {
    height: 50px;
    --border-width: 1px;
    --border-style: solid;
    --background: transparent;
    --background-hover-opacity: 1;
    --box-shadow: none;
    --transition: 0.5s;
}

.OrganizationSetup_backBtn__3_fJ2 {
    max-width: 9.5rem;
    --color: var(--ion-text-color);
    --color-hover: var(--ion-color-primary-contrast);
    --border-color: var(--ion-text-color);
    --background-hover: var(--ion-text-color);
}

.OrganizationSetup_backBtn__3_fJ2 ion-icon {
    padding-bottom: 0.1rem;
}

.OrganizationSetup_nextBtn__2UDuV {
    width: 11.563rem;
    --color: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    --background: var(--ion-color-primary);
    --background-hover: var(--ion-main-background-color);
}

.OrganizationSetup_buttonsColRight__Rj85h {
    padding-right:0px;
}
.RobotGroupSetup_icon__1yjv0 {
	margin-right: 0.5rem;
}

/* Organization details */
.RobotGroupSetup_formGrid__3k3XA {
	width: 100%;
	padding: 0;
	margin-bottom: 3.5rem;
}

.RobotGroupSetup_formGrid__3k3XA ion-list {
	width: 100%;
	padding: 0;
}

.RobotGroupSetup_formGrid__3k3XA .RobotGroupSetup_firstCol__26yk- {
	margin-right: 5rem;
	padding-left: 16px;
}

.RobotGroupSetup_formGrid__3k3XA ion-item {
	opacity: 1;
	--border-color: var(--ion-main-background-color);
}

.RobotGroupSetup_formGrid__3k3XA ion-list-header {
	margin-top: 1.5rem;
	--background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.RobotGroupSetup_detailHeader__vZYRN {
	font-family: 'Industry-Demi';
}

.RobotGroupSetup_selectContainer__3q2pF {
	margin: 0 0.5em 0.5em 0.5em;
}

.RobotGroupSetup_msgContainer__2UeTh {
	flex: 1 1;
	margin-top: 1.5rem;
	width: 100%;
	color: var(--ion-color-primary-contrast);
}

.RobotGroupSetup_fullImage__25Yu0 {
	border-radius: 0;
	width: 120px;
	height: 120px;
	object-fit: contain !important;
	margin: auto
}

.RobotGroupSetup_avatarClass__1Zjxy {
	display: flex;
	background-color: var(--ion-text-color);
	border-radius: 0px;
	height: 200px;
	width: 185px;
	margin: auto;
}

.RobotGroupSetup_urlExpand__1Psso {
	width: 100%;
	display: inline-flex;
	align-items: center;
}

.RobotGroupSetup_expandInput__3V7kv {
	width: 250px !important;
	font-family: Roboto;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: var(--ion-color-gray-shade);
}

.RobotGroupSetup_iconContainer__1qSM- {
	width: -webkit-max-content;
	width: max-content;
}

.RobotGroupSetup_icon__1yjv0 {
	width: 15.8px;
	height: 19.8px;
	margin-right: 8px;
}

.RobotGroupSetup_iconExpanded__Zdpxy {
	color: var(--ion-color-gray-shade);
	width: 26px;
	height: 26px;
	margin: 14px 7px 10px 16px;
}

.RobotGroupSetup_hidden__Gplca {
	display: none;
}

.RobotGroupSetup_createdOnLb__3btN3 {
	--color: var(--ion-color-gray) !important;
	margin-top: 2rem;
}

.RobotGroupSetup_profileListHeader__9yHcM {
	--background: transparent !important;
}

.RobotGroupSetup_profileHeader__2G2Gn {
	font-family: 'Industry' !important;
	font-size: 28px !important;
	display: flex;
	justify-content: center;
	width: 100%;
}

/* Billing and shipping*/
.RobotGroupSetup_billingShippingGrid__RFaGk {
	margin: 0;
}

.RobotGroupSetup_billingShippingGrid__RFaGk ion-row.RobotGroupSetup_header__2xnpf {
	margin-top: 1.5rem;
	background: var(--ion-main-background-color);
	font-family: 'Industry';
	text-transform: uppercase;
	border-radius: 5px;
}

.RobotGroupSetup_billingShippingGrid__RFaGk ion-row.RobotGroupSetup_header__2xnpf ion-col {
	padding: 0;
}

.RobotGroupSetup_billingShippingGrid__RFaGk ion-row.RobotGroupSetup_header__2xnpf ion-item {
	--background: transparent;
}

.RobotGroupSetup_billingShippingGrid__RFaGk ion-row.RobotGroupSetup_header__2xnpf ion-label {
	font-family: 'Industry-Demi';
	text-transform: uppercase;
}

.RobotGroupSetup_billingShippingGrid__RFaGk .RobotGroupSetup_chkContainer__cT94j .RobotGroupSetup_detailHeader__vZYRN {
	display: contents;
}

.RobotGroupSetup_sameAsLb__2KFhs {
	color: var(--ion-color-gray-shade) !important;
	text-transform: none !important;
	padding-left: 0.5rem;
	font-family: 'Roboto' !important;
	font-size: 13px !important;
}

.RobotGroupSetup_sameAsChk__14_iq {
	margin-left: 0.5rem;
}

.RobotGroupSetup_billingList__2r2hp {
	margin-right: 2rem;
}

.RobotGroupSetup_zipCityContainer__tEgFF {
	display: flex;
}

.RobotGroupSetup_zipCityContainer__tEgFF div {
	width: 100%;
}

.RobotGroupSetup_formItemContainer__dOjsI {
	margin: 0 0.5em 0.5em;
}

.RobotGroupSetup_formItem__3P4oj {
	--highlight-color-focused: var(--ion-color-gray-shade);
	--highlight-color-invalid: var(--form-field-error-color);
	--highlight-height: 1px;
	--padding-start: 0;
	--padding-end: 0;
}

.RobotGroupSetup_formItem__3P4oj:hover {
	--border-color: var(--ion-color-gray-shade);
	--border-width: 0 0 1px 0;
}

.RobotGroupSetup_formItem__3P4oj:active {
	--highlight-background: var(--form-field-active-color);
}

.RobotGroupSetup_formItem__3P4oj ion-label {
	margin-bottom: 10px;
}

.RobotGroupSetup_formItem__3P4oj ion-label.RobotGroupSetup_lbColor__2n-5Y {
	color: var(--ion-text-color) !important;
}

.RobotGroupSetup_formItem__3P4oj .RobotGroupSetup_colorFocused__3OhGA,
.RobotGroupSetup_formItem__3P4oj ion-label.RobotGroupSetup_colorFocused__3OhGA {
	color: var(--ion-color-primary) !important;
}

.RobotGroupSetup_formItem__3P4oj .RobotGroupSetup_hasFocus__1n4Pq,
.RobotGroupSetup_formItem__3P4oj ion-label.RobotGroupSetup_hasFocus__1n4Pq {
	color: var(--ion-color-primary) !important;
}

.RobotGroupSetup_formItem__3P4oj ion-text {
	color: var(--ion-color-primary);
	padding-left: 3px;
}

.RobotGroupSetup_errorItem__3orzy {
	--border-color: var(--form-field-error-color) !important;
	--highlight-background: var(--form-field-error-color) !important;
}

.RobotGroupSetup_errorMsg__2eS5L {
	color: var(--form-field-error-color);
	font-size: 0.8em;
	width: 100%;
	display: block;
	padding-top: 5px;
}

.RobotGroupSetup_inputIcon__2zuXP {
	display: inline-flex !important;
	width: 100%;
}

.RobotGroupSetup_inputIconSize__2xeXy {
	height: 20px;
	width: 20px;
	margin: auto 0;
}

.RobotGroupSetup_robotTimeZoneItem__dE2pt{
	margin-top:5px
}

.RobotGroupSetup_robotTimeZoneSelect__3W3xo{
	margin-top:5px;
	width:100%
}

.RobotGroupSetup_inGroupLabel__2QlSx{
	
	font-size:14px;
}

.RobotGroupSetup_hasNoRobot__WpgAo{
	margin-top:10px;
	color:#a5a5a6;
	
}

.RobotGroupSetup_createBtn__3pSVG{
	width:138px;
	height:53px;
	text-transform: initial;
	height: 45px;
	width: 150px;
}

.RobotGroupSetup_initialText__QkyZF{
	text-transform: initial;

}
.RobotGroupSetup_spanContainer__3nOJJ{
	min-height:20px;
}

.RobotGroupSetup_linkContainer__2iPSx {
    display: flex;
	margin-top: 3rem;
	margin-left: 16px
}
.RobotGroupSetup_linkContainer__2iPSx ion-icon {
    color: var(--ion-color-primary);
}
.RobotGroupSetup_link__3_Wpa {
    color: var(--ion-color-primary);
}
.RobotGroupSetup_link__3_Wpa:hover, .RobotGroupSetup_linkContainer__2iPSx ion-icon:hover {
    color: var(--ion-color-primary-shade);
    cursor: pointer;
}

.RobotGroupSetup_IonChip__T8BWD{
	background-color:#eeeeee ;
	color:#1e1f22
}

.RobotGroupSetup_close__14zpn{
	background-color: #a5a5a6;
	color:#eeeeee;
	border-radius: 50%;
	
}

.RobotGroupSetup_close__14zpn:hover{
	background:#1e1f22
}



.RobotGroupSetup_devicesRow__fEfZc{
	max-height: 120px;
    overflow: auto;
}

.RobotGroupSetup_groupNameItem__22Eb4 {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: block;
}
.RobotGroupSetup_groupNameItem__22Eb4 li {
	margin-top: 0.25rem;
}
.RobotGroupSetup_andLb__QvKon {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
}

.RobotGroupSetup_moreInLb__2RF6T:hover {
	text-decoration: underline;
	cursor: pointer;
}

.RobotGroupSetup_tooltipIonChip__3fde6{
	margin-left:30px
}

.RobotGroupSetup_IonChipLabel__1Ic_C{
	margin-right:20px
}
.RobotGroupSetup_addIcon__1dfZ2{
	font-size: 1.5rem;
	margin-right: 10px;
}
.AccountSetup_mainTitle__TsMOB {
    font-family: 'Industry';
    font-size: 28px !important;
}

.AccountSetup_tabContentGrid__3ubQv {
    padding: 0;
}

.AccountSetup_setupTabBtn__1jWWl {
    --indicator-color: transparent;
    --indicator-box-shadow: none;
    padding: 0.25rem 0;
}

.AccountSetup_contentGrid__fqnei {
    padding: 0;
}

.AccountSetup_leftSide__1JU9e {
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin-right: 1rem;
    padding-left: 0;
}

.AccountSetup_rightSide__M_rkv {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--ion-color-primary-contrast);
    position: relative;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    padding: 1.063rem 2.5rem;
    height: 560px;
}

.AccountSetup_triangle__1mzFr {
    right: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: var(--ion-color-primary-contrast);
    border-width: 14px;
    margin-top: -14px;
}

.AccountSetup_hide__3xNnO {
    display: none;

}

.AccountSetup_list__5Q51F {
    padding: 0;
    background-color: transparent;
    overflow: auto;
    height: 100%;
    max-height: 560px;
}

.AccountSetup_list__5Q51F ion-item {
    --background: transparent;
    --background-hover: #dddddd;
    --background-hover-opacity: 1;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    /* height: 50px;
    width: 200px; */
}

.AccountSetup_list__5Q51F ion-item:hover {
    cursor: pointer;
}

.AccountSetup_selectedItem__2sQXg {
    --background: var(--ion-color-primary-contrast) !important;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
}

.AccountSetup_leftCol__LMYqB {
    max-width: 22.5rem;
}

.AccountSetup_btnCol__3jAUA {
    display: flex;
    justify-content: flex-end;
}

.AccountSetup_btn__2U2Sh {
    height: 50px;
    --border-width: 1px;
    --border-style: solid;
    --background: transparent;
    --background-hover-opacity: 1;
    --box-shadow: none;
    --transition: 0.5s;
}

.AccountSetup_backBtn__3QeVR {
    max-width: 9.5rem;
    --color: var(--ion-text-color);
    --color-hover: var(--ion-color-primary-contrast);
    --border-color: var(--ion-text-color);
    --background-hover: var(--ion-text-color);
}

.AccountSetup_backBtn__3QeVR ion-icon {
    padding-bottom: 0.1rem;
}

.AccountSetup_nextBtn__2xEv_ {
    width: 11.563rem;
    --color: var(--ion-color-primary-contrast);
    --color-hover: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    --background: var(--ion-color-primary);
    --background-hover: var(--ion-main-background-color);
}
.EmptyChartComponent_chartContainerNoData__2y62z {
	display: flex;
	align-items: center;
	flex-direction: column;
	grid-gap: 10px !important;
	gap: 10px !important;
}

.EmptyChartComponent_chartContainerNoData__2y62z .EmptyChartComponent_defaultIconContainer__2_UnO {
	display: flex;
	align-items: flex-end;
	color: var(--ion-color-gray-tint);
}

.EmptyChartComponent_chartContainerNoData__2y62z .EmptyChartComponent_defaultIconContainer__2_UnO p {
	font-size: 20px;
	font-weight: 700;
	color: var(--ion-color-gray-tint);
}

.EmptyChartComponent_chartContainerNoData__2y62z .EmptyChartComponent_defaultTextContainer__yW8mD {
	color: var(--ion-color-gray);
	text-align: center;
}

.Filter_selectedDateRange__tY-7a {
	background-color: var(--ion-color-primary) !important;
	border-radius: 50% !important;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-Light.c9ef5dbf.eot);
    src: local('Industry Light'), local('Industry-Light'),
        url(/static/media/Industry-Light.c9ef5dbf.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-Light.dd6b7791.woff) format('woff'),
        url(/static/media/Industry-Light.8c8fb973.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-Bold.97264866.eot);
    src: local('Industry Bold'), local('Industry-Bold'),
        url(/static/media/Industry-Bold.97264866.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-Bold.7625d0cb.woff) format('woff'),
        url(/static/media/Industry-Bold.57b9533d.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-BoldItalic.47eecd84.eot);
    src: local('Industry Bold Italic'), local('Industry-BoldItalic'),
        url(/static/media/Industry-BoldItalic.47eecd84.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-BoldItalic.35741fcf.woff) format('woff'),
        url(/static/media/Industry-BoldItalic.0646b719.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-LightItalic.5250dae8.eot);
    src: local('Industry Light Italic'), local('Industry-LightItalic'),
        url(/static/media/Industry-LightItalic.5250dae8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-LightItalic.0408e705.woff) format('woff'),
        url(/static/media/Industry-LightItalic.cda5662d.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Industry-Book';
    src: url(/static/media/Industry-Book.905dd7a4.eot);
    src: local('Industry Book'), local('Industry-Book'),
        url(/static/media/Industry-Book.905dd7a4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-Book.e7e92863.woff) format('woff'),
        url(/static/media/Industry-Book.8d67a0a1.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-BlackItalic.e977c0f4.eot);
    src: local('Industry Black Italic'), local('Industry-BlackItalic'),
        url(/static/media/Industry-BlackItalic.e977c0f4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-BlackItalic.18f3e862.woff) format('woff'),
        url(/static/media/Industry-BlackItalic.ef7aae5a.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Industry-BookItalic';
    src: url(/static/media/Industry-BookItalic.ca5d2960.eot);
    src: local('Industry Book Italic'), local('Industry-BookItalic'),
        url(/static/media/Industry-BookItalic.ca5d2960.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-BookItalic.92787169.woff) format('woff'),
        url(/static/media/Industry-BookItalic.09cdde1e.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Industry-DemiItalic';
    src: url(/static/media/Industry-DemiItalic.343b1a82.eot);
    src: local('Industry Demi Italic'), local('Industry-DemiItalic'),
        url(/static/media/Industry-DemiItalic.343b1a82.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-DemiItalic.a14325c1.woff) format('woff'),
        url(/static/media/Industry-DemiItalic.afc2259a.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-Black.760e5f10.eot);
    src: local('Industry Black'), local('Industry-Black'),
        url(/static/media/Industry-Black.760e5f10.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-Black.1fc7ad2c.woff) format('woff'),
        url(/static/media/Industry-Black.6da88db3.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-Medium.42b5eea4.eot);
    src: local('Industry Medium'), local('Industry-Medium'),
        url(/static/media/Industry-Medium.42b5eea4.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-Medium.8524d71e.woff) format('woff'),
        url(/static/media/Industry-Medium.684d069b.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Industry';
    src: url(/static/media/Industry-MediumItalic.507887c0.eot);
    src: local('Industry Medium Italic'), local('Industry-MediumItalic'),
        url(/static/media/Industry-MediumItalic.507887c0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-MediumItalic.da9fac93.woff) format('woff'),
        url(/static/media/Industry-MediumItalic.e2b76824.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Industry-Demi';
    src: url(/static/media/Industry-Demi.4d430677.eot);
    src: local('Industry Demi'), local('Industry-Demi'),
        url(/static/media/Industry-Demi.4d430677.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Industry-Demi.7e84a406.woff) format('woff'),
        url(/static/media/Industry-Demi.847a5d70.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}


:root {
	--ion-color-primary: #22a2d9;
	--ion-color-primary-rgb: 0, 0, 0;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #036ea5;
	--ion-color-primary-tint: #1c8ac3;
	--ion-color-primary-dark: #118abd;

	/** Brand **/
	--ion-color-brand: #118abd;

	/**Blue Light**/
	--ion-color-blue-light: #90d0ec;

	/** secondary **/
	--ion-color-secondary: #1e1f22;
	--ion-color-secondary-rgb: 36, 36, 36;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #202020;
	--ion-color-secondary-tint: #3a3a3a;

	/** gray **/
	--ion-color-gray: #a5a5a6;
	--ion-color-gray-rgb: 165, 165, 166;
	--ion-color-gray-contrast: #000000;
	--ion-color-gray-contrast-rgb: 0, 0, 0;
	--ion-color-gray-shade: #78787a;
	--ion-color-gray-tint: #d2d2d2;
	--ion-color-gray-1: #78787a;
	--ion-color-gray-2: #a5a5a6;
	--ion-color-gray-3: #d2d2d2;
	--ion-color-gray-light: #eeeeee;

	--ion-background-color: #ffffff;
	--ion-background-color-rgb: 255, 255, 255;

	--ion-text-color: #2f2d2e;
	--ion-text-color-rgb: 47, 45, 46;
	/* --ion-header-icon-color: #8e8c89; */

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/**UVD Green**/
	--ion-color-uvd-green: #377831;

	/**UVD Yellow**/
	--ion-color-uvd-yellow: #ffc400;

	/** Red **/
	--ion-color-red: #d92e2e;
	--ion-color-dark-red: #b5221b;
	/** application variables **/
	--ion-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;

	/** text & font **/
	--ion-font-size: 14px;
	--ion-text-color: #1e1f22;
	--ion-text-color-rgb: 165, 165, 166;

	/** home page **/
	--ion-header-icon-color: var(--ion-color-gray);
	--ion-main-background-color: #eeeeee;

	/** toolbar & main menu **/
	--ion-color-toolbar: #1e1f22;
	--ion-height-toolbar: 70px;
	--ion-color-menu: #1e1f22;
	--ion-color-menu-icon: #ffffff;
	--ion-color-menu-item-hover: #ffffff;
	--ion-background-menu-item-active: var(--ion-color-primary);
	--ion-background-menu-item-hover: #78787a;

	/** custom variables **/
	--ion-btn-border-radius: 30px;
	--ion-lb-color-light: #cccccc;
	--ion-more-lb-color: #5c5956;
	--ion-text-color-light: var(--ion-color-gray);
	--ion-color-danger-hover: #aa0e11;
	/* --ion-background-menu-item-hover: #242424;
	--ion-color-menu-icon: #d7f2fb;
	--ion-color-menu-item-hover: #037dbc; */
	--ion-border-top-color-divider: 1px solid #c1c5c8;
	--ion-border-bottom-color-divider: 1px solid #5a6265;
	--login-page-image: url('/assets/img/UVD_Cover.png');

	--ion-background-color-divider: rgba(0, 0, 0, 0.25);

	--robot-logo: url('/assets/img/uvd-logo.svg');
	--robot-logo-white: url('/assets/icons/GOBE-ROBOTS-logo-neg.svg');

	--ion-main-background-color: #eeeeee;
}

ion-searchbar {
	--box-shadow: none !important;
}
ion-input {
	font-size: 14px !important;
	font-size: var(--ion-font-size) !important;
}
ion-label {
	--color: var(--ion-text-color);
	font-size: 14px !important;
	font-size: var(--ion-font-size) !important;
}
ion-button.round {
	border: 1px solid #22a2d9;
	border: 1px solid var(--ion-color-primary);
	border-radius: 30px;
	border-radius: var(--ion-btn-border-radius);
	--border-radius: var(--ion-btn-border-radius) !important;
	--color-hover: var(--ion-color-primary) !important;
	--background-hover: var(--ion-color-primary-contrast) !important;
	--background-hover-opacity: 1;
	--box-shadow: none !important;
}
ion-button.transparent {
	border: 1px solid #1e1f22;
	border: 1px solid var(--ion-text-color);
	border-radius: 30px;
	border-radius: var(--ion-btn-border-radius);
	--color: var(--ion-text-color);
	--color-hover: var(--ion-color-primary-contrast);
	--border-color: var(--ion-text-color);
	--border-width: 1px;
	--background: var(--ion-color-primary-contrast);
	--background-hover: var(--ion-text-color);
	--background-hover-opacity: 1;
	--box-shadow: none !important;
}
ion-progress-bar {
	height: 10px;
	border-radius: 10px;
	background-color: #eeeeee;
}

/** Add user modal in Users page **/
.addUserModal,
.editUserModal {
	--max-width: 400px;
	--max-height: 500px;
}

/** Add modal in UserGroups page **/
.addUserGroupModal,
.editUserGroupModal,
.deleteUserGroupModal {
	--max-width: 400px;
	--max-height: 600px;
}

/** Add user modal in accountmanagement page **/
.addPictureModal {
	--max-width: 400px;
	--max-height: 300px;
}

/** Add device modal in Robots page **/
.addDeviceModal {
	/* --max-height: 700px; */
	--max-height: 450px;
	--max-width: 400px;
}
.editDeviceModal {
	--max-height: 800px;
}
/* tooltip styles */
.react-tooltip-lite {
	background: #1e1f22;
	background: var(--ion-text-color);
	color: white;
	border-radius: 2px;
	font-size: 13px;
}

.tooltip .react-tooltip-lite {
	position: absolute;
	background-color: #ffffff !important;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important;
	border-radius: 5px !important;
	color: black !important;
}
.tooltip .react-tooltip-lite-arrow {
	color: #ffffff !important;
}
.tooltip .react-tooltip-lite-arrow::after {
	content: '';
	position: absolute;
	width: 50px;
	height: 50px;
	color: #ffffff !important;
	-webkit-transform: rotate(225deg);
	        transform: rotate(225deg);
	left: 12px;
	box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
}

#selectSSOType .alert-wrapper {
	width: 100% !important;
}

.tooltip-dashboard .react-tooltip-lite {
	width: 250px !important;
}

.tooltip-delete-org .react-tooltip-lite {
	width: -webkit-max-content !important;
	width: max-content !important;
}
.tooltip-modal .react-tooltip-lite{
	width: -webkit-max-content !important;
	width: max-content !important;
	z-index: 9999999 !important;
}
.tooltip-modal .react-tooltip-lite-arrow {
    z-index: 999999 !important;
    color: black;
}

